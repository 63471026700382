.btn {
    text-decoration: none;
    font-family    : $body-font;
    overflow       : hidden;

    &.btn-margin-top {
        margin-top: 30px;
    }

    &:focus,
    &:active {
        outline   : none !important;
        box-shadow: none;
    }

    &.btn-primary {
        background-color: $turbo;
        color           : $black;
        text-transform  : uppercase;
        border-color    : transparent;
        min-height      : 40px;
        padding-left    : 1.5rem;
        padding-right   : 1.5rem;
        border-radius   : 40px;
        font-weight     : bold;
        min-width       : auto;
        border          : 0;
        display         : inline-flex;
        justify-content : center;
        align-items     : center;
        @include link-font(8px);
        @include tracking(200);

        @include media-breakpoint-up(md) {
            @include link-font(9px);
            min-height: 50px;
        }

        // Hover devices
        @media(hover: hover) {
            &:hover {
                text-decoration : none;
                background-color: $black;
                color           : $white;
            }
        }


    }

    &.btn-no-fill {
        background-color: transparent;
        border          : 1px solid $black;
    }

    &.btn-download {
        &:after {
            content            : '';
            height             : 20px;
            width              : 20px;
            background-image   : url(/static/img/icons/download-black.svg);
            background-size    : 20px 19px;
            background-position: center right;
            background-repeat  : no-repeat;
            margin-left        : .25rem;
            position           : relative;
            transition         : transform .2s ease-in;
        }

        &:hover {
            &:after {
                transform: translateY(-1px);
            }
        }
    }

    &.pill {


        &.filter-pill {
            transform     : scale(.25);
            transition    : .4s all ease-out;
            opacity       : 0;
            text-transform: none;
            font-family   : $body-font;
            @include tracking(0);
            border          : 1px solid $filter_btn_border_color;
            background-color: $white;
            border-radius   : 20px;
            min-height      : 32px;
            padding         : 10px 15px;
            @include fontSize(12px);

            &:hover {
                color: $white;
            }
        }

        &.black-pill {
            @include link-font(8px);
            @include tracking(200);
            min-height      : 30px;
            border-radius   : 9px;
            color           : $white;
            padding         : 0 9px;
            background-color: $raisin;
            pointer-events  : none;

            @include media-breakpoint-up(md) {
                @include link-font(9px);
            }


        }
    }
}
