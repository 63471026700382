.container {

    &.inner {
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            max-width: calc(100% - 2rem);
        }

        @include media-breakpoint-up(md) {
            max-width: calc(100% - 3rem);
        }

        @include media-breakpoint-up(lg) {
            max-width: 960px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1160px;
        }

        @include media-breakpoint-up(xlg) {
            // max-width: 1150px;
        }
    }
}
