.masthead.b2b {
    position      : relative;
    padding-top   : 4rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up(sm) {
        @include mastheadPadding();
        padding-bottom: 4rem;
    }

    .copy-block {
        padding-left : 4ch;
        padding-right: 4ch;
    }

    .headline {

        &+p {
            text-wrap: pretty;
        }
    }

    display         : flex;
    flex-direction  : column;
    align-items     : center;
    background-color: $raisin;

    &.magic-stripe {
        &:after {
            content: '';
            width  : 100%;
            height : 20px;
            left   : 0;
            bottom : 0;
            @include magicBackgroundGradient();
            z-index : 0;
            position: absolute;
        }
    }


    &.we-got-filmstrip {
        padding-bottom: calc(5.337rem + 11.5652vw);


        @include media-breakpoint-up(md) {
            padding-bottom: 150px;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 170px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 210px;
        }

        &+.carousel-on-scroll {
            overflow : visible;
            position : relative;
            transform: translateY(-80px);

            @include media-breakpoint-up(md) {
                transform: translateY(-84px);
            }

            @include media-breakpoint-up(lg) {
                transform: translateY(-91px);
            }

            @include media-breakpoint-up(xl) {
                transform: translateY(-104px);
            }

            .scrolly-filmstrip {
                overflow-y: visible;
                overflow-x: hidden;
            }
        }
    }

    &.copy-on {
        &-right {
            .img-col {
                flex: 1 0 50%;

            }

            .content {
                flex: 1 0 50%;
            }
        }

        &-left {
            .img-col {
                flex    : 1 0 50%;
                position: relative;

                .img-parent {
                    position: relative;
                    z-index : 1;
                }

                .magic-background-panel {
                    position: absolute;
                    left    : 50%;
                    top     : -200%;
                    right   : auto;
                    height  : 600%;
                    width   : 1000%;
                    @include magicBackgroundGradient();
                    z-index: 0;
                }
            }

            .content {
                flex         : 1 0 50%;
                padding-right: 0;
                padding-left : 0;

                @include media-breakpoint-down(md) {
                    ul li {
                        list-style-position: inside;
                    }
                }

                @include media-breakpoint-up(md) {
                    padding-right: 2rem;
                }
            }

            .container-grid {
                flex-direction: row-reverse;
            }
        }
    }


    .headline {
        margin        : 0 0 .75rem;
        padding       : 1rem 0 0;
        text-transform: none;
    }

    &>* {
        color: $white
    }

    .container-grid {
        display: flex;

        @include media-breakpoint-up(sm) {
            align-items: center;
        }

        .img-col {
            flex           : 1 0 50%;
            position       : relative;
            display        : none;
            justify-content: center;

            @include media-breakpoint-up(md) {
                display: flex;
            }

            @include media-breakpoint-up(xl) {
                flex        : 0 1 560px;
                margin-right: -3rem;
            }

            @include media-breakpoint-up(xxl) {
                margin-right: -4rem;
            }

            .img-parent {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }

        .content {
            flex: 1 0 50%;

            @include media-breakpoint-down(md) {
                display       : flex;
                flex-direction: column;
                align-items   : center;
                text-align    : center;
            }

            &>* {
                margin-bottom: 1.5rem;
            }

            ul li {
                &:last-child {
                    padding-bottom: 1rem;
                }
            }

            @include media-breakpoint-up(md) {
                padding-left : .75rem;
                padding-right: 2rem;
                flex         : 0 1 700px;
            }

            @include media-breakpoint-up(xl) {
                padding-right: 4rem;
            }

            @include media-breakpoint-up(xxl) {
                padding-right: 5rem;
            }

            .js-btn-split {
                &:hover {
                    background-color: $white;
                    --text          : #000;
                }
            }

        }


    }

    // Dump alignment mobile stuff here
    @include media-breakpoint-down(sm) {
        .one-colum .container-grid {
            display       : flex;
            flex-direction: column-reverse;
        }

        .img-col {
            padding-top: 1rem;
        }

        .content {
            padding-top: 2rem;
        }

        .magic-background-panel {
            display: none;
        }
    }
}

:root {
    --lord-icon-primary  : #00bbe3;
    --lord-icon-secondary: #000000;
}

.b2b-landing.form-landing-detail {

    .mw-1160 {
        width        : 100%;
        max-width    : calc(1160px + 4rem);
        padding-left : 2rem;
        padding-right: 2rem;
    }

    .scrolly-filmstrip {
        width    : 100vw;
        left     : 50%;
        transform: translateX(-50%);
        position : relative;

        .scroll-wrapper {
            display : flex;
            grid-gap: 20px;
            width   : 4000px;

            @include media-breakpoint-up(xl) {
                width: 300vw;
            }
        }

        .scroll-item {
            flex  : 1 0 clamp(12.5rem, 10.3261rem + 10.8696vw, 270px);
            height: 160px;

            @include media-breakpoint-up(md) {
                height: 160px;
            }

            @include media-breakpoint-up(lg) {
                height: 180px;
            }

            @include media-breakpoint-up(xl) {
                height: 200px;
            }

            overflow        : hidden;
            position        : relative;
            display         : flex;
            align-items     : center;
            justify-content : center;
            background-color: transparent;
            border-radius   : 0;

            img {
                object-fit: cover;
                position  : absolute;
                height    : 100%;
                width     : 100%;
            }
        }
    }

    .js-turbo-line-wrap {

        .turbo-line-stretch {
            transform: scaleX(0);
            height   : 2px;
            width    : 100%;
            @include magicBackgroundGradient();
        }
    }

    .cc-quote-block {
        text-align: center;

        .container.inner {
            @include media-breakpoint-down(sm) {
                max-width: calc(100% - 3rem);
            }
        }

        .blockquote-container {
            padding: 1rem 0rem;

            @include media-breakpoint-up(sm) {
                padding: 30px 30px;
            }

            @include media-breakpoint-up(md) {
                padding: 60px 30px 56px;
            }
        }

        cite {
            @include cite();
        }
    }

    .submitted-thank-you {
        ul {
            &.download-options {
                a {
                    color: $core_blue;
                }
            }

        }
    }
}


.vanilla-sky-background-reversed {
    background: $vanilla-sky-bg-gradient-reversed;
    position  : relative;
    padding   : 80px 0;
}

.marquee-overflow-allow {

    .container-fluid,
    .container,
    .row,
    .row.one-column-row.fullbleed .one-column.copy-column,
    .one-column.copy-column {
        overflow : visible;
        max-width: 100%;
        margin   : 0;
        padding  : 0;
    }
}

.card-grid {
    position             : relative;
    z-index              : 1;
    display              : flex;
    flex-wrap            : wrap;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap             : 6rem;
    padding-top          : 6rem;
    justify-content      : center;

    @include media-breakpoint-up(md) {
        display      : grid;
        grid-gap     : 2rem;
        margin-bottom: 40px;
    }

    .card {
        background-color: $whitesand;
        padding         : 4.5rem 4vw 2rem;
        overflow        : visible;
        display         : flex;
        align-items     : center;
        text-align      : center;
        flex-direction  : column;
        border-radius   : clamp(1rem, 2vw, 20px);
        max-width       : 450px;
        flex            : 1 1 450px;
        margin-bottom   : 0;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 4.5rem 3rem 2.5rem;
        }


        &:nth-child(2) {
            @include media-breakpoint-up(md) {
                transform: translateY(2rem);
            }
        }
    }

    .card .content p {
        text-wrap: pretty;

        b {
            color: $core_blue;
        }

    }

    .icon-wrapper {
        border-radius   : clamp(1rem, 2vw, 20px);
        background-color: $white;
        box-shadow      : rgba($drop_shadow, 0.2) 6px 6px 30px 0px;
        position        : absolute;
        top             : 0;
        transform       : translateY(-50%);
        padding         : .85rem;
    }
}

.b2b-landing .b2b-gallery-grid {
    .intro-row {
        @include media-breakpoint-down(sm) {
            padding: 0 1rem;
        }
    }

    .grid-row {
        display        : none;
        justify-content: center;
        grid-gap       : 3vw;
        margin-bottom  : 3vw;
        margin-left    : 0;
        margin-right   : 0;

        @include media-breakpoint-up(sm) {
            grid-gap     : 10px;
            margin-bottom: 10px;
        }

        @include media-breakpoint-up(xl) {
            grid-gap     : 20px;
            margin-bottom: 20px;
        }

        &>* {
            margin : 0;
            padding: 0;
        }

        &.grid-item-2 {
            grid-template-columns: 1fr 1fr;
        }

        &.grid-item-3 {
            padding-left         : 1rem;
            padding-right        : 1rem;
            grid-template-columns: 1fr;

            @include media-breakpoint-up(sm) {
                padding-left         : 0;
                padding-right        : 0;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}

.b2b-gallery-grid .grid-item {
    margin       : 0;
    cursor       : pointer;
    border-radius: clamp(.5rem, 1.25vw, 20px);
    overflow     : hidden;

    .item-wrapper {
        position: relative;
    }

    .item-img {
        display      : flex;
        border-radius: clamp(.5rem, 1.25vw, 20px);
        overflow     : hidden;


        img {
            object-fit: cover;
        }
    }

    .caption {
        z-index        : 2;
        width          : 100%;
        height         : 100%;
        position       : absolute;
        bottom         : 0;
        padding        : clamp(.75rem, 2vw, 2rem);
        color          : $white;
        background     : rgba($black, .8);
        opacity        : 0;
        transition     : opacity .2s linear;
        display        : flex;
        flex-direction : column;
        justify-content: flex-end;
        align-items    : flex-start;
        text-align     : left;
        font-size      : clamp(10px, 4vw, 17px);
        line-height    : clamp(16px, 5vw, 22px);

        p {
            display            : -webkit-box;
            -webkit-line-clamp : 3;
            -webkit-box-orient : vertical;
            overflow           : hidden;
            transition-duration: .65s;
        }


        @include media-breakpoint-up(lg) {
            background: $linearblackgradient2;
        }

        & * {
            transition: translate .4s ease-out;
            translate : 0 2rem;
            opacity   : 0;
        }
    }

    .grid-item-2 & {
        .caption p {
            -webkit-line-clamp: 7;
        }
    }

    .long-content {
        display: none;
    }


    @media (hover: hover) {
        &:hover {
            .play-btn {
                z-index: 5;
            }

            .caption {
                opacity: 1;

                & * {
                    opacity  : 1;
                    translate: 0 0;
                }
            }
        }
    }
}

.b2b-landing {
    .headline {
        text-wrap: balance;
    }


    &.modal-open {
        overflow: hidden;
        height  : 100%;
    }

    .headline.lg-sentence {
        font-size  : clamp(34px, 7vw, 48px);
        line-height: clamp(40px, 8vw, 54px);
    }

    .headline.xlg-sentence {
        font-size  : clamp(24px, 7vw, 38px);
        line-height: clamp(40px, 8vw, 44px);
    }
}

.b2b-landing .intro-row {
    padding: 0;

    @include media-breakpoint-up(md) {
        padding: 0 50px;
    }

    h2 {
        text-wrap: pretty;
    }

    p {
        text-wrap: pretty;
    }
}

.b2b-landing .two-column-row {
    // @include blockPaddingMedium();

    .copy-column {

        @include media-breakpoint-up(md) {
            padding-left: 0;
        }
    }
}

.b2b-landing .one-column-row .one-column>.container-fluid {
    @include media-breakpoint-down(sm) {
        padding-left : .5rem;
        padding-right: .5rem;
    }
}


// Form

.b2b-landing .form-intro-message {
    margin-bottom: 2rem;

    h2 {
        @include display-font-semi-bold(26px);

        @include media-breakpoint-up(sm) {
            @include display-font-semi-bold(26px);
            line-height: clamp(38px, 5vw, 64px);
            font-size  : clamp(34px, 4vw, 52px);
        }
    }

    h3 {
        @include display-font-semi-bold(21px);

        line-height: clamp(26px, 6vw, 50px);
        font-size  : clamp(21px, 5vw, 42px);
    }

}

.b2b-landing .block-type-one_column_full_bleed {
    overflow: hidden;
}

.b2b-landing .two-column-row {

    .container.inner {
        max-width: 100%;
    }

    .one-column,
    .two-column {
        padding-left : 1rem;
        padding-right: 1rem;

        @include media-breakpoint-up(sm) {
            padding-left : 1rem;
            padding-right: 1rem;
        }

        @include media-breakpoint-up(md) {
            padding-left : .75rem;
            padding-right: .75rem;
        }

        @include media-breakpoint-up(lg) {
            padding-left : 0;
            padding-right: 0;
        }
    }

    .one-column {
        padding-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            padding-right: 5rem;
        }
    }
}

.b2b-landing .dark-content .form-standard {
    padding-left : .25rem;
    padding-right: .25rem;

    label {
        margin-bottom: .5rem;

        &[for=id_privacy_policy] {
            margin-bottom: 0;
        }
    }


    @include media-breakpoint-up(md) {
        padding-left : 0;
        padding-right: 0;
    }

    * {
        font-weight: 300;
    }

    .form-field {
        margin-bottom: 1.5rem;
    }

    .help-text {
        color: $core_grey;
    }

    a {
        color      : $core_blue;
        font-weight: 500;

        &:hover {
            text-decoration: none;
        }
    }

    input {
        background-color: $raisin;
        border          : 1px solid $core_grey;
        color           : $white;

        &[type='radio'] {
            border: 1px solid $core_grey !important;

            &:before {
                box-shadow: inset 1em 1em $white;
            }
        }

        &[type='checkbox'] {
            border: 2px solid $core_grey;

            &:after {
                color: $white;
            }
        }
    }

    textarea {
        background-color: $raisin;
        color           : $white;
        border          : 1px solid $core_grey;
    }

    select {
        background-color: $raisin;
        color           : $white;
        border          : 1px solid $core_grey;
        background-image: linear-gradient(45deg, transparent 50%, $core_grey 50%),
            linear-gradient(135deg, $core_grey 50%, transparent 50%),
            linear-gradient(to right, $white, $white);
    }

    .checkboxinput {
        label {
            margin-bottom: 0;
        }
    }

}


.b2b-landing.form-landing-detail .modal,
.b2b-landing.custom-landing-detail .modal {
    &.show {
        z-index   : 1051;
        opacity   : 1;
        visibility: visible;
    }

    .close-overlay {
        background: transparent;
        border    : 0;
    }

    .close-x {
        background: transparent;
        border    : 0;
        position  : fixed;
        right     : 1rem;
        top       : 1rem;
        z-index   : 11;

        @include media-breakpoint-up(md) {
            right: 2rem;
            top  : 2rem;
        }
    }

    .btn-close {
        background-color   : transparent;
        background-image   : none;
        border             : 0;
        height             : 20px;
        width              : 20px;
        background-size    : contain;
        background-position: center;
        background-repeat  : no-repeat;
        opacity            : 1;
        @include transition(.23s all ease);

        span {
            visibility: hidden;
        }
    }

    .modal-dialog {
        max-width      : none;
        height         : auto;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        min-height     : 100%;
        margin-top     : 0;

        @include media-breakpoint-up(lg) {
            width: calc(100vw - 2rem);
        }

        @include media-breakpoint-up(xl) {
            width: 100%;
        }

        .modal-content {
            border-radius   : 0;
            background-color: transparent;
            color           : $white;
            border          : 0;
            max-width       : 100%;
            margin-top      : 0;
        }

        .full-img {
            position: relative;

            .img-parent {
                display        : flex;
                flex-direction : column;
                align-items    : center;
                justify-content: center;

                .modal-img {
                    height: fit-content;
                    width : 100%;
                }
            }
        }
    }

    .modal-header {
        border: 0;
    }

    .modal-body {
        &.for-img {
            padding       : 0;
            padding-bottom: 3rem;
        }

        &.hidden {
            padding: 0;
            height : 0;
        }

        &.responsive-object {
            max-width : 80vw;
            align-self: center;
        }
    }

    &#masonry-modal {
        width: 100%;

        .modal-content {
            padding: 0;
        }

        .modal-header {
            display        : flex;
            text-align     : left;
            justify-content: space-between;
            padding        : 0 0 1rem;
        }
    }

    .more-content-arrow {
        position  : fixed;
        width     : 100vw;
        height    : 20vh;
        bottom    : 0;
        left      : 0;
        opacity   : 0;
        transition: opacity .2s linear;
        visibility: hidden;
        z-index   : 2;
        background: linear-gradient(to top, #000, transparent);

        &.activated {
            opacity   : 1;
            visibility: visible;
            transition: opacity .2s linear;
        }

        .the-arrow {
            position           : absolute;
            width              : 100%;
            height             : 50px;
            left               : 0;
            z-index            : 12;
            opacity            : 1;
            top                : 50%;
            background-repeat  : no-repeat;
            background-position: center;
            background-image   : url(/static/img/svg/filter-btn-arrow-white.svg);
            background-size    : 40px 40px;
        }

    }

    .modal-wrapper {

        background-color: $white;
        color           : $black;

        .additional-content {
            position: relative;
        }
    }

    .modal-content-block {
        position     : relative;
        margin-bottom: 1.5rem;

        &:first-of-type {
            margin-top: 3rem;
        }

        &:last-of-type {
            margin-bottom : 0;
            padding-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 3rem;
        }

        .container-fluid,
        .container.inner,
        .row {
            padding-left : 0;
            padding-right: 0;
            max-width    : 100%;
            margin-left  : 0;
            margin-right : 0;
        }

        .quote-trigger {
            padding-top   : 0;
            padding-bottom: 0;

            @include media-breakpoint-down(lg) {

                .cc-quote-block {
                    padding-left : 0;
                    padding-right: 0;
                }

            }
        }

        .image-block {
            .img-parent {
                overflow: hidden;
            }

            &.two-up-img-block {

                .two-up-grid {
                    display       : flex;
                    flex-direction: column;
                    grid-gap      : 3rem;

                    @include media-breakpoint-up(sm) {
                        display              : grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap             : 1.25rem;
                    }



                    .img-caption {
                        padding-right: 2rem;
                    }

                    .col {
                        padding: 0;
                    }
                }

            }
        }
    }
}

.modal-backdrop.show {
    opacity: 0.84;
}


#video-modal .modal-dialog {
    max-width: 1450px;
}

#masonry-modal .swiper-navigation {
    display        : flex;
    position       : fixed;
    z-index        : 20;
    width          : 100%;
    max-width      : 95%;
    justify-content: space-between;
    margin         : 0 auto;
    top            : 50vh;

    left     : 50%;
    transform: translateX(-50%);
    height   : 0px;

    @include media-breakpoint-up(md) {
        max-width: 90%;
    }

    @include media-breakpoint-up(xxxl) {
        max-width: 1500px;
    }

    .desktop & {
        left: calc(50% - .46rem);
    }
}


.img-with-copy-modal-grid {
    grid-template-columns: 50% 50%;
    grid-gap             : 1rem;

    .col.left-side {

        grid-column: 1 / span 1;

        .modal & {
            padding: 0;
        }
    }

    .photo-wrapper .column-img {
        display: flex;
        @include smallRadiusCorner();
        overflow   : hidden;
        grid-column: 2 / span 1;

        img {
            height: auto;
        }
    }
}


// Swiper slider
.modal-slider {


    .swiper-button-next,
    .swiper-button-prev {
        opacity   : 0;
        transition: all .2s ease-in;
        right     : auto;
        left      : 0;
        position  : relative;

        &:after {
            color      : $white;
            font-size  : clamp(1rem, 2vw, 30px);
            text-shadow: 2px 2px 2px rgba($black, 0.78);
        }

        &:hover {
            &:after {
                color: $turbo_oklch;
            }
        }

        &.swiper-button-disabled {
            &:after {
                opacity: .65;
            }
        }
    }

    .swiper-button-next {
        left : auto;
        right: 0;
    }

    &.swiper-autoheight {
        overflow: hidden;
    }

    &.swiper-initialized {

        .swiper-button-next,
        .swiper-button-prev {
            opacity: 1;
        }
    }

    .swiper-slide {
        .slide-headline {
            padding-bottom: .75rem;
        }
    }

    .slide-content {
        position : relative;
        max-width: 86%;
        margin   : 0 auto;

        @include media-breakpoint-up(md) {
            max-width: 82%;
        }

        @include media-breakpoint-up(xxxl) {
            max-width: 1400px;
        }

    }

}
