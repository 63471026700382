.core-brand {

    .vanilla-sky-background {
        background: $vanilla-sky-bg-gradient;
        position  : relative;

        &:before {
            content   : '';
            width     : 100%;
            height    : 156px;
            top       : 0;
            right     : 0;
            position  : absolute;
            background: $linearwhitegradient2;
        }
    }

    .service-card-grid {
        margin-top           : 3rem;
        grid-gap             : .75rem;
        align-items          : center;
        display              : grid;
        grid-template-columns: 1fr 1fr;

        @include media-breakpoint-up(sm) {
            grid-gap  : 4vw;
            margin-top: 4rem;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;

            grid-gap: clamp(1rem, 2vw, 40px);
        }

        .service-card {
            overflow        : hidden;
            border-radius   : 20px;
            background-color: $white_oklch;
            box-shadow      : $card_shadow;
            aspect-ratio    : 1/1;
            width           : 100%;
            height          : auto;
            padding         : 0px;
            display         : flex;
            flex-direction  : column;
            justify-content : center;
            align-items     : center;
            grid-gap        : .25rem;
            width           : 100%;

            &.marketing {
                grid-row   : 1 / span 2;
                grid-column: 1 / span 1;
            }

            &.branding {
                grid-row   : 1 / span 1;
                grid-column: 2 / span 1;
            }

            &.creative {
                grid-row   : 2 / span 1;
                grid-column: 2 / span 1;
            }


            lord-icon {
                border       : 6vw solid transparent;
                border-bottom: 12vw solid transparent;
                position     : absolute;
                top          : 0;
                right        : 0;
                bottom       : 0;
                left         : 0;
                object-fit   : cover;

                @include media-breakpoint-between(sm, md) {
                    border       : 6vw solid transparent;
                    border-bottom: 12vw solid transparent;
                }

                @include media-breakpoint-up(md) {
                    border       : 20px solid transparent;
                    border-bottom: 40px solid transparent;
                }

                @include media-breakpoint-up(xl) {
                    border       : 50px solid transparent;
                    border-bottom: 100px solid transparent;
                }

            }

            .service-title {
                margin-bottom: 0;
                position     : absolute;
                bottom       : 7vw;

                @include media-breakpoint-between(sm, md) {
                    font-size: 4vw;
                }

                @include media-breakpoint-between(md, lg) {
                    font-size: 20px;
                }

                @include media-breakpoint-up(md) {
                    bottom: 20px;
                }

                @include media-breakpoint-up(xl) {
                    bottom: 50px;
                }
            }

            // Custom offset cus rocket is taller
            &.creative {
                lord-icon {
                    margin-top: -0.75rem;
                }
            }

        }


    }


}
