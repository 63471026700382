@import url("https://use.typekit.net/mfg7gay.css");
@import url("/static/fonts/gotham.css");

// Display font variants
$display-font-thin     : LaviossaThin;
$display-font-reg      : LaviossaRegular;
$display-font-med      : LaviossaMedium;
$display-font-semi-bold: LaviossaSemibold;
$display-font-bold     : LaviossaBold;

// Body font Gotham ScreenSmart
$body-font: 'Gotham SSm A',
'Gotham SSm B',
sans-serif;

// Link font Termina
$link-font: termina,
sans-serif;
