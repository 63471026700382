.main-content {

    a {
        color: $link-color;

        &:not(.card-link):hover {
            color      : $link-color;
            font-weight: 700;
        }

        &.social-link {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
