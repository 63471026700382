.preloader-section {
    background-color: $woodsmoke;
    width           : 100vw;
    height          : 100vh;
    overflow        : hidden;
    position        : fixed;
    display         : flex;
    top             : 0;
    left            : 0;
    align-items     : center;
    justify-content : center;
    will-change     : transform;

    svg.core-logo-preloader {
        width    : 90px;
        max-width: 560px;
        position : relative;
        z-index  : 1;
    }
}
