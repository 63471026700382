.card {
    border          : none !important;
    border-radius   : $border-radius;
    position        : relative;
    background-color: transparent;

    &.border-color-none {
        .card-body {
            border-left     : 1px solid $turbo;
            border-right    : 1px solid $turbo;
            border-bottom   : 1px solid $turbo;
            background-color: $white;
        }

        &.no-image {
            .card-body {
                border-top             : 1px solid $turbo;
                border-top-right-radius: $border-radius;
                border-top-left-radius : $border-radius;
            }
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 20px;
    }

    .card-img-top {
        overflow               : hidden;
        border-top-right-radius: $border-radius;
        border-top-left-radius : $border-radius;
        z-index                : 0;
        transform              : translateY(1px);

        .img-wrapper {
            transition      : 5s transform linear;
            transform-origin: center center;
        }
    }

    .card-body {
        z-index                   : 1;
        padding                   : 3rem;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius : $border-radius;

        @include media-breakpoint-up(md) {
            padding: 2rem 1rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 2rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 37px 32px;
        }

        a.link {
            margin-bottom: 1px;
        }

        &.with-background {
            &:not(.background-grey) {
                * {
                    color: $white;
                }

                a.link {
                    color       : $white;
                    border-color: $white;
                }

                .btn.btn-primary {
                    border-color: transparent;
                }
            }

            &.background-grey {

                .card-title {
                    color: $turbo;
                }
            }
        }

        .stretched-link {
            transition    : none;
            border-width  : 1px;
            padding-bottom: 1px;

            &:hover {
                border-width: 2px;

                padding-bottom: 0px;
            }
        }
    }

    .card-cta {
        @include smallCaps(12px);
        align-items    : inherit;
        display        : flex;
        flex-direction : column;
        flex           : 1 0 auto;
        justify-content: flex-end;
        margin-top     : 7px;
        line-height    : 16px;
    }

    // Image zoom effect
    &.active-hover {
        .card-img-top {

            .img-wrapper {
                transform: scale3d(1.1, 1.1, 1.1);
            }
        }
    }
}

.card-link {
    text-decoration: none;

    .article-img {
        transition      : .3s transform linear;
        transform-origin: center center;
    }

    .news-card-hdr .article-title,
    p {
        color: $black;
    }

    .news-card-hdr {

        .article-type,
        .category-tags,
        .article-title {
            transition: .2s margin linear;
        }
    }

    &:hover {
        text-decoration: none;

        .news-card-body>p {
            font-weight: normal;
        }
    }

    // Card zoom hover effect for non-touch devices hover only
    @media (hover: hover) {
        &:hover {

            .article-img {
                transform: scale3d(1.05, 1.05, 1.05);
            }

            .cta.link {
                .chars {
                    --m: calc(var(--font-size) * -1);
                }
            }
        }
    }


}
