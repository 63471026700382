.multiple-image-block {

    .photo-parent img {
        object-fit: cover;
    }

    .image-row {
        margin-bottom: 32px;
    }

    .grid-col {
        margin-bottom: 32px;
    }

    @include media-breakpoint-up(md) {
        .grid-col {
            margin-bottom: 0;
        }
    }

    .img-grid {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-gap: 32px;
        }

        // Double columns
        &.one-half-one-half {
            grid-template-columns: 1fr 1fr;
        }

        &.two-third-one-third {
            grid-template-columns: minmax(200px, 736px) minmax(100px, 352px);

            &.full-bleed {
                grid-template-columns: minmax(200px, 2fr) minmax(100px, 1fr);
            }
        }

        &.one-third-two-third {
            grid-template-columns: minmax(100px, 352px) minmax(200px, 736px) ;

            &.full-bleed {
                grid-template-columns: minmax(100px, 1fr) minmax(200px, 2fr) ;
            }
        }

        // Triple column
        &.triple-img {
            grid-template-columns: 1fr 1fr 1fr;
        }

    }


}

// IE11 support
.ie11 .multiple-image-block {

    .img-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .grid-col {
            flex: 1;
            padding: .5rem;
        }
    }
}
