.project-showcase {
    .container.inner.max-100 {
        max-width: 100%;
    }

    .intro-copy {
        .headline {
            * {
                @include display-font-semi-bold(21px);

                line-height: clamp(26px, 6vw, 50px);
                font-size  : clamp(21px, 5vw, 42px);
            }
        }
    }

    .two-column-container {
        background-color: $white;
        margin-top      : 0 !important;
        margin-bottom   : 0 !important;

        &:nth-child(even) {
            background-color: $whitesand;
        }
    }

    .two-column-row {
        padding-top   : $block-spacer-40 !important;
        padding-bottom: $block-spacer-40 !important;

        .copy-column {
            margin-bottom: 24px;
            padding-left : 2.5rem;
            padding-right: 2.5rem;
        }

        .container.inner {
            max-width: 100%;
        }

        .paragraph-block {
            padding: 0;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            padding-top   : $block-spacer-60 !important;
            padding-bottom: $block-spacer-60 !important;

            .copy-column {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-top   : $block-spacer-120 !important;
            padding-bottom: $block-spacer-120 !important;
        }

        .column-img,
        .column-img img {
            height       : auto;
            overflow     : hidden;
            border-radius: 40px;
        }
    }

    .one-column,
    .two-column {
        display        : flex;
        flex-direction : column;
        justify-content: center;

        @include media-breakpoint-up(md) {}
    }
}
