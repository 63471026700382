.landing-detail-intro-panel {
    .headline {
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            margin-bottom: 50px;
        }
    }

    .copy-column {
        p {
            max-width: 660px;
        }
    }

    .business-column {
        margin-top  : 40px;
        padding-left: 1rem;

        p {
            margin-bottom: .5rem;
        }

        a {
            display: block;
            width  : max-content;
        }

        @include media-breakpoint-up(md) {
            padding-left: 20px;
            margin-top  : 0;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 55px;
        }

        .cta-link {
            margin-top: .75rem;
        }

        ul {
            margin-top  : .5rem;
            padding-left: .75rem;

            @include media-breakpoint-up(md) {
                padding-left: 1.1rem;
            }
        }

        .social-link {
            margin-right: 4px;

            img {
                height: 16px;
            }

            &:hover {
                img {
                    transform: translateY(-1px) scale(1.1);
                }
            }
        }
    }
}


.business-list {
    list-style-type: none;
    padding-left   : 0;
    margin-bottom  : 1rem;
}
