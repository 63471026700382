// This class is removed with javascript if tab is used
// credit to: https:// jmperezperez.com/outline-focus-ring-a11y/
.no-focus-outline a:focus,
.no-focus-outline button:focus,
.no-focus-outline {
    outline: none !important;
}

.hidden-label {
    line-height: 0;
    font-size: 0;
    color: transparent;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}