.background-photo {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
    z-index : 0;

    &:before {
        content         : '';
        background-color: rgba($black, .8);
        position        : absolute;
        left            : 0;
        top             : 0;
        width           : 100%;
        height          : 100%;
    }

    .photo-parent {
        display : flex;
        width   : 100%;
        height  : 100%;
        left    : 0;
        top     : 0;
        position: absolute;
        z-index : -1;

        .bg-photo {
            object-fit: cover;
            position  : absolute;
            height    : 100%;
            width     : 100%;
        }
    }
}
