.background-video-landing {

    header.masthead.b2b {
        padding-top: clamp(110px, 10vw, 150px);
    }

    header {
        position: relative;
        background-color: black;
        height: 50vh;
        min-height: 35rem;
        width: 100%;
        overflow: hidden;
        z-index: 1;

        .header-content-container {
            justify-content: center;
        }

        .header-content {
            max-width: 90%;

            @include media-breakpoint-up(md) {
                max-width: 80%;
                display: flex;
                flex-direction: column;
            }

            @include media-breakpoint-up(lg) {
                max-width: 70%;
            }

            @include media-breakpoint-up(xl) {
                max-width: 60%;
            }
        }

        @include media-breakpoint-up(md) {
            height: 65vh;
            min-height: 35rem;
        }

        @include media-breakpoint-up(xl) {
            height: 60vh;
        }

        @media (max-height: 960px) {
            min-height: 40rem;
        }
    
        video, picture.background-hero {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            -ms-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);

            img {
                object-fit: cover;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }

        .container {
            position: relative;
            z-index: 2;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.7;
            z-index: 1;

            &.video-overlay {
                opacity: 0.75;
            }
        }
    }
}