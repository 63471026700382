// Uses vars from 1-settings/variables.scss

.block-margin-bottom {
    margin-bottom: $block-spacer-40 !important;

    @include media-breakpoint-up(md) {
        margin-bottom: $block-spacer-60 !important;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: $block-spacer-120 !important;
    }
}

.block-margin-top {
    margin-top: $block-spacer-40 !important;

    @include media-breakpoint-up(md) {
        margin-top: $block-spacer-60 !important;
    }

    @include media-breakpoint-up(xl) {
        margin-top: $block-spacer-120 !important;
    }
}


.block-with-background,
.block-padding {
    padding-top   : $block-spacer-40;
    padding-bottom: $block-spacer-40;

    @include media-breakpoint-up(md) {
        padding-top   : $block-spacer-60;
        padding-bottom: $block-spacer-60;
    }

    @include media-breakpoint-up(xl) {
        padding-top   : $block-spacer-120;
        padding-bottom: $block-spacer-120;
    }

    &-top {
        padding-top: $block-spacer-40;

        @include media-breakpoint-up(md) {
            padding-top: $block-spacer-60;
        }

        @include media-breakpoint-up(xl) {
            padding-top: $block-spacer-120;
        }
    }

    &-bottom {
        padding-bottom: $block-spacer-40;

        @include media-breakpoint-up(md) {
            padding-bottom: $block-spacer-60;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: $block-spacer-120;
        }
    }
}

// For block with 30px gutter from other blocks above and below
.custom-below-padding {
    padding-bottom: $block-spacer-40;

    @include media-breakpoint-up(md) {
        padding-bottom: $block-spacer-60;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: $block-spacer-90;
    }
}

.custom-above-padding {
    padding-top: $block-spacer-40;

    @include media-breakpoint-up(md) {
        padding-top: $block-spacer-60;
    }

    @include media-breakpoint-up(xl) {
        padding-top: $block-spacer-90;
    }
}

.block-y-margin,
.block-padding-md {
    @include blockPaddingMedium();

    &-top {
        padding-top: $block-spacer-40;

        @include media-breakpoint-up(md) {
            padding-top: $block-spacer-60;
        }

        @include media-breakpoint-up(xl) {
            padding-top: $block-spacer-110;
        }
    }

    &-bottom {
        padding-bottom: $block-spacer-40;

        @include media-breakpoint-up(md) {
            padding-bottom: $block-spacer-60;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: $block-spacer-110;
        }
    }
}

.block-padding-xm {
    @include blockPaddingXtraMedium();
}

.block-padding-bottom-md {
    padding-bottom: $block-spacer-60;

    @include media-breakpoint-up(md) {
        padding-bottom: $block-spacer-90;
    }
}

.block-padding-bottom-lg {
    padding-bottom: $block-spacer-60;

    @include media-breakpoint-up(md) {
        padding-bottom: $block-spacer-90;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: $block-spacer-120 !important;
    }
}

.block-padding-sm {
    padding-top   : $block-spacer-40;
    padding-bottom: $block-spacer-40;

    @include media-breakpoint-up(md) {
        padding-top   : $block-spacer-60;
        padding-bottom: $block-spacer-60;
    }
}

.block-no-margin {
    margin-top   : 0;
    margin-bottom: 0;
}

// Common margin classes
.mt-40 {
    margin-top: 25px;

    @include media-breakpoint-up(md) {
        margin-top: $block-spacer-40;
    }
}
