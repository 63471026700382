.about-intro-row {
    padding-top: 60px;

    @include media-breakpoint-up(md) {
        padding-top: 80px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 130px;
    }
}

.design-services #main {
    &>.container-fluid>.container.inner.about-intro-block {
        padding-left : 1.75rem;
        padding-right: 1.75rem;

        @include media-breakpoint-up(md) {
            padding-left : 0;
            padding-right: 0;
        }

        @include media-breakpoint-up(lg) {
            max-width: 960px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 1160px;
        }
    }

    .marquee-horizontal-scroll-panel {
        &>div {
            position: relative;
            z-index : 1;
        }

        &:after {
            width           : 200%;
            left            : 0;
            content         : '';
            height          : 50%;
            position        : absolute;
            bottom          : 0;
            z-index         : 0;
            background-color: $whitesand;
        }
    }
}
