$lgScreenOffset : 53vw;
$squareSide     : calc(100vw * 3/4);

.culture-slider-block {

    .app-init {
        transition : height .4s ease-in;
        will-change: height;
    }

    .copy-container,
    .height-spacer,
    .native-state,
    .swiper-state,
    .swiper-container {
        grid-column: 1/span 1;
        grid-row   : 1/span 1;
    }

    .culture-copy-box {
        width              : 50%;
        position           : relative;
        height             : calc(240px + clamp(330px, 40vw, 500px));
        z-index            : 1;
        backface-visibility: hidden;
        display            : flex;
        align-items        : center;
        align-self         : flex-start;

        padding-top   : 120px;
        padding-bottom: 120px;

        .copy-wrap {
            max-width: clamp(336px, 38.7vw, 492px);
        }

    }

    .height-spacer {
        position: relative;
        width   : 100%;
        height  : calc(240px + clamp(330px, 40vw, 500px));
    }

    .photo-card {
        width           : 560px;
        height          : 500px;
        transform-origin: center center;
        transition      : transform .25s linear;
        display         : flex;
        justify-content : center;

        @include media-breakpoint-up(md) {
            width : clamp(330px, 42vw, 560px);
            height: clamp(330px, 40vw, 500px);
        }

        img {
            border-radius: 40px;
            overflow     : hidden;
            object-fit   : cover;
            height       : 100%;
            width        : 100%;
        }

        &.slim {
            width: 360px;
        }
    }
}




// Toggle button
.btn.btn-primary.js-btn-plus-close {
    @include link-font(8px);
    background-color: $turbo;
    color           : $black;
    display         : flex;
    height          : 50px;
    min-width       : 0;
    padding         : 0 24px;
    position        : absolute;
    text-transform  : uppercase;
    transition      : all .3s linear;
    width           : 50px;
    will-change     : transform, background-color;
    z-index         : 2;
    left            : 0;
    top             : calc(80px + clamp(360px, 40vw, 500px) / 2 - 25px);

    @include media-breakpoint-up(md) {
        top  : calc(120px + clamp(360px, 40vw, 500px) / 2 - 25px);
        left : calc(#{$lgScreenOffset} - 25px);
    }

    &:focus {
        outline   : 0;
        box-shadow: none;
    }

    &.activated {
        --text        : #000;
        --shadow      : 0 2px 8px -1px #{rgba(#000, .04)};
        --shadow-hover: 0 4px 20px -2px #{rgba(#000, .12)};
    }

    &:hover,
    &.hover {
        background-color: $turbo;

        .toggle-icon.open {
            transform: translateY(-50%) rotate(45deg) scale(1.3);
        }
    }

    .label {
        display    : flex;
        align-items: center;
    }

    .toggle-icon {
        position        : absolute;
        top             : 50%;
        transition      : all .2s ease-in-out;
        transform-origin: center;
        transform       : translateY(-50%) rotate(0deg);

        // Change plus to X when the region is expanded/open
        &.open {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    // Toggle button text exposed on hover
    .hover-text {
        opacity         : 0;
        position        : absolute;
        left            : 50%;
        top             : 50%;
        transform       : translate(-50%, -50%) scale(0);
        transform-origin: 2rem center;
        width           : 210px;
        justify-content : center;
        transition      : transform .2s linear;

        &:after {
            position           : relative;
            content            : '';
            display            : inline-block;
            background-image   : url(/static/img/icons/arrows/small-right-arrow.svg);
            background-position: center;
            background-repeat  : no-repeat;
            background-size    : contain;
            width              : 13px;
            height             : 9px;
            z-index            : 1;
            margin-left        : 9px;
            top                : -1;
        }
    }

    // JS applied .hover
    &.hover {
        background-color: $turbo;
        color           : $black;
        width           : 205px;

        .toggle-icon {
            display: none;
        }

        .hover-text {
            opacity  : 1;
            display  : inline-flex;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

// Cards in stacked position.
.native-state {
    left    : 0;
    margin  : 0;
    position: absolute;
    width   : 100%;
    overflow: visible;
    height  : 100%;
    top     : 120px;

    .photo-card {
        position  : absolute;
        top       : 0;
        left      : 0;
        transform : translateX(#{$lgScreenOffset});

        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                z-index: -#{$i - 1};
            }
        }
    }
}

// Card deck 'fan out' on hover
.native-state.collapsed.hover {
    .photo-card {
        @for $i from 1 through 10 {
            // Start at -3deg
            $rotation: $i - 3;

            &:nth-child(#{$i}) {
                @include media-breakpoint-up(md) {

                    transform: translateX(#{$lgScreenOffset}) rotate(#{$rotation * 2}deg);

                    @include media-breakpoint-up(lg) {
                        transform: translateX(#{$lgScreenOffset}) rotate(#{$rotation * 2}deg);
                    }

                }
            }
        }
    }
}

// Initialized hidden swiper
.swiper-state {
    position     : relative;
    visibility   : hidden;
    z-index      : -1;
    height       : auto;
    max-height   : 400px;
    width        : 100%;
    max-width    : 100vw;
    padding-right: 3rem;
    margin-top   : 120px;
    transition   : max-height .4s ease-out;

    .photo-card {
        visibility: hidden;
    }

    .main-slider {
        width        : 100vw;
        max-width    : 1160px;
        padding-right: 80px;
    }
}

// Swiper CORE values slider, bleeds off screen
.cc-slider.swiper {
    overflow: visible;

    .swiper-wrapper {
        overflow: visible;
    }

    .swiper-slide {
        width: 360px;
    }

    .swiper-card {
        &-headline {
            margin-top   : 32px;
            margin-bottom: 6px;
        }
    }
}

// Navigation station: scrollbar with progress, navigation arrows
.cc-slider-navigation {
    align-items: center;

    @include media-breakpoint-between(md, xl) {
        max-width: calc(100% - 5rem);
        max-width: 100%;
    }

    .cc-slider-nav {
        width      : 50px;
        height     : 20px;
        align-items: center;
        display    : flex;
    }

    // Tiny little nav arrows
    .swiper-button {
        margin-top         : 0;
        top                : auto;
        background-image   : url('/static/img/icons/arrows/swiper-nav-arrow-right.svg');
        background-repeat  : no-repeat;
        background-size    : contain;
        background-position: center;
        width              : 6px;
        height             : 12px;

        &-prev {
            transform: scale(-1);
        }

        &:after {
            content: '';
        }
    }

    // Progress bar from https: //jsfiddle.net/6ng205L1/
    .slider-progress-wrap {
        position: relative;
        flex    : 1 1 auto;
        height  : 20px;
    }

    .slider-progress {
        position  : absolute;
        top       : 9px;
        right     : 0;
        left      : 0;
        width     : 100%;
        height    : 3px;
        background: $grey_d6;
    }

    .slider-progress2 {
        position  : absolute;
        top       : 9px;
        right     : 0;
        left      : 0;
        width     : 0;
        height    : 3px;
        background: $turbo;
    }

    .swiper-scrollbar-drag {
        height          : 7px;
        top             : calc(50% - 3px);
        border-radius   : 2px;
        width           : 200px;
        background-color: $turbo;
        position        : absolute;

        .mobile &,
        &:hover,
        &:active {
            height       : 12px;
            border-radius: 3px;
            top          : calc(50% - 6px);
        }

        &:hover {
            cursor: grab;
        }

        &:active {
            cursor: grabbing;
        }
    }
}

// Dots navigatioin
.swiper-pagination {
    position: relative;

    .swiper-pagination-bullet {

        &.swiper-pagination-bullet-active {
            background: $black;
        }
    }
}

// Mobile 768px and down

@include media-breakpoint-down(md) {

    .culture-slider-block {
        .app-init {
            position     : relative;
            margin-bottom: 40px;
        }

        .copy-container {
            flex: 1 0 100%;
        }

        .native-wrapper,
        .native-state,
        .swiper-state {
            position: relative;
        }

        .height-spacer {
            display: none;
        }

        .culture-copy-box {
            width              : auto;
            position           : relative;
            height             : auto;
            z-index            : 1;
            backface-visibility: hidden;
            display            : flex;
            align-items        : center;
            align-self         : flex-start;
            padding-top        : 40px;
            padding-bottom     : 40px;

            .copy-wrap {
                max-width: 100%;
                transform: none !important;
                opacity  : 1 !important;
            }

        }


        .photo-card {
            width           : $squareSide;
            height          : $squareSide;
            transform-origin: center center;
            transition      : transform .25s linear;
            border-radius   : 40px;
            overflow        : hidden;
            display         : flex;
            justify-content : center;

            img {
                object-fit: cover;
                height    : 100%;
                width     : 100%;
            }

            &.slim {
                width: $squareSide;
            }
        }

        // Cards in stacked position.
        .native-state {
            margin-left: .25rem;
            position   : relative;
            width      : 100%;
            overflow   : visible;
            height     : 100%;
            top        : 0;

            .photo-card {
                transition: none;
                left      : 50%;
                transform : translateX(-50%);
            }
        }

        .swiper-state {
            margin-top: 0;
            max-height: $squareSide;

            .cc-slider.swiper {
                .swiper-slide {
                    width: $squareSide;
                }
            }
        }

        // Toggle button
        .btn.btn-primary.js-btn-plus-close.mobile-toggle-btn {
            height : 40px;
            width  : 40px;
            left   : -1rem;
            top    : calc((100vw * 3/4) / 2 - 20px);
            padding: .2rem;

            &:hover {

                .toggle-icon.open {
                    transform: translateY(-50%) rotate(45deg) scale(1);

                    .desktop & {
                        transform: translateY(-50%) rotate(45deg) scale(1.3);
                    }
                }
            }

            &.hover {
                background-color: $turbo;
                color           : $black;
                width           : 40;

                .desktop & .toggle-icon {
                    display  : block;
                    transform: translateY(-50%) scale(1.3);
                }

                .hover-text {
                    opacity  : 0;
                    display  : none;
                    transform: none;
                }
            }

            @media screen and (min-width: 370px) {
                left: -5px;
            }

            @media screen and (min-width: 480px) {
                left: 16px;
            }

            @media screen and (min-width: 580px) {
                left: 6px;
            }

            @media screen and (min-width: 650px) {
                left: 15px;
            }

            @media screen and (min-width: 750px) {
                left: 28px;
            }

            &.close-ready {
                left: -1rem;
            }

        }

    }

    // END .culture-slider-block

}

// END mobile
