.openings-panel-block {
    background-color: $woodsmoke;
    z-index         : 1;
    position        : relative;
    padding-top     : 40px;
    padding-bottom  : 40px;

    @include media-breakpoint-up(md) {
        padding-top   : 120px;
        padding-bottom: 120px;
        @include bigRadiusCorner(bottom-right);

        &:after {
            position                         : absolute;
            @include fingerPrint($light-print:false);
            content                          : '';
            width                            : clamp(25vw, 33vw, 400px);
            left                             : 0;
            bottom                           : 0;
            height                           : calc(100% - 80px);
        }
    }

    .photo-container {
        position       : relative;
        z-index        : 1;
        display        : flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
            left: -9vw;
        }
    }

    .photo-wrapper-circle {
        position     : relative;
        display      : flex;
        margin-bottom: 40px;
        width        : clamp(260px, calc(100vw - 80px), 560px);
        height       : clamp(260px, calc(100vw - 80px), 560px);

        @include media-breakpoint-up(sm) {
            width     : clamp(320px, calc(100vw - 6.5rem), 560px);
            height    : clamp(320px, calc(100vw - 6.5rem), 560px);
            margin-top: 1rem;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            width        : clamp(360px, 45vw, 560px);
            height       : clamp(360px, 45vw, 560px);
        }

        img {
            object-fit   : cover;
            width        : 100%;
            position     : absolute;
            height       : 100%;
            border-radius: 50%;
            overflow     : hidden;
        }
    }

    .brand-spinner {
        right     : 10px;
        top       : 0;
        position  : absolute;
        height    : 100%;
        width     : 100%;
        max-width : clamp(96px, 28vw, 200px);
        max-height: clamp(96px, 28vw, 200px);

        @include media-breakpoint-up(sm) {
            right     : 20px;
            max-width : clamp(105px, 28vw, 200px);
            max-height: clamp(105px, 28vw, 200px);
        }

        @include media-breakpoint-up(md) {
            right     : -10px;
            max-width : clamp(50px, 18vw, 200px);
            max-height: clamp(50px, 18vw, 200px);
        }

        img {
            max-width: 100%;
        }
    }

    .jobs-availible {
        margin-bottom: 25px;

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }
    }


}
