.decorative-ribbon {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    // background-image: url('/static/img/svg/green-ribbon-vector-wave.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    &.flip-background {
        // background-image: url('/static/img/svg/green-ribbon-vector-wave-reversed.svg');
    }
}
