// html,
body.desktop {

    &.hide-cursor {

        *,
        *:hover {
            cursor: none;
        }
    }

    .cursor {
        transform      : translate(-50%, -50%);
        position       : fixed;
        top            : 0px;
        left           : 0px;
        pointer-events : none;
        cursor         : none;
        transition     : transform 50ms ease-out;
        z-index        : -1;
        opacity        : 1;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;

        &[inert] {
            transition: opacity 300ms ease-out;
            opacity   : 0;
        }

        &:after {
            content         : '';
            transform       : translate(-50%, -50%);
            width           : 40px;
            height          : 40px;
            border-radius   : 100%;
            border          : $turbo 1px solid;
            transition      : all .2s ease-in-out;
            opacity         : 0;
            position        : absolute;
            top             : 0;
            left            : 0;
            overflow        : hidden;
            z-index         : -1;
            background-color: $turbo;
        }

        &.background-circle--scale {
            mix-blend-mode: normal;
            z-index       : 9999;

            &:after {
                transition: all .2s ease-in-out;
                width     : 80px;
                height    : 80px;
                opacity   : 1;
            }
        }
    }

    .cursor-dot {
        width           : 0px;
        height          : 0px;
        transform       : translate(-50%, -50%);
        background-color: transparent;
        border-radius   : 100%;
        transition      : all 0.25s ease-in-out;
    }

    .cursor-dot--hide {
        width : 0px;
        height: 0px;
    }

    .cursor-text {
        transform     : translate(-50%, -50%) scale(0);
        width         : fit-content;
        height        : fit-content;
        transition    : transform 0.1s ease-in-out;
        top           : 50%;
        left          : 50%;
        color         : black;
        z-index       : 200000;
        position      : absolute;
        text-transform: uppercase;
        @include link-font(9px);
        @include tracking(200);
        line-height: 9px;
        text-align : center;
    }

    .cursor-text--anim {
        z-index  : 200;
        transform: translate(-50%, -50%) scale(1);
    }
}
