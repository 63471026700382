.gallery-wrap.parallax-image {

    picture[data-speed] {
        position  : fixed;
        top       : 0;
        left      : 0;
        z-index   : 0;
        width     : 100%;
        height    : 60vh;
        max-height: 100%;
    }
}
