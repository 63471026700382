// Branding, Marketing, Creative
.services-bmc-container {
    position: relative;
}

.services-bmc-wrapper {
    z-index : 1;
    position: relative;
}

.services-bmc-featured-labels-container {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.services-bmc-item {

    &-grid {
        display        : flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    .services-bmc-item-grid-col {
        &.-right {
            flex: 0 1 50%;

            @include media-breakpoint-up(lg) {
                flex: 0 1 65%;
            }
        }

        &.-left {
            flex           : 1 50%;
            display        : flex;
            justify-content: center;

            @include media-breakpoint-up(md) {
                visibility: hidden;
            }
        }

        .copy-box {
            padding-top    : 40px;
            display        : flex;
            flex-direction : column;
            justify-content: center;
            padding-bottom : 40px;

            @include media-breakpoint-up(md) {
                padding-top: 0;
            }

            @include media-breakpoint-up(lg) {
                max-width     : 660px;
                padding-bottom: 120px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 90px;
            }
        }

        .service-list {
            margin-top   : 1.5rem;
            margin-bottom: 0;

            li {
                opacity: 0;
            }
        }
    }
}


.services-circle-item {
    display        : flex;
    align-items    : center;
    justify-content: center;
    width          : clamp(200px, 45vw, 400px);
    height         : clamp(200px, 45vw, 400px);
    position       : relative;
    left           : 0;
    right          : 0;
    top            : 0;
    bottom         : 0;

    @include media-breakpoint-up(sm) {
        display        : flex;
        align-items    : center;
        justify-content: center;
        width          : 36vw;
        height         : 36vw;
        position       : relative;
        left           : 0;
        right          : 0;
        top            : 0;
        bottom         : 0;
    }

    @include media-breakpoint-up(lg) {
        width : 350px;
        height: 350px;
    }

    @include media-breakpoint-up(xl) {
        width : 400px;
        height: 400px;
    }


    .circle-shape {
        padding         : 2rem;
        transition      : all .9s ease-out;
        background-color: $turbo;
        @include magicRadialGradient();
        width        : 100%;
        height       : 100%;
        position     : absolute;
        border-radius: 50%;

        &.background-black {
            background: $black;
        }
    }

    .service-name {
        transition            : all 1s ease-out;
        -webkit-font-smoothing: antialiased;
        backface-visibility   : hidden;
        -webkit-font-smoothing: subpixel-antialiased;


    }

    .service-name {
        position: relative;
        z-index : 1;

        & * {
            margin-bottom: 0;
        }

        .hdr-lg {
            @include media-breakpoint-between(md, lg) {
                font-size: 6vw;
            }
        }

        .magic-bg-text {
            color         : $turbo;
            text-transform: none;
            @include magicBackgroundGradient();
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

// Mobile

.mobile {
    .circle-shape {

        &.background-black {
            background: $black;
        }
    }

    .services-bmc-featured-labels-container {
        display: none;
    }

    .services-bmc-item-grid-col {

        &.-left {
            visibility    : visible;
            padding-bottom: 3rem;

            .services-circle-item {
                padding-top   : 3rem;
                padding-bottom: 3rem;
            }



            .circle-shape {

                @include media-breakpoint-up(sm) {
                    max-width : 36vw;
                    max-height: 36vw;

                }

            }
        }
    }

    .services-bmc-item-grid {
        flex-direction: column;

        .copy-box {
            @include media-breakpoint-up(lg) {
                max-width     : 100%;
                margin-left   : auto;
                padding-bottom: 20px;
            }
        }


    }


}

.mobile [data-bmc-service='creative'] {
    padding-bottom: 60px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 120px;
    }
}
