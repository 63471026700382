.glitch-wrapper {
    width          : 100%;
    height         : auto;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.glitch {
    position: relative;
}

.glitch::before,
.glitch::after {
    color     : $white;
    content   : attr(title);
    position  : absolute;
    width     : 100%;
    height    : 100%;
    background: transparent;
    overflow  : hidden;
    top       : 0;
}

.glitch::before {
    left                     : 3px;
    text-shadow              : -2px 0 $core_green;
    animation-name           : glitch-animation-1;
    animation-duration       : 2s;
    animation-timing-function: linear;
    animation-delay          : 0s;
    animation-iteration-count: infinite;
    animation-direction      : reverse-alternate;
}

.glitch::after {
    left                     : -3px;
    text-shadow              : -2px 0 $core_blue;
    animation-name           : glitch-animation-2;
    animation-duration       : 2s;
    animation-timing-function: linear;
    animation-delay          : 0s;
    animation-iteration-count: infinite;
    animation-direction      : reverse-alternate;
}

@keyframes glitch-animation-1 {
    0% {
        clip: rect(60px, 750px, 77px, 30px);
    }

    5% {
        clip: rect(7px, 750px, 112px, 30px);
    }

    10% {
        clip: rect(64px, 750px, 43px, 30px);
    }

    15% {
        clip: rect(43px, 750px, 2px, 30px);
    }

    20% {
        clip: rect(149px, 750px, 115px, 30px);
    }

    25% {
        clip: rect(101px, 750px, 55px, 30px);
    }

    30% {
        clip: rect(133px, 750px, 115px, 30px);
    }

    35% {
        clip: rect(93px, 750px, 103px, 30px);
    }

    40% {
        clip: rect(93px, 750px, 82px, 30px);
    }

    45% {
        clip: rect(143px, 750px, 137px, 30px);
    }

    50% {
        clip: rect(117px, 750px, 81px, 30px);
    }

    55% {
        clip: rect(103px, 750px, 82px, 30px);
    }

    60% {
        clip: rect(50px, 750px, 70px, 30px);
    }

    65% {
        clip: rect(59px, 750px, 39px, 30px);
    }

    70% {
        clip: rect(12px, 750px, 64px, 30px);
    }

    75% {
        clip: rect(31px, 750px, 84px, 30px);
    }

    80% {
        clip: rect(11px, 750px, 45px, 30px);
    }

    85% {
        clip: rect(96px, 750px, 62px, 30px);
    }

    90% {
        clip: rect(139px, 750px, 14px, 30px);
    }

    95% {
        clip: rect(107px, 750px, 81px, 30px);
    }

    100% {
        clip: rect(103px, 750px, 47px, 30px);
    }
}

@keyframes glitch-animation-2 {
    0% {
        clip: rect(134px, 750px, 146px, 30px);
    }

    5% {
        clip: rect(51px, 750px, 76px, 30px);
    }

    10% {
        clip: rect(127px, 750px, 130px, 30px);
    }

    15% {
        clip: rect(147px, 750px, 90px, 30px);
    }

    20% {
        clip: rect(53px, 750px, 107px, 30px);
    }

    25% {
        clip: rect(114px, 750px, 11px, 30px);
    }

    30% {
        clip: rect(118px, 750px, 68px, 30px);
    }

    35% {
        clip: rect(87px, 750px, 132px, 30px);
    }

    40% {
        clip: rect(73px, 750px, 10px, 30px);
    }

    45% {
        clip: rect(65px, 750px, 75px, 30px);
    }

    50% {
        clip: rect(139px, 750px, 31px, 30px);
    }

    55% {
        clip: rect(148px, 750px, 91px, 30px);
    }

    60% {
        clip: rect(18px, 750px, 6px, 30px);
    }

    65% {
        clip: rect(16px, 750px, 100px, 30px);
    }

    70% {
        clip: rect(148px, 750px, 33px, 30px);
    }

    75% {
        clip: rect(13px, 750px, 96px, 30px);
    }

    80% {
        clip: rect(31px, 750px, 73px, 30px);
    }

    85% {
        clip: rect(71px, 750px, 94px, 30px);
    }

    90% {
        clip: rect(23px, 750px, 147px, 30px);
    }

    95% {
        clip: rect(51px, 750px, 23px, 30px);
    }

    100% {
        clip: rect(11px, 750px, 44px, 30px);
    }
}
