.scrolly-filmstrip {
    width    : 100vw;
    left     : 50%;
    transform: translateX(-50%);
    position : relative;

    .scroll-wrapper {
        display : flex;
        grid-gap: 20px;
        width   : 4000px;

        @include media-breakpoint-up(xl) {
            width: 300vw;
        }
    }

    .scroll-item {
        flex         : 0 1 240px;
        border-radius: 40px;
        overflow     : hidden;
        height       : 160px;

        @include media-breakpoint-up(md) {
            flex  : 0 1 360px;
            height: 240px;
        }

        img {
            object-fit: cover;
            height    : 100%;
        }
    }
}
