.our-why-panel {
    position: relative;
    display: flex;
    align-items: center;
    overflow: visible;
    z-index: 1;
    background-color: $white;
    margin-top: -1px;

    // Fingaprint bg
    @include fingerPrint();

    @include media-breakpoint-up(sm) {
        min-height: 700px;
    }

    @include media-breakpoint-up(md) {
        min-height: 780px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 880px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 1000px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 1100px;
    }

    .content {
        flex: 0 1 440px;
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 276px;

        @include media-breakpoint-up(sm) {
            max-width: 320px;
        }

        @include media-breakpoint-up(md) {
            max-width: 400px;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        @include media-breakpoint-up(lg) {
            height: 100vh;
            max-width: none;
            flex: 1 800px;

            .headline {
                max-width: 600px;
            }

            p {
                max-width: 430px;
            }
        }

        .headline {
            margin-bottom: 1rem;
        }

        .btn-primary {
            margin-top: 35px;
        }

    }

    // opposing lines
    #ourwhy_headline {
        display: flex;
        flex-direction: column;

        .lines-bruh:nth-child(even) {
            transform: translateX(-80px);
        }

        .lines-bruh:nth-child(odd) {
            transform: translateX(80px);
        }

        line-height: 42px;

        @include media-breakpoint-up(sm) {
            line-height: clamp(42px, 6.5vw, 78px);
        }

        @include media-breakpoint-up(lg) {
            line-height: clamp(42px, 7vw, 78px);
        }
    }

    .parallax-photo-container {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 0;
        max-width: 100vw;
        overflow: hidden;

        .photo {
            &-container {
                position: absolute;
                overflow: hidden;
                border-radius: 43%;

                img {
                    height: auto;
                }

                img.scale-up {
                    transform: scale(1.1);
                }
            }

            &-1 {
                border-radius: 20vw;
                left: -50px;
                top: 34%;

                img {
                    width: 30vw;
                }

                @include media-breakpoint-up(xxl) {
                    top: 32%;

                    img {
                        width: 24vw;
                    }
                }
            }

            &-2 {
                border-radius: 90px;
                left: 22%;
                top: 67%;
            }

            &-3 {
                border-radius: 47%;
                left: 15%;
                top: 20%;

                @include media-breakpoint-up(xl) {
                    left: 17%;
                }

                @include media-breakpoint-up(xxl) {
                    left: 18%;
                }
            }

            &-4 {
                border-radius: 300px;
                right: 20%;
                top: 25%;
                z-index: 2;
            }

            &-5 {
                border-radius: 20vw;
                right: -50px;
                top: 45%;

                img {
                    width: 30vw;
                }

                @include media-breakpoint-up(xxl) {
                    top: 42%;

                    img {
                        width: 24vw;
                    }
                }
            }
        }
    }

    // Mobile
    @include media-breakpoint-down(lg) {
        .parallax-photo-container {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: 0;
            z-index: 0;

            .photo {
                &-container {

                    img {
                        height: auto;
                    }

                }

                &-1 {
                    border-radius: 14vw;
                    left: -10px;
                    top: 30%;

                    img {
                        width: 26vw;
                    }
                }

                &-2 {
                    border-radius: 8vw;
                    left: 10%;
                    top: 65%;

                    img {
                        width: 15vw;
                    }
                }

                &-3 {
                    border-radius: 8vw;
                    left: 1%;
                    top: 8%;

                    img {
                        width: 14vw;
                    }
                }

                &-4 {
                    right: -3%;
                    top: 25%;

                    img {
                        width: 12vw;
                    }
                }

                &-5 {
                    border-radius: 14vw;
                    right: -13px;
                    top: 45%;

                    img {
                        width: 26vw;
                    }
                }




            }
        }
    }
}

.desktop .our-why-panel .parallax-photo-container {
    overflow: hidden;
}
