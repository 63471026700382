.newsletter-page {

    .newsletter-masthead {
        height: auto;
        padding: 90px 0 60px;

        @include media-breakpoint-up(xl) {
            min-height: 380px;
        }

        p {
            text-wrap: balance;
        }
    }

    .connect-with-us-block .form-standard .card-email-input {
        background-color: $input_border;

        &::-webkit-input-placeholder {
            color: $black;
        }

        &:-moz-placeholder {
            color: $black;
        }

        &::-moz-placeholder {
            color: $black;
        }
    }

    .newsletter-tracking {
        background-color: $white;

        &>p {
            color: $black ;
            padding-top: 30px;
            padding-bottom: 60px;

        }
    }

    .main-footer .footer-grid .footer-logo-wrapper {
        @include media-breakpoint-up(lg) {
            top: 40px;
        }
    }
}
