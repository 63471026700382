.decorative-wave {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;

    &.reversed {
        transform: scaleX(-1);
    }

    &.purple {

        .wave-background {
            .fill {
                background-color: $purple;
            }

            .wave-svg {
                path,
                rect {
                    fill: $purple;
                }
            }
        }
    }

    &.light-blue {
        .wave-background {
            .fill {
                background-color: $turbo;
            }

            .wave-svg {
                path,
                rect {
                    fill: $turbo;
                }
            }
        }
    }

    &.dark-blue {
        .wave-background {
            .fill {
                background-color: $turbo;
            }

            .wave-svg {
                path,
                rect {
                    fill: $turbo;
                }
            }
        }
    }

    &.green {
        .wave-background {
            .fill {
                background-color: $turbo;
            }

            .wave-svg {
                path,
                rect {
                    fill: $turbo;
                }
            }
        }
    }

    &.grey {
        .wave-background {
            .fill {
                background-color: $turbo;
            }

            .wave-svg {
                path,
                rect {
                    fill: $turbo;
                }
            }
        }
    }

}

// BG wave with top, middle, and bottom tiling to allow vertical content to expand without scaling issues
.wave-background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .wave-svg {
        width: 100%;
        flex: 0 1 auto;
        left: 0;
        height: auto;

        // Make sure top and bottom of wave are scaled down vertically for huge screens
        @include media-breakpoint-up(xxl) {
            height: 150px;
        }
    }

    .wave-top {
        top: 0;
        z-index: 0;
    }

    // .fill bridges the gap between top and bottom of wave
    .fill {
        height: 100%;
        flex: 1;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        margin-top: -1px;
        height: calc(100% + 1px);
    }

    .wave-bottom {
        bottom: 0;
        z-index: 1;
        margin-top: -1px;
    }
}
