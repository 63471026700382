.img-caption {
    display   : block;
    margin-top: .25rem;

    @include media-breakpoint-up(md) {
        margin-top: .5rem;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 1rem;
    }

    span,
    span>p {
        @include fontSize(11px);
        line-height: 17px;
        display    : block;

        @include media-breakpoint-up(md) {
            @include fontSize(13px);
            line-height: 20px;
        }
    }
}
