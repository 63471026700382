.home-page {

    .fingerprint-bg {
        @include fingerPrint($light-print:true);
    }

    .main-content {
        background-color: $woodsmoke;
        z-index         : 1;
    }
}
