.main-footer {
    overflow: hidden;
    position: relative;
    z-index: 1;
    min-height: 250px;
    padding-bottom: 4rem;
    margin-top: -1px;

    .container.inner {
        position: relative;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        min-height: 262px;
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .footer-grid {
        position: relative;
        display: grid;
        grid-template-columns: 1fr .5fr 1fr;
        grid-template-rows: 1fr 1fr .25fr;
        padding: 0;
        width: 100%;
        margin: 0;

        .left-side,
        .right-side {
            position: relative;
        }

        .left-side {
            grid-column: 1 / span 1;
            grid-row: 1 / span 2;
            z-index: 2;
        }

        .right-side {
            grid-column: 2 / span 2;
            grid-row: 1 / span 2;
            z-index: 1;
        }

        @include media-breakpoint-up(md) {
            padding: 0 .75rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 .5rem;
            margin-left: -.5rem;
        }

        .footer-logo-wrapper {
            grid-column: 2 / span 2;
            grid-row: 2 / span 1;
            max-width: 100%;
            padding: 0;
            position: absolute;
            right: -1.25rem;
            height: auto;
            transform: scale(1);
            width: clamp(200px, 27vw, 378px);
            z-index: 100;

            &:hover {

                .footer-logo {
                    transform: rotate(-5deg);
                }
            }

            .footer-logo {
                width: 100%;
                height: 100%;
                position: relative;
                transition: transform .3s ease-in;
            }

            @include media-breakpoint-up(sm) {
                grid-column: 2/span 2;
            }

            @include media-breakpoint-up(md) {
                grid-row: 2 / span 2;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .footer-logo {
                    width: clamp(150px, 21vw, 275px);
                }
            }

            @include media-breakpoint-up(lg) {
                margin-top: 0;
                grid-row: 1 / span 1;

                .footer-logo {
                    width: clamp(250px, 25vw, 275px);
                }
            }

            @include media-breakpoint-up(xl) {
                grid-column: 3/span 1;

                .footer-logo {
                    width: clamp(250px, 35vw, 312px);
                }
            }

            @include media-breakpoint-up(xxl) {
                .footer-logo {
                    margin-right: -7vw;
                    width: clamp(250px, 35vw, 377px);
                }
            }
        }
    }


    // left side has the desktop social icons
    .left-side {
        padding: 50px 0;
        padding-left: .75rem;

        @include media-breakpoint-up(md) {
            padding-left: 0;
        }

        @include media-breakpoint-up(lg) {
            width: 60vw;
            max-width: 660px;
        }

        @include media-breakpoint-up(xl) {
            padding: 95px 0;
            width: 50vw;
        }

        .links {
            list-style: none;
            padding-left: 0;
            padding-right: 2rem;
            color: $white;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                padding-right: 0;
            }

            .link {
                @include fontSize(8px);
                line-height: 12px;
                width: max-content;

                @include media-breakpoint-up(sm) {
                    @include fontSize(10px);
                    line-height: 14px;
                }

                &:before {
                    visibility: hidden;
                }

                &:after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 100%;
                    transform: scale(0);
                    background-color: $turbo;
                    transition: transform .2s ease-in;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    z-index: -1;
                    transform-origin: left center;
                    @include magicHoverLine();
                }

                &:hover {
                    background-image: none;

                    &:after {
                        @include magicHoverLine();
                        transform: scale(1);
                    }

                }
            }
        }

        .social-nav {
            display: flex;
            padding-top: 13px;
            margin-right: -0.5rem;

            @include media-breakpoint-down(md) {
                margin-right: 0;
            }

            svg {
                filter: drop-shadow(3px 5px 2px rgb(255 255 255 / 0.8));
            }

            .social-link {
                margin-right: 4px;
                transform-origin: bottom center;

                .social-icon {
                    height: 15px;
                    transition: transform 150ms ease;
                }

                &:hover {
                    img {
                        transform: translateY(-1px) scale(1.1);
                    }
                }
            }
        }


        @include media-breakpoint-down(lg) {
            display: block;

            .links {
                display: block;
            }
        }
    }

    .right-side {
        position: relative;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include media-breakpoint-down(lg) {
            padding-top: 50px
        }

        @include media-breakpoint-up(md) {
            grid-column: 3/span 2;
            grid-row: 1/span 2;
        }


        .social-link {
            margin-right: 3px;

            @include media-breakpoint-down(md) {

                transition: transform 150ms ease;
                transform-origin: bottom center;

                @include media-breakpoint-up(sm) {
                    width: auto;
                }

                &:last-child {
                    margin-right: 0;
                }

                .social-icon {
                    height: 12.5px;
                    width: 15px;

                    @include media-breakpoint-up(sm) {
                        width: auto;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                margin-right: 6px;
            }
        }
    }

}

.contact-page .main-footer .footer-grid .footer-logo-wrapper {
    @include media-breakpoint-up(lg) {
        top: 20px;
    }
}

.home-page .main-footer {
    padding-top: 40px;

    @include media-breakpoint-up(xl) {
        padding-top: 0;
    }
}

.main-footer .social-link {
    text-decoration: none;
}

.mobile:not(.home-page) .main-footer .footer-grid .footer-logo-wrapper {
    @include media-breakpoint-up(md) {
        margin-top: 20px;
    }
}
