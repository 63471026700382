.news-index {
    .filter-row {}

    .filter-category-list {
        grid-gap       : 13px;
        list-style-type: none;
        flex-wrap      : wrap;
    }

    .cat-li-item {

        // btn styles
        .btn-filter {
            background-color: transparent;
            border-radius   : 10px;
            min-height      : 40px;
            height          : 100%;
            padding         : 14px;
            border          : 1px solid $filter_btn_border_color;

            &:hover {
                color: $white;
            }
        }

        &.active {
            .btn-filter {
                background-color: $black;
                color           : $white;
                pointer-events  : none !important;

            }
        }
    }

    .form-card {
        padding      : 2rem;
        margin-bottom: 2rem;
        border-radius: clamp(20px, 3vw, 30px);

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            padding      : 1rem;
        }

        @include media-breakpoint-up(lg) {
            padding: clamp(24px, 7vw, 37px);
        }



        .content-wrap {
            padding-top: clamp(2%, 7vw, 26px);
            text-align : center;
        }

        .card-headline {
            text-wrap: pretty;
        }

        .card-email-input {
            text-align      : center;
            border-color    : $drop_shadow;
            background-color: $raisin;
            color           : $white;
        }

        .news-card-footer {
            margin-bottom: 0;
        }

        #send-icon {
            height: 30px;
            width : 30px;
        }
    }
}

.core-card-grid {
    position      : relative;
    padding-bottom: 5rem;
    padding-top   : 0;

    &.load-more-padding-bottom {
        padding-bottom: 7rem;
    }

    @include media-breakpoint-up(sm) {
        display              : grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap             : 1.25rem;
        grid-template-rows   : auto;

        @include media-breakpoint-up(lg) {
            grid-gap             : 1rem;
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-up(lg) {
            grid-gap: 2rem;
        }
    }

    &.two-up-grid {
        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    #js-more-news {
        position : absolute;
        bottom   : 40px;
        margin   : 0 auto;
        left     : 50%;
        transform: translateX(-50%);
    }
}
