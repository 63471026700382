// Contact form footer block - detail page
.connect-with-us-block {

    .form-standard {

        .inset-field-btn {
            display              : grid;
            grid-template-columns: 3fr 145px;
            grid-template-rows   : 66px;
            align-items          : center;
            height               : 66px;
        }

        .card-email-input {
            grid-column     : 1 / span 2;
            grid-row        : 1;
            color           : $white;
            height          : 66px;
            background-color: $woodsmoke;
            border-radius   : 33px;
            border          : 1px solid $contact_email_field_border;
            padding-left: 1.25rem;

            @include media-breakpoint-up(md) {
                padding-left: 2rem;
            }
        }

        .btn-submit {
            grid-row    : 1;
            grid-column : 2 / span 1;
            height      : 50px;
            margin-right: 7px;
        }
    }

    &.full-version {

        .form-standard {
            display  : flex;
            flex-wrap: wrap;

            justify-content: space-between;
            grid-gap       : 1rem;
            column-gap     : 1.25rem;

            .form-field {
                position     : relative;
                display      : inline-flex;
                margin-bottom: 0;
                flex         : 1 0 100%;

                &.textinput:nth-child(-n+3) {
                    flex-basis: 48%;
                }

                &.required {
                    label {
                        &:before {
                            content : '*';
                            color   : $white;
                            position: absolute;
                            right   : -8px;
                            top     : -2px;
                        }
                    }
                }

                label {
                    position  : absolute;
                    top       : .75rem;
                    left      : .75rem;
                    transition: all .2s ease-out;

                    @include media-breakpoint-between(md, lg) {
                        font-size: 14px;
                        top      : .5rem;

                        &:after {
                            height: 40%;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        top: 1rem;
                    }

                    &:after {
                        content         : '';
                        height          : 61%;
                        position        : absolute;
                        width           : calc(100% + .5rem);
                        left            : -.25rem;
                        top             : 0;
                        z-index         : -1;
                        opacity         : 0;
                        background-color: $woodsmoke;
                    }

                }

                &.hiddeninput {
                    display: none;
                }

                &.privacy-check,
                &.newsletter-check {


                    label {

                        @include fontSize(12px);
                        // transform: none;
                        top : auto;
                        left: 26px;
                    }
                }

                &.active label {
                    text-shadow: 2px 2px 4px rgba($black, 1);

                    &:after {
                        opacity: 1;
                    }

                    @include media-breakpoint-between(md, lg) {
                        transform: translateY(-1.5rem) scale(.8) translateX(-.5rem);
                    }

                    @include media-breakpoint-up(lg) {
                        transform: translateY(-2rem) scale(.8) translateX(-.5rem);
                    }

                    transform: translateY(-1.25rem) scale(.8) translateX(-.75rem);
                }

                input,
                textarea {
                    color           : $white;
                    background-color: $raisin;
                    border          : 1px solid $core_grey;
                }

                a {
                    color: $core_blue_oklch;
                }
            }
        }
    }
}
