.two-core-teams-panel {
    position        : relative;
    display         : flex;
    align-items     : center;
    overflow        : visible;
    background-color: $white;
    margin-top      : -1px;
    transition      : background-color .4s ease-in;
    padding-bottom  : 50px;

    // Fingaprint bg
    @include fingerPrint();

    &.side-by-side-block:not(.dark-content) {
        background-image: none;

        padding-top   : 60px;
        padding-bottom: 60px;

        @include media-breakpoint-up(lg) {
            padding-top   : 90px;
            padding-bottom: 90px;
        }

        @include media-breakpoint-up(xl) {
            padding-top   : 120px;
            padding-bottom: 120px;
        }


        .hdr-sm-eyebrow,
        .hdr-lg-plus {
            font-family: $display-font-semi-bold;
        }

    }


    .home-page &,
    &.side-by-side-block.dark-content {
        background-image: url('/static/img/svg/fingerprint-nav.svg');
        padding-top     : 60px;
        padding-bottom  : 60px;

        @include media-breakpoint-up(lg) {
            padding-top   : 90px;
            padding-bottom: 90px;
        }

        @include media-breakpoint-up(xl) {
            padding-top   : 120px;
            padding-bottom: 120px;
        }

        &.activated {
            background-color: $raisin;
        }

        .link.module-link {
            color: $white;

            &:hover {
                --text: #fff;
            }
        }
    }


    @include media-breakpoint-up(lg) {
        padding-bottom: 50px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 120px;
    }



    .content-row {

        display       : flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            padding-top   : 30px;
            padding-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            padding-top: 60px;
        }
    }

    .cta-link {
        margin-top: 1rem;
        color     : $white;
        --text    : #fff;
    }

    .team-card {
        padding-top   : 40px;
        padding-bottom: 0;
        overflow      : hidden;

        @include media-breakpoint-up(sm) {
            padding-bottom: 40px;
        }

        .copy-wrap {
            position: relative;
        }

        &.brand .copy-wrap {

            @include media-breakpoint-up(md) {
                transform    : translateX(50vw);
                padding-right: 30px;
            }
        }

        &.design .copy-wrap {

            @include media-breakpoint-up(md) {
                transform   : translateX(-50vw);
                padding-left: 30px;
            }
        }
    }

    .divider-column {
        max-width: 1px;
        padding  : 0;
        @include magicBackgroundGradient();
        background-size: 530px 100%;
    }
}

.custom-landing-detail {
    .block-type-one_column_full_bleed {
        padding-left : 0;
        padding-right: 0;

        .side_by_side_colunn_block {
            .row.one-column-row.fullbleed .one-column.copy-column {
                max-width: 100%;
            }
        }
    }
}
