.content-with-sidebar {
    .headline {
        margin-bottom: 50px;
    }

    .copy-column {
        p {
            max-width: 660px;
        }
    }

    .sidebar-column {
        padding-left: 20px;
        border      : 1px solid red;

        @include media-breakpoint-up(xl) {
            padding-left: 55px;
        }
    }
}
