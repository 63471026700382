.careers-page {
    .careers-masthead {
        min-height: 220px;

        @include media-breakpoint-up(lg) {
            height        : 380px;
            padding-top   : 0;
            padding-bottom: 0;
        }
    }

    .container.inner.intro-block {

        .headline-copy-col {

            @include media-breakpoint-up(xl) {
                padding-right: 5rem;
            }
        }
    }

    .jobs-callout-copy {
        margin-top: 55px;
    }

    .job-posts-list {
        list-style   : none;
        padding-left : 0;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }

        li {
            height            : 40px;
            display           : flex;
            border-image-slice: 1;

            &.border-gradient {
                border-top        : 1px solid;
                border-image-slice: 1;
                border-width      : 1px;
                border-left       : none;
                border-right      : none;
                border-bottom     : none;

                &:last-child {
                    border-bottom: 1px solid;
                }
            }

            &.border-gradient-purple {
                border-image-source: linear-gradient(to right, $core_blue, $core_green, $turbo);
            }

            @include media-breakpoint-up(md) {
                height: 66px;
            }

            .job-post {
                flex           : 1 0 100%;
                display        : flex;
                align-items    : center;
                justify-content: space-between;

                svg {
                    max-height: 9px;

                    path {
                        fill: $black;
                    }

                    @include media-breakpoint-up(md) {
                        max-height: 18px;
                    }
                }
            }

            a.job-post-link {
                @include link-font(8px);
                color           : $black;
                display         : flex;
                align-items     : center;
                flex            : 1 100%;
                height          : 100%;
                transform-origin: left center;
                transition      : transform .2s ease-in-out;
                padding-top     : 0;

                @include media-breakpoint-up(md) {
                    @include link-font(9px);
                }

                &:before,
                &:after,
                &:hover:before {
                    display: none;
                }

                &:hover {
                    color           : $black;
                    transform       : scale(1.05);
                    background-image: none;
                }
            }
        }
    }

    // MKE Tool branding
    &.design-services {
        .cc-contact-panel-block {
            background-color: $core_grey;
        }
    }

}
