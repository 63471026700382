.keep-exploring-panel {
    overflow: hidden;

    &+.cc-contact-panel-block {
        margin-top: -1px;
    }

    .js-turbo-line-wrap {

        .turbo-line-stretch {
            transform: scaleX(0);
            height   : 2px;
            width    : 100%;
            @include magicBackgroundGradient();
        }
    }

    .grid-wrap {
        display              : grid;
        flex-direction       : column;
        grid-gap             : 1rem;
        grid-template-columns: 1fr 1fr;
        position             : relative;
        overflow             : visible;
        padding              : 0 .5rem;

        &:after {
            width           : calc(500vw);
            position        : absolute;
            height          : calc(50% + 2px);
            bottom          : -2px;
            left            : -100vw;
            background-color: $woodsmoke;
            content         : '';
            z-index         : 0;
        }

        @include media-breakpoint-up(md) {
            grid-gap: 2rem;
        }
    }

    .photo-wrap {
        @include smallRadiusCorner();
        overflow       : hidden;
        position       : relative;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;

        &:before {
            content         : '';
            background-color: rgba($color: $black, $alpha: 0.4);
            width           : 100%;
            height          : 100%;
            position        : absolute;
            z-index         : 3;
        }

        .article-img {
            @include smallRadiusCorner();
            overflow: hidden;
        }
    }

    .thumbnail-text {
        color   : $white;
        position: absolute;
        z-index : 4;

        &.project-reveal {
            visibility: hidden;
            opacity   : 0;
            transform : translateY(25px);
        }
    }

    .explore-link {
        position: relative;
        z-index : 1;

        .thumbnail-text {
            margin-bottom: 0;
            display      : flex;
            align-items  : center;

            &:before,
            &:after {
                content          : '';
                background-repeat: no-repeat;
                background-size  : auto 100%;
                width            : 1rem;
                height           : 1rem;
                display          : inline-flex;
                overflow         : hidden;
                transition       : all .2s ease-in-out .2s;

                @media (min-width: 420px) {
                    width : 10px;
                    height: 10px;
                }

                @include media-breakpoint-up(sm) {
                    width : 20px;
                    height: 1.25rem;
                }
            }
        }

        &.previous,
        &.next {
            .thumbnail-text {
                transition: .2s all ease-in-out;

                &:before,
                &:after {
                    position  : absolute;
                    transition: .2s all ease-in-out;
                }
            }

            &:hover {
                .thumbnail-text {

                    &:before,
                    &:after {
                        width: 50px;
                    }

                }
            }
        }

        &.previous {
            .thumbnail-text {
                padding-left: 1.5rem;
                margin      : 0 0.75rem;

                @media (min-width: 420px) {
                    padding-left: 1rem;
                }

                @include media-breakpoint-up(sm) {
                    padding-left: 30px;
                }

                &:before {
                    background-image   : url('/static/img/icons/arrows/prev-arrow.svg');
                    background-position: left center;
                    left               : 0;
                }

                &:after {
                    display: none;
                }
            }
        }

        &.next {
            .thumbnail-text {
                padding-right: 1.5rem;
                margin       : 0 0.75rem 0 1rem;

                @media (min-width: 420px) {
                    padding-right: 1rem;
                }

                @include media-breakpoint-up(sm) {
                    padding-right: 30px;
                }

                &:after {
                    background-image   : url('/static/img/icons/arrows/next-arrow.svg');
                    background-position: right center;
                    right              : 0;
                }

                &:before {
                    display: none;
                }
            }
        }
    }

    @media (hover: hover) {
        .explore-link:hover {
            &.previous {
                .thumbnail-text {
                    &:before {
                        left: -.5rem;

                        @include media-breakpoint-up(sm) {
                            left: -1rem;
                        }
                    }
                }
            }

            &.next {
                .thumbnail-text {
                    &:after {
                        right: -.5rem;

                        @include media-breakpoint-up(sm) {
                            right: -1rem;
                        }
                    }
                }
            }

            .thumbnail-text {
                visibility: hidden;
                opacity   : 0;
                transform : translateY(-50px);

                &.project-reveal {
                    visibility: visible;
                    opacity   : 1;
                    transform : translateY(0);
                }
            }
        }
    }



}
