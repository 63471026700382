.arrow-right-with-hover {
    .svg {
        overflow        : visible;
        position        : absolute;
        transition      : right .3s linear;
        transform-origin: left center;
        right           : 1rem;
    }

    #arrow-right {
        fill: $white;

        &.black {
            fill: $black;
        }
    }

    &:hover {

        .svg {
            right: 0;
        }

        #arrow-right {
            fill: $turbo;

            &.black {
                fill: $black;
            }
        }
    }
}
