// Global mixins
@use "sass:math";

// Font size functin / mixin
@function calculateRem($size) {
    $remSize: math.div($size, 16px);
    @return #{$remSize}rem;
}

@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}

// Calculate tracking from Adobe XD tracking value on text & convert to letter-spacing property value in pixels
@function tracking($target) {
    @return math.div($target, 1000) * 1em;
}

@mixin tracking($target) {
    letter-spacing: tracking($target);
}

// Display fonts
@mixin display-font-thin($size) {
    font-size  : $size;
    font-family: $display-font-thin;
}

@mixin display-font-reg($size) {
    font-size  : $size;
    font-family: $display-font-reg;
}

@mixin display-font-med($size) {
    font-size  : $size;
    font-family: $display-font-med;
}

@mixin display-font-semi-bold($size) {
    font-size  : $size;
    font-family: $display-font-semi-bold;
}

@mixin display-font-bold($size) {
    font-size  : $size;
    font-family: $display-font-bold;
}

// Link fontface
@mixin link-font($size, $style: normal, $weight: 700) {
    @include fontSize($size);
    font-weight: $weight;
    font-style : $style;
    font-family: $link-font;
}

// Body fonts
@mixin body-font-light($size, $style: normal, $weight: 400) {
    font-size  : $size;
    font-style : $style;
    font-weight: $weight;
    font-family: $body-font;
}

@mixin body-font-bold($size, $style: normal, $weight: 700) {
    font-size  : $size;
    font-style : $style;
    font-weight: $weight;
    font-family: $body-font;
}

@mixin smallCaps($size) {
    @include fontSize($size);
    @include tracking(75);
    text-transform: uppercase;
}

@mixin imgCover() {
    height    : 100%;
    width     : 100%;
    object-fit: cover;
}

@mixin magicHoverLine() {
    transition      : .2s all linear;
    z-index         : 0;
    background-color: $turbo;
    @include magicBackgroundGradient();

    &:hover {
        animation-speed: 20ms;
    }
}

@mixin transformCenter() {
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
}

@mixin textShadow($color) {
    text-shadow: 0 0 1px $color;
}

@mixin linkHover() {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@mixin cite() {
    display       : inline-flex;
    text-transform: uppercase;
    @include link-font(8px);
    line-height: 13px;
    @include tracking(200);

    @include media-breakpoint-up(md) {
        @include link-font(9px);
    }
}

@mixin navItem() {
    @include display-font-semi-bold(40px);
    text-decoration: none;
    line-height    : clamp(43px, 6vw, 84px);
    font-size      : clamp(37px, 5vw, 74px);
}

@mixin bigRadiusCorner($corner) {
    border-#{$corner}-radius: clamp(75px, 8vw, 150px);
}

@mixin smallRadiusCorner() {
    border-radius: 20px;

    @include media-breakpoint-up(md) {
        border-radius: clamp(30px, 3vw, 40px);
    }
}

@mixin magicPulse() {
    background-image       : linear-gradient(45deg, $turbo_oklch, $turbo_oklch, $core_green_oklch, $core_blue_oklch, $core_blue_oklch, $core_green_oklch, $turbo_oklch, $turbo_oklch);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size        : 100% 100%;
}

@mixin magicBackgroundGradient() {
    background-image   : linear-gradient(45deg, $turbo_oklch, $turbo_oklch, $core_green_oklch, $core_blue, $core_blue_oklch, $core_green_oklch, $turbo_oklch, $turbo_oklch);
    background-color   : $turbo;
    background-size    : 600% 100%;
    animation          : gradient 16s linear infinite;
    animation-direction: alternate;
}

@mixin magicBackgroundGradient2() {
    background-image   : linear-gradient(45deg, $turbo_oklch, $core_green_oklch, $core_blue_oklch, $core_blue_oklch, $core_green_oklch, $turbo_oklch, );
    background-color   : $turbo;
    background-size    : 600% 100%;
    animation          : gradient 25s linear infinite;
    animation-direction: alternate-reverse;
}

@mixin magicRadialGradient() {
    background: radial-gradient(closest-side, $turbo_oklch,
            $core_green_oklch,
            $core_blue_oklch, );
    background-size    : 600% 600%;
    animation          : gradient 16s linear infinite;
    animation-direction: alternate;
}

@mixin cursorMagicRadialGradient() {
    background: radial-gradient(closest-side, $turbo_oklch,
            $core_green_oklch,
            $core_blue_oklch, );
    background-size    : 500% 650%;
    animation          : gradient 1s linear infinite;
    animation-direction: alternate;
}

@mixin fingerPrint($light-print: true) {
    background-position: left bottom;
    background-repeat  : repeat;
    background-size    : 45vw;

    @include media-breakpoint-up(sm) {
        background-size: 35vw auto;
    }

    @include media-breakpoint-up(md) {
        background-size: 25vw auto;
    }

    @include media-breakpoint-up(lg) {
        background-size: 18vw auto;
    }

    @include media-breakpoint-up(xl) {
        background-size: auto;
    }

    @if $light-print {
        // 5% opacity
        background-image: url('/static/img/svg/fingerprint-v3.svg');
    }

    @else {
        // 15% opacity
        background-image: url('/static/img/svg/fingerprint-opacity-fifteen.svg');
    }
}

@mixin mastheadPadding() {
    padding-top   : clamp(80px, 13vw, 130px);
    padding-bottom: clamp(80px, 16vw, 160px);
}

@mixin blockPaddingMedium() {
    padding-top   : $block-spacer-40;
    padding-bottom: $block-spacer-40;

    @include media-breakpoint-up(md) {
        padding-top   : $block-spacer-60;
        padding-bottom: $block-spacer-60;
    }

    @include media-breakpoint-up(xl) {
        padding-top   : $block-spacer-110;
        padding-bottom: $block-spacer-110;
    }
}

@mixin blockPaddingXtraMedium() {
    padding-top   : $block-spacer-40;
    padding-bottom: $block-spacer-40;

    @include media-breakpoint-up(md) {
        padding-top   : $block-spacer-60;
        padding-bottom: $block-spacer-60;
    }

    @include media-breakpoint-up(xl) {
        padding-top   : $block-spacer-90;
        padding-bottom: $block-spacer-90;
    }
}
