.benefits-panel-block {
    padding-bottom: 65px;

    @include media-breakpoint-up(xl) {
        min-height: 960px;
        padding-bottom: $block-spacer-60;
    }

    .benefits-copy-container {
        flex: 0 1 50%;

        .benefits-copy {

            .benefits-body {
                max-width: clamp(100%, 40vw, 560px);
            }

        }

        @include media-breakpoint-up(xl) {
            padding-top: 30px;
        }

        @include media-breakpoint-up(xxl) {
            flex: 0 1 660px;
        }
    }

    .benefits-list {
        margin-top: 23px;
        padding-left: 1rem;

        @include media-breakpoint-up(xl) {
            margin-top: 38px;
        }
    }

    .logo-wrap {
        flex-direction: column;
        margin-top: 40px;
        grid-gap: 40px;
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }

        .logo img {
            max-width: 100%;
        }
    }

    .benefits-photo-container {
        flex: 1;
        position: relative;
        align-self: stretch;
        overflow: visible;

        &.col.expand-visible {
            padding-top: 40px;


            @include media-breakpoint-up(md) {
                width: 50vw;
                overflow: hidden;
                height: 620px;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 0;
                overflow: visible;
            }
        }

        .photo-wrapper {
            position: relative;
            display: flex;
            right: -2rem;
            width: calc(100% + 2rem);

            @include media-breakpoint-up(xl) {
                max-width: 890px;
            }

            .photo-parent {
                z-index: 1;
            }

            &:before {
                @include media-breakpoint-down(xl) {
                    max-width: calc(100% - 2rem);
                    right: 4rem;
                    height: calc(100% - 1rem);
                }
            }

            img {
                max-width: 100%;
                background-color: $raisin;
                height: calc(100vw * 3/6);

                @include media-breakpoint-up(md) {
                    height: auto;
                }

                @include media-breakpoint-up(xl) {
                    max-width: 44vw;
                }
            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                width: 40vw;
                min-height: 720px;
                height: 100vh;
                right: 0;
            }

            @include media-breakpoint-up(xxl) {
                width: 35vw;
            }
        }
    }
}
