.multiple-copy-block {
    position: relative;

    .grid-row {

        @include media-breakpoint-up(md) {
            display: grid;
            grid-column-gap: 20px;
        }

        @include media-breakpoint-up(xl) {
            grid-column-gap: 34px;
        }

        &.two-block {
            grid-template-columns: 1fr 1fr;
        }

        &.multi-block {
            grid-template-columns:  repeat(3, 1fr );
            grid-row-gap: 20px;

            @include media-breakpoint-up(md) {
                display: flex;
                align-items: stretch;
                justify-content: center;
                flex-wrap: wrap;
            }

            @include media-breakpoint-up(xl) {
                grid-row-gap: 34px;
            }

            .card {
                flex: 0 1 31.3%;

                @include media-breakpoint-up(xl) {
                    flex: 0 1 324px;
                }

                @include media-breakpoint-up(xxl) {
                    flex: 0 1 350px;
                }
            }

            .decorative-wave {
                &.light-blue {
                    .wave-color {
                        fill: $turbo;
                    }
                }

                &.green {
                    .wave-color {
                        fill: $turbo;
                    }
                }

                &.dark-blue {
                    .wave-color {
                        fill: $turbo;
                    }
                }

                &.light-grey {
                    .wave-color {
                        fill: $turbo;
                    }
                }

                &.purple {
                    .wave-color {
                        fill: $purple;
                    }
                }
            } // .decorative-wave
        } // .multi-block
    }
}

// IE11 support
.ie11 .multiple-copy-block {
    position: relative;

    .grid-row {
        display: flex!important;
        flex-direction: row;
        flex-wrap: wrap;

        .card {
            margin-right: 1.25rem;
            flex-basis: 31.5%;
            max-width: 31.5%;
            margin-bottom: 32px;
        }
    }
}
