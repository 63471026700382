.services {

    .scrolling-marquee {
        @include magicBackgroundGradient2;

        .marquee__part .value {
            color: $white;
        }
    }
}
