$headHeightSm: 350px;
$headHeightMd: clamp(350px, 50vh, 400px);
$headHeightLg: clamp(350px, 60vh, 650px);

.about-masthead {
    overflow: hidden;

    @include media-breakpoint-up(md) {
        overflow: visible;
        @include bigRadiusCorner(bottom-left);
    }

    .copy-block {
        width         : 100%;
        padding-top   : 90px;
        padding-bottom: 40px;
        max-width     : 100%;
        text-align    : center;

        .header-lg-plus {
            @include display-font-med(32px);
            line-height: clamp(38px, 6.5vw, 78px);
            font-size  : clamp(34px, 5vw, 68px);

            @include media-breakpoint-up(sm) {
                line-height: clamp(39px, 7.5vw, 78px);
                font-size  : clamp(34px, 7vw, 68px);
            }
        }

        @include media-breakpoint-up(md) {
            text-align    : left;
            padding-top   : 0;
            padding-bottom: 0;
            max-width     : 660px;
        }
    }

    .col.expand-visible {
        position: relative;
        width   : 100%;
        left    : auto;
        overflow: visible;

        @include media-breakpoint-up(md) {
            position: absolute;
            width   : 50%;
            left    : 50%;
            overflow: visible;
        }
    }

    &.split-masthead .content-panel {
        // @include media-breakpoint-up(md) {
        //     height: $headHeightSm;
        // }

        // @include media-breakpoint-up(lg) {
        //     height: $headHeightMd;
        // }

        // @include media-breakpoint-up(xl) {
        //     height: $headHeightLg;
        // }

    }

    &.split-masthead .photo-wrapper {
        position: relative;
        display : flex;
        right   : -2rem;
        width   : calc(100% + 2rem);

        &:before {
            @include media-breakpoint-down(md) {
                max-width: calc(100% - .25rem);
                right    : 2rem;
                height   : calc(100% - 1rem);
            }
        }

        .column-img {
            display: flex;
        }

        img {
            max-width       : 100%;
            background-color: $raisin;
            height          : calc(100vw * 3/6);

            @include media-breakpoint-up(md) {
                max-width    : 44vw;
                height       : auto;
                object-fit   : cover;
                // min-height: 0;
                // max-height: none;
            }
        }

        @include media-breakpoint-up(md) {
            position     : absolute;
            width        : 40vw;
            min-height   : 0;
            right        : 0;
            // height    : $headHeightSm;
        }

        @include media-breakpoint-up(lg) {
            // height: $headHeightMd;
        }

        @include media-breakpoint-up(xl) {
            // height: $headHeightLg;
        }

        @include media-breakpoint-up(xxl) {
            width: 35vw;
        }
    }
}

.about-page.design-services .intro-block {
    padding-top: 60px;
}

.about-masthead.shorty {
    &.split-masthead {
        .content-panel {
            @include media-breakpoint-up(md) {
                height: $headHeightSm;
            }

            @include media-breakpoint-up(lg) {
                height: $headHeightMd;
            }

            @include media-breakpoint-up(xl) {
                height: $headHeightLg;
            }

        }

        .photo-wrapper {
            @include media-breakpoint-up(md) {
                height: $headHeightSm;
            }

            @include media-breakpoint-up(lg) {
                height: $headHeightMd;
            }

            @include media-breakpoint-up(xl) {
                height: $headHeightLg;
            }


            img {
                @include media-breakpoint-up(md) {
                    min-height: 0;
                    max-height: none;
                }
            }
        }

    }
}
