.culture-careers-masthead,
.project-showcase-masthead {
    overflow      : visible;
    padding-bottom: 80px;
    margin-bottom : 100px;
    min-height    : 430px;

    .headline {
        &.hdr-xxl {
            @include display-font-med(58px);
            line-height: clamp(60px, 6vw, 110px);
            font-size  : clamp(50px, 6vw, 100px);
        }
    }

    @include media-breakpoint-up(xl) {
        min-height    : 550px;
        padding-bottom: 100px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 650px;
    }
}


.marquee-infinity-scroller {
    position: absolute;
    width   : 100vw;
    height  : 360px;
    bottom  : -170px;
    left    : 0;
    overflow: hidden;

    .wrapper {
        position : absolute;
        width    : 150%;
        height   : 350px;
        top      : 50%;
        left     : 50%;
        transform: translate(-50%, -50%);
        overflow : hidden;
        opacity  : 0; // Revelead with js

        @include media-breakpoint-up(md) {
            width : 150%;
            height: 360px;
        }

        .marquee-box {
            position   : absolute;
            width      : 173px;
            height     : 173px;
            color      : $white;
            font-size  : 40px;
            font-weight: 600;
            line-height: 50px;
            text-align : center;
            margin     : 0 2rem;

            @include media-breakpoint-up(md) {
                width : 250px;
                height: 250px;
            }

            .photo {
                border-radius: 40px;
            }
        }

        .boxes {
            height   : 183px;
            left     : -25%;
            position : absolute;
            top      : 50%;
            transform: translate(-50%, -50%);

            @include media-breakpoint-up(md) {
                height: 260px;
                left  : -15%;
            }
        }
    }
}
