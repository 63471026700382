// For related content grid
.grid-row.three-up {
    display : flex;
    grid-gap: 20px;

    .article-title {
        @include media-breakpoint-down(md) {
            @include fontSize(20px);
            line-height: 26px;
        }
    }

    @include media-breakpoint-up(md) {
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
        grid-gap: 40px;
    }
}
