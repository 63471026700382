.explore-work-panel.split-photo-panel {
    overflow      : hidden;
    padding-top   : $block-spacer-40;
    padding-bottom: $block-spacer-40;

    @include media-breakpoint-up(md) {
        padding-top   : $block-spacer-80;
        padding-bottom: $block-spacer-60;
    }

    @include media-breakpoint-up(lg) {
        padding-top   : 150px;
        padding-bottom: $block-spacer-120;
    }

    @include media-breakpoint-up(xl) {
        padding-top   : $block-spacer-120;
        padding-bottom: $block-spacer-120;
    }

    .copy-block {
        width         : 100%;
        padding-bottom: 40px;
        max-width     : 100%;
        text-align    : left;
        margin-top    : 0;

        @include media-breakpoint-up(md) {
            padding-top: 90px;
        }

        .header-lg-plus {
            @include display-font-med(32px);
            line-height: clamp(38px, 6.5vw, 78px);
            font-size  : clamp(34px, 5vw, 68px);

            @include media-breakpoint-up(sm) {
                line-height: clamp(39px, 7.5vw, 78px);
                font-size  : clamp(34px, 7vw, 68px);
            }
        }

        @include media-breakpoint-up(md) {
            padding-top   : 0;
            padding-bottom: 0;
            max-width     : 560px;
        }
    }

    .col.expand-visible {
        position  : relative;
        width     : 100%;
        left      : auto;
        overflow  : visible;
        margin-top: 30px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            position  : absolute;
            width     : 50%;
            left      : 50%;
            overflow  : visible;
        }
    }

    .photo-wrapper {
        position: relative;
        display : flex;
        right   : -2rem;
        width   : calc(100% + 2rem);

        &:before {

            @include fingerPrint($light-print:false);

            @include media-breakpoint-down(md) {
                max-width: calc(100% - 2.25rem);
                right    : 4rem;
                height   : calc(100% - 0.5rem);
                top      : auto;
                bottom   : 2rem;
            }

            @include media-breakpoint-up(md) {
                top   : -2rem;
                right : 2rem;
                bottom: auto;
            }

            @include media-breakpoint-up(lg) {
                top  : -60px;
                right: 60px;
            }
        }

        img {
            max-width       : 100%;
            background-color: $raisin;
            height          : calc(100vw * 3/6);

            @include media-breakpoint-up(md) {
                max-width: 44vw;
                height   : 100%;
            }
        }

        @include media-breakpoint-up(md) {
            position  : absolute;
            width     : 40vw;
            min-height: 0;
            right     : 0;
        }

        @include media-breakpoint-up(xxl) {
            min-height: 720px;
            width     : 35vw;
        }
    }
}
