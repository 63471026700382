.dark-content {
    .btn-primary {
        &:hover {
            background-color: $white;
            color           : $black;
        }
    }

    body & p a:link,
    body & p a:visited,
    body & p a:focus,
    body & p a:hover,
    body & p a:active {
        color: $white;
    }
}

.light-content,
.light-inner-content {
    .btn-primary {
        &:hover {
            color           : $white;
            background-color: $black;
        }
    }
}
