.split-masthead {
    padding-top: 96px;
    max-height : 816px;
}

.mobile-pattern {
    // Fingaprint bg
    @include fingerPrint($light-print:false);
    background-position              : 150px -14px;
    height                           : 2rem;
    width                            : 100%;
    max-width                        : calc(100% - 4.5rem);
    right                            : 2rem;
    position                         : absolute;

    @include media-breakpoint-up(sm) {
        max-width: calc(100% - 5.35rem);
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.split-masthead,
.split-photo-panel {



    .content-panel {
        height: 500px;

        @include media-breakpoint-up(xl) {
            height: 720px;
        }
    }

    .copy-block {
        margin-top: -96px;
        width     : 100%;
        max-width : 540px;

        @include media-breakpoint-up(xxl) {
            max-width: 660px;
        }
    }

    .col.expand-visible {
        position: relative;
        width   : 100%;
        left    : auto;
        overflow: visible;

        @include media-breakpoint-up(md) {
            position: absolute;
            width   : 50%;
            left    : 50%;
            overflow: visible;
        }
    }

    .photo-wrapper {
        position       : absolute;
        display        : flex;
        right          : 0;
        width          : 100%;
        justify-content: flex-end;

        @include media-breakpoint-up(sm) {
            width: 50vw;
        }

        @include media-breakpoint-up(lg) {
            width     : 45vw;
            min-height: 720px;
        }

        @include media-breakpoint-up(xxl) {
            width: 39vw;
        }

        img {
            @include bigRadiusCorner(top-left);
            object-fit     : cover;
            object-position: center;
            width          : 100%;
            max-width      : 44vw;
            height         : 100%;
            max-height     : 500px;
            z-index        : 1;

            @include media-breakpoint-up(xl) {
                min-height: 720px;
                max-height: 720px;
            }
        }

        &:before {
            position  : absolute;
            width     : 100%;
            max-width : 44vw;
            height    : 100%;
            max-height: 500px;
            left      : auto;
            top       : 3rem;
            right     : 3rem;
            bottom    : auto;
            content   : '';

            z-index: 0;

            // Fingaprint bg
            @include fingerPrint($light-print:false);

            @include media-breakpoint-up(xl) {
                top       : 60px;
                right     : 60px;
                max-height: 720px;
            }
        }

        &.pattern-offset-top {

            &:before {
                @include media-breakpoint-up(lg) {
                    top: -60px;
                }
            }
        }

        &.tall {
            &:before {
                max-height: 751px;
            }

            img {
                max-height: 751px;
            }

            @include media-breakpoint-up(xxl) {
                &:before {
                    max-height: 850px;
                }

                img {
                    max-height: 850px;
                }
            }
        }
    }



}

.split-photo-panel {

    @include media-breakpoint-up(lg) {
        min-height: 640px;
    }
}
