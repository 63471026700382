.landing-detail {
    #main {
        z-index : 1;
        position: relative;
    }

    .fixed-masthead {
        overflow: hidden;
        position: relative;
        height  : 60vh;

        @include media-breakpoint-up(md) {
            height: 80vh;
        }

        @include media-breakpoint-up(xl) {
            height: 100vh;
        }


        .top-layer {
            position     : relative;
            display      : block;
            margin-bottom: 32vh;
            z-index      : 1;

            @include media-breakpoint-up(md) {
                margin-bottom: 40vh;
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 50vh;
            }
        }

        .container.inner {
            height  : 30vh;
            z-index : 1;
            position: relative;

            @include media-breakpoint-up(md) {
                height: 40vh;
            }

            @include media-breakpoint-up(xl) {
                height: 50vh;
            }


        }

        .copy-block {
            @include media-breakpoint-down(md) {
                padding-top: 30px;
            }
        }

        &+.masthead-background-image {
            position  : fixed;
            left      : 0;
            z-index   : 0;
            width     : 100%;
            height    : 60vh;
            max-height: 100%;

            @include media-breakpoint-up(md) {
                height: 85vh
            }

            @include media-breakpoint-up(lg) {
                height: 100vh;
            }

            .img-parent {
                position: absolute;
                top     : 0;
                left    : 0;
                z-index : 0;
                display : flex;
                height  : 100%;
                width   : 100vw;


            }
        }
    }
}
