.landing-detail {

    .main-container {
        background-color: $white;
    }

    // Custom body class
    &.mke-boot-landing {
        color: $white;

        .main-container {
            background-color: $black;
        }

        .light-content {
            background-color: $black;

            p {
                color: $white;
            }

            p a:link,
            p a:visited,
            p a:focus,
            p a:hover,
            p a:active {
                color: $white;
            }

            .js-btn-split.activated,
            .link.activated,
            .format-link.activated {

                --text: #fff;

            }

            &>* {
                color: $white;
            }
        }

        .landing-detail-gallery-panel {
            background-color: $black;
        }
    }

    .block {
        padding-top   : 0;
        padding-bottom: 0;

        &.block-with-background {}
    }
}
