[loading="lazy"],
.lazy {
	opacity   : 0;
	visibility: hidden;
	transition: .3s opacity linear;

	&[data-ll-status='loaded'],
	&[data-ll-status='native'] {
		opacity   : 1;
		visibility: visible;
	}
}

@keyframes infiniteLoadAnim {
	0% {
		opacity  : 0;
		transform: translateY(50px);
	}

	100% {
		opacity  : 1;
		transform: translateY(0px);
	}
}

.infinite-load {
	// animation: infiniteLoadAnim 1s forwards;
}
