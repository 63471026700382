.contact-us-panel {
    padding-top   : 50px;
    padding-bottom: 50px;
    margin-bottom : -50px;
    z-index       : 2;

    @include media-breakpoint-up(md) {
        padding-top   : 90px;
        padding-bottom: 90px;
        margin-bottom : -20px;
        z-index       : 2;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: -30px;
    }

    .copy {
        width: clamp(60%, 80vw, 1120px);

        @include media-breakpoint-up(md) {
            width: 90vw;
        }

        @include media-breakpoint-up(lg) {
            width: 75vw;
        }


        @include media-breakpoint-up(xl) {
            width: clamp(60%, 70vw, 1120px);
        }


        padding-right: 3.5rem;
    }

    .flyout-panel-shape {
        border-top-right-radius   : 40px;
        border-bottom-right-radius: 40px;
        z-index                   : 0;
        position                  : absolute;
        top                       : 0;
        height                    : 100%;
        left                      : 0;
        background-color          : $white;
        width                     : clamp(60%, 85vw, 1120px);

        @include media-breakpoint-up(md) {
            width: 90vw;
        }

        @include media-breakpoint-up(lg) {
            width: 75vw;
        }

        @include media-breakpoint-up(xl) {
            width: clamp(60%, 70vw, 1120px);
        }

    }
}
