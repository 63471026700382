@use "sass:math";

.js-split-headline {
    .words {
        overflow: hidden;
        line-height: 1.09;
    }
}

.js-turbo-period {

    .js-turbo-char {
        position: absolute;
    }

    &.turbo-relative {
        .js-turbo-char {
            position: relative;
        }
    }
}

.js-line-animation {
    opacity: 0;

    &.activated {
        opacity: 1;
    }
}

.js-split-paragraph {
    opacity: 0;


    &.activated {
        opacity: 1;
    }
}

.js-home-text-fade,
.js-text-fade {
    --mask: linear-gradient(-45deg, transparent 100%, black 150%);
    -webkit-mask-image: var(--mask);
    mask-image: var(--mask);
}

.js-text-fade-2 {
    --mask: linear-gradient(0deg, transparent 100%, black 150%);
    -webkit-mask-image: var(--mask);
    mask-image: var(--mask);
}

.js-home-letter-animation,
.js-word-animation,
.js-letter-animation {
    opacity: 0;

    &.activated {
        opacity: 1;
    }
}

.mobile {

    .js-home-letter-animation,
    .js-word-animation,
    .js-letter-animation,
    .js-line-animation {
        opacity: 1;
    }
}


// Inspired by https://codepen.io/aaroniker/pen/bGGVMbY?editors=0100
.js-btn-split.activated,
.link.activated,
.format-link.activated {
    --text: #000;
    --shadow : 0 2px 8px -1px #{rgba(#000, .04)};
    --shadow-hover: 0 4px 20px -2px #{rgba(#000, .12)};
    --font-size: 1rem;
    --duration: .44s;
    --move-hover: -4px;
    --font-shadow: var(--font-size);
    box-shadow: var(--shadow);
    line-height: var(--font-size);
    transform: translateY(var(--y)) translateZ(0);
    transition: all var(--duration) linear;

    .light-inner-content & {
        --text: #fff;
    }

    .words-bro {
        display: flex;
        overflow: hidden;
        text-shadow: 0 var(--font-shadow) 0 var(--text);
        margin-right: .21rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .chars {
        display: block;
        backface-visibility: hidden;
        font-style: normal;
        transition: transform var(--duration) ease;
        transform: translateY(var(--m)) translateZ(0);
        $i : 1;

        @while $i < 12 {
            &:nth-child(#{$i}) {
                transition-delay: math.div($i, 20) + s;
            }

            $i: $i+1;
        }
    }

    // Text rollover effect

    @media(hover: hover) {


        &:hover {
            .chars {
                --m: calc(var(--font-size) * -1);
            }

            .dark-content & {
                // --text: #fff;

                &.btn-submit {
                    --text: #000;
                    background-color: $white;
                }
            }


        }
    }



    .light-content & {
        --text: #fff;
        --shadow : 0 2px 8px -1px #{rgba(#fff, .04)};
        --shadow-hover: 0 4px 20px -2px #{rgba(#fff, .12)};
    }


}


.js-animated-scroll-paragraph {
    display: flex;
    flex-direction: column;
}

.js-animated-scroll-paragraph div {
    will-change: transform;
    overflow: hidden;
    vertical-align: top;
}
