// .news-index .masthead,
.news-detail .masthead {

    @include media-breakpoint-up(lg) {
        min-height: clamp(400px, 45vw, 450px);
    }

    height : auto;
}

.news-index .masthead {
    height: auto;
    padding: 90px 0 60px;

    @include media-breakpoint-up(md) {
        // padding: 150px 0 120px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 380px;
    }

    p {
        text-wrap: balance;
    }
}

.news-detail .masthead {
    padding-top: clamp(80px, 16vw, 160px);
    padding-bottom: 100px;

    .headline {
        margin-bottom: 1.25rem;
        text-wrap: balance;

        @include media-breakpoint-up(lg) {
            margin-bottom: 26px;
        }
    }

    .by-line {
        margin-bottom: 1.25rem;
        @include link-font(8px);
        line-height: 18px;
        text-transform: uppercase;
        @include tracking(200);

        @include media-breakpoint-up(md) {
            @include link-font(9px);
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 35px;
        }
    }

    .yellow-pipe {
        color: $turbo;
        display: inline-block;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .intro-copy {
        margin-bottom: 0;
    }

    &.with-hero {
        @include mastheadPadding();
    }
}
