.about-page {

    .marquee-horizontal-scroll-panel {
        position: relative;

        &:after {
            content         : '';
            height          : 50%;
            position        : absolute;
            width           : 200%;
            background-color: $whitesand;
            bottom          : 0;
            left            : -5rem;
            z-index         : -1;
        }
    }
}
