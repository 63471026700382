.block.faq-block {
    position: relative;

    @include media-breakpoint-up(md) {
        padding-top: 70px;
    }

    @include media-breakpoint-up(xl) {
        overflow: visible;
    }

    .faq-container {
        position: relative;
        z-index: 1;
        max-width: 800px;
    }

    .faq-box {
        padding: 3rem;
        text-align: center;

        .headline {
            margin-bottom: 38px;
        }

        @include media-breakpoint-up(sm) {
            padding: 5rem;
        }

        @include media-breakpoint-up(md) {
            padding: 96px;
        }

        &.background-purple {
            background-color: $purple;
            * {
                color: $white;
            }
        }

        &.background-dark-blue {
            background-color: $turbo;
            * {
                color: $white;
            }

        }
    }

    .faq-item {
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .decorative-ribbon {
        top: 0;

        @include media-breakpoint-up(sm) {
            height: 100%;
            background-position: center top;
            background-size: 100% auto;
        }
    }
}
