.brand-philosophy-panel.split-photo-panel {
    height          : auto;
    background-color: $woodsmoke;
    z-index         : 1;
    padding-top     : 40px;
    padding-bottom  : 40px;
    position        : relative;

    @include media-breakpoint-up(md) {
        min-height    : 710px;
        padding-top   : 100px;
        padding-bottom: 50px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 800px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 120px;
    }

    .col.content-panel {
        @include media-breakpoint-down(md) {
            height: auto;
        }
    }

    .col .copy {

        @include media-breakpoint-up(sm) {
            max-width: 350px;
        }

        @include media-breakpoint-up(md) {
            max-width: 350px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 540px;
        }
    }

    .col .copy-block {
        margin-top: 0;

        @include media-breakpoint-up(md) {
            margin-top: 80px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 160px;
        }
    }

    .col.expand-visible {
        position       : relative;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        width          : 100%;
        left           : 0;

        @include media-breakpoint-up(md) {
            position: absolute;
            width   : 50%;
            left    : 50%;
            overflow: visible;
            display : block;
        }
    }

    .col-container {

        .photo-wrapper {
            position  : relative;
            margin-top: 40px;
            display   : flex;
            right     : -2rem;
            width     : calc(100% + 2rem);

            .photo-parent {
                right   : 0;
                overflow: hidden;
            }

            @include media-breakpoint-up(md) {
                right     : 0;
                width     : 100%;
                position  : absolute;
                margin-top: 0;
            }

            &:before {
                top  : 2rem;
                right: 2rem;

                max-width: 100%;

                @include media-breakpoint-up(md) {
                    margin-right: 0;
                    max-height  : none;
                    width       : calc(100% - 3rem);
                    max-width   : none;
                    height      : 100%;
                    top         : 3rem;
                    right       : 3rem;

                }

                @include media-breakpoint-up(lg) {
                    width: calc(100% - 6rem);
                }

                @include media-breakpoint-up(xl) {
                    width: calc(100% - 7rem);
                }
            }
        }

        img.brand-img {
            max-width             : 100%;
            background-color      : $black;
            border-top-left-radius: 0;
            position              : relative;
            object-position       : top;


            @include bigRadiusCorner(bottom-left);

            @include media-breakpoint-up(md) {
                max-width : 45vw;
                right     : -3rem;
                max-height: 620px;

            }

            @include media-breakpoint-up(lg) {
                max-height: 650px;
                position  : absolute;
            }

            @include media-breakpoint-up(xl) {
                max-height: 680px;
            }

            @include media-breakpoint-up(xxl) {
                max-height: 740px;
            }
        }
    }

    .brand-spinner {
        position        : absolute;
        top             : 90px;
        left            : -11px;
        width           : 7rem;
        height          : 7rem;
        z-index         : 2;
        transform-origin: center;
        display         : flex;

        img {
            border-radius      : 0;
            min-height         : 0;
            backface-visibility: hidden;
            width              : 100%;
            height             : 100%;
        }

        .rotation-wrapper {
            transform-origin: center;
        }

        @include media-breakpoint-up(sm) {
            top: 83px;
        }

        @include media-breakpoint-up(md) {
            top   : 100px;
            width : 8rem;
            height: 8rem;
        }

        @include media-breakpoint-up(lg) {
            width : 15vw;
            height: 15vw;
        }

        @include media-breakpoint-up(xxl) {
            top: 100px;
        }
    }

    #brand-headline {
        margin-bottom: 10px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 14px;
            color        : red;
        }
    }

    .cta-link {
        margin-top: 10px;

        @include media-breakpoint-up(lg) {
            margin-top: 25px;
        }
    }


}
