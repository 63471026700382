.services-bmc-featured-labels-container {
    height: 300vh;
    width: 50vw;
    top: 0;
    left: 3rem;
    position: absolute;
    z-index: 0;
}


.services-circle-wrapper .services-circle-item {
    background-color: $turbo;
    @include magicRadialGradient();
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    clip-path: circle();

    &.black {
        background-color: $black;
        background-image: none;

        .service-name {
            color: $white;
        }
    }


    .services-circle-wrapper & {
        position: absolute;
    }

    .circle-shape {
        transition: all .9s ease-out;

    }

    .service-name {
        position: relative;
        z-index: 1;

        & * {
            margin-bottom: 0;
        }
    }
}

.services-circle-wrapper {
    position: absolute;
    width: 43%;
    top: 0;
    left: 0;
    height: 400px;
}

.services-circle-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
}

.circle-mask {
    top: 50%;
    transform: translateY(-50%);
    background-color: $turbo;

    @include media-breakpoint-up(sm) {
        left: 0;
        right: auto;
        position: absolute;
        height: 36vw;
        width: 36vw;
        padding: 10vw;
        clip-path: circle();
        overflow: hidden;
    }

    @include media-breakpoint-up(lg) {
        height: 349px;
        width: 349px;
        padding: 70px;
    }

    @include media-breakpoint-up(xl) {
        height: 399px;
        width: 399px;
        padding: 100px;
    }
}
