// From https: //codepen.io/GreenSock/pen/GRQEYwZ
.back-to-top-wrapper {
    position: absolute;
    right   : 2.25rem;
    bottom  : 0;
    width   : 50px;
}

.progress-wrap {
    position     : fixed;
    height       : 46px;
    width        : 46px;
    display      : flex;
    align-items  : center;
    overflow     : hidden;
    border-radius: 50px;
    z-index      : 100;
    opacity      : 0;
    visibility   : hidden;
    transform    : translateY(15px);
    bottom       : 70px;

    @include media-breakpoint-up(sm) {
        bottom: 120px;
    }

    @include media-breakpoint-up(lg) {
        bottom: 150px;
    }
}

.progress-wrap.active-progress {
    opacity   : 1;
    visibility: visible;
}

.progress-wrap:after,
.progress-wrap:before {
    background-repeat  : no-repeat;
    background-size    : 20px 20px;
    background-position: center;
    transition         : all .2s ease-in;
}

.progress-wrap::after {
    position        : absolute;
    background-image: url('/static/img/svg/back-to-top-arrow.svg');
    background-color: rgba($core_grey, 0.3);
    content         : '';
    left            : 0;
    top             : 0;
    height          : 46px;
    width           : 46px;
    display         : block;
    z-index         : 1;
}

.desktop .progress-wrap:hover::after {
    opacity: .5;
}

.desktop .progress-wrap:hover::before {
    background-color: $whitesand;
}

.progress-wrap::before {
    position        : absolute;
    background-image: url('/static/img/svg/back-to-top-arrow-hover.svg');
    content         : '';
    opacity         : 0;
    left            : 0;
    top             : 0;
    height          : 100%;
    width           : 100%;
    overflow        : hidden;
    border-radius   : 50%;
    display         : block;
    z-index         : 2;
}

.desktop .progress-wrap:hover::before {
    opacity: 1;
}

.progress-wrap svg.progress-circle path {
    stroke      : rgba($turbo, 1);
    stroke-width: 4;
    box-sizing  : border-box;
}
