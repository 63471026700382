.news-detail,
.about-page {
    .block.quoteblock {
        .turbo-line {
            height: 2px;
            width : 100%;
            @include magicBackgroundGradient();
        }

        .cc-quote-block .blockquote-container {
            padding: 30px 0;

            @include media-breakpoint-up(lg) {
                padding: 60px 0 50px;
            }

            cite {
                @include cite();
            }
        }
    }
}
