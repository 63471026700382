.paragraph-block {
    &.block-content-align-center {

        @include media-breakpoint-up(lg) {
            .container.inner {
                max-width: 1022px;
            }
        }
    }
}
