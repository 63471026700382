// For copy alignment inside of blocks

.block-content-align {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }
}
