.block.two-col-paragraph {

    .grid {

        @include media-breakpoint-up(sm) {
            display: grid;
            grid-template-columns: minmax(20px, 528px) minmax(20px, 528px);
            grid-column-gap: 60px;
        }

    }

    .grid-column {

        &:last-child {
            padding-top: 2rem;

            @include media-breakpoint-up(sm) {
                padding-top: 0;
            }
        }

    }

    &.with-background {
        &:not(.background-grey) {
            * {
                color: $white;
            }

            a.link {
                color: $white;

                &:hover {
                    border-color: $white;
                }
            }
        }
    }
}
