.services-intro-row {
    padding-top: 60px;

    @include media-breakpoint-up(md) {
        padding-top: 80px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 130px;
    }
}
