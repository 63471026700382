.landing-detail,
.custom-landing-detail {
    .animated-stats-block {

        .stats-grid-container {
            display              : grid;
            grid-template-columns: 50% 50%;
            grid-gap             : 1rem;

            @include media-breakpoint-up(md) {
                grid-gap             : 40px;
                grid-template-columns: repeat(auto-fill, minmax(45.4%, 1fr));
            }
        }

        .stats-item {

            .stat-hdr {
                display: flex;
            }

            .anim-number {
                font-style: normal;
            }
        }
    }
}
