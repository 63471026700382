.work-detail-gallery-panel {
    margin-bottom: $block-spacer-40 !important;

    &+.keep-exploring-panel {
        margin-top: 80px;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: $block-spacer-60 !important;
    }

    &.no-margin {
        margin-bottom: 0 !important;
    }

    .gallery-container .block-type-one_column_full_bleed {
        // @include media-breakpoint-down(xl) {
        //     padding-left : .75rem !important;
        //     padding-right: .75rem !important;
        // }
    }

    .gallery-container .block-type-two_column_full_bleed {
        @include media-breakpoint-down(xl) {
            padding-left : 0 !important;
            padding-right: 0 !important;
        }
    }
}

.one-column-row.fullbleed {
    @include media-breakpoint-down(md) {
        margin-left : 0;
        margin-right: 0;
    }
}

.one-column-row.fullbleed .one-column.copy-column,
.gallery-wrap {
    margin-left : auto;
    margin-right: auto;
    max-width   : 1200px;
    grid-gap    : 8px;

    @include media-breakpoint-up(md) {
        grid-gap: 1rem;
    }

    @include media-breakpoint-up(xl) {
        grid-gap: 30px;
    }

    @include media-breakpoint-up(xxl) {
        max-width: 1382px;
    }
}

.gallery-container {

    .row {
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 30px;
        }
    }

    &:last-child {
        .row {
            margin-bottom: 0;
        }
    }

    .js-turbo-line-wrap {

        .turbo-line-stretch {
            transform: scaleX(0);
            height   : 2px;
            width    : 100%;
            @include magicBackgroundGradient();
        }
    }

    .cc-quote-block {
        text-align: center;

        @include media-breakpoint-down(sm) {
            padding-left : 0;
            padding-right: 0;

            .container.inner {
                padding-left : .75rem;
                padding-right: .75rem;
            }
        }

        .blockquote-container {
            padding: 1rem 0rem;

            @include media-breakpoint-up(sm) {
                padding: 30px 30px;
            }

            @include media-breakpoint-up(md) {
                padding: 60px 30px 56px;
            }
        }

        cite {
            @include cite();
        }
    }

    .video-embed-container {
        display        : flex;
        justify-content: center;
        align-items    : center;
        height         : 100%;
        width          : 100%;

        &.dark-blue-bg {
            background-color: $burger_blue;
        }
    }

    .video-embed-wrapper {
        width   : 100%;
        height  : 75%;
        position: absolute;
    }

    .one-column-row.fullbleed {
        .col {
            padding-left : 0;
            padding-right: 0;
        }
    }

    .block-padding-bottom-sm {
        @include media-breakpoint-up(md) {
            padding-bottom: 90px;
        }
    }
}
