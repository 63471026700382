// Menu animation inspired by https://codepen.io/designcouch/pen/ExvwPY

#nav-icon {
    width     : 24px;
    height    : 16px;
    transform : rotate(0deg);
    margin-top: 18px;
    position  : fixed;
    left      : calc(100vw - 45px);
    right     : auto;
    background: none;
    color     : inherit;
    border    : none;
    padding   : 0;
    font      : inherit;
    outline   : inherit;

    @include media-breakpoint-up(md) {
        left      : calc(100vw - 70px);
        margin-top: 40px;
    }

    &:hover {
        span:nth-child(3) {
            width: 24px;
        }
    }
}

.over-masthead,
.nav-expanded {
    #nav-icon:after {
        background: transparent;
    }
}

#nav-icon span {
    display      : block;
    position     : absolute;
    height       : 2px;
    width        : 100%;
    background   : $white;
    border-radius: 2px;
    opacity      : 1;
    left         : 0;
    transform    : rotate(0deg);
    transition   : .55s ease-in-out;
}

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2) {
    top: 8px;
}

#nav-icon span:nth-child(3) {
    top  : 16px;
    width: 15px;
    right: 0;
    left : auto;
}

#nav-icon.open span {
    background: $turbo !important;
    transition: .35s linear;
}

#nav-icon.open span:nth-child(1) {
    top      : 8px;
    transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
    opacity: 0;
    left   : -60px;
}

#nav-icon.open span:nth-child(3) {
    top      : 8px;
    width    : 100%;
    transform: rotate(-135deg);
}

.desktop {
    #nav-icon.open {
        &:hover {
            span {
                &:nth-child(1) {

                    transform: rotate(0deg);
                }

                &:nth-child(3) {
                    transform: rotate(-0deg);
                }

            }
        }
    }
}
