.container.inner.maxwidth,
.maxwidth {


    &-1440 {
        max-width: calc(100% - 3rem);

        @include media-breakpoint-up(xl) {
            max-width: 1440px;
        }
    }

    &-1350 {
        max-width: calc(100% - 3rem);

        @include media-breakpoint-up(xxl) {
            max-width: 1350px;
        }
    }

    &-980 {
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            max-width: calc(100% - 3rem);
        }

        @include media-breakpoint-up(lg) {
            max-width: 980px;
        }
    }

    &-960 {
        max-width: calc(100% - 3rem);

        @include media-breakpoint-up(lg) {
            max-width: 960px;
        }
    }

    &-790 {
        max-width: calc(100% - 3rem);

        @include media-breakpoint-up(lg) {
            max-width: 790px;
        }
    }

    &-760 {
        max-width: calc(100% - 3rem);

        @include media-breakpoint-up(lg) {
            max-width: 760px;
        }
    }
}

.copy-maxwidth-480 {
    @include media-breakpoint-up(lg) {
        max-width: 90%;
    }

    @include media-breakpoint-up(xl) {
        max-width: 480px;
    }
}

.copy-maxwidth-460 {
    @include media-breakpoint-up(lg) {
        max-width: 90%;
    }

    @include media-breakpoint-up(xl) {
        max-width: 460px;
    }
}

.copy-maxwidth-448 {
    @include media-breakpoint-up(lg) {
        max-width: 90%;
    }

    @include media-breakpoint-up(xl) {
        max-width: 448px;
    }
}
