.intro-panel-block {
    text-align : center;
    padding-top: 40px;

    @include media-breakpoint-up(md) {
        padding-top: 90px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 180px;
    }

    .intro-copy {
        @include media-breakpoint-up(md) {
            padding-bottom: 120px;
        }
    }

    .headline {
        text-wrap: balance;
        @include display-font-semi-bold(42px);
        margin-bottom: 1.5rem;
        font-size    : clamp(21px, 4vw, 42px);
    }

}
