.link,
.format-link {
    display: inline-block;
    @include link-font(8px);
    line-height: 11px;
    @include tracking(200);
    font-weight    : bold;
    position       : relative;
    text-decoration: none;
    text-transform : uppercase;
    padding        : .35rem 0 0;
    text-decoration: none;

    &.activated {
        @include link-font(8px);
        line-height: 8px;

        @include media-breakpoint-up(md) {
            @include link-font(9px);
            line-height: 9px;
        }
    }

    &.module-link {
        color: $black;
    }

    @include media-breakpoint-up(md) {
        @include link-font(9px);
        line-height: 12px;
    }

    &:after {
        transform-origin: left center;
        width           : 100%;
        content         : '';
        height          : 1px;
        position        : absolute;
        bottom          : -1px;
        left            : 0;
        @include magicHoverLine();

        @include media-breakpoint-up(md) {
            bottom: -2px;
        }
    }

    &.activated {

        .light-content & {
            --text        : #000;
            --shadow      : 0 2px 8px -1px #{rgba(#000, .04)};
            --shadow-hover: 0 4px 20px -2px #{rgba(#000, .12)};
        }
    }
}

.download-form-container a.download {
    color: $core_blue_oklch;

    &:link,
    &:visited,
    &:hover,
    &:active {
        color: $core_blue_oklch;
    }
}



.form-intro-message a[href^="/documents"],
.form-intro-message a[href$=".pdf"],
.form-intro-message a[href$=".docx"],
.form-intro-message a[href$=".xls"] {
    color      : $core_blue_oklch;
    position   : relative;
    display    : flex;
    align-items: flex-start;
    grid-gap   : 0.5rem;

    &:after {
        content            : '';
        height             : 2rem;
        width              : 2rem;
        background-image   : url(/static/img/icons/download.svg);
        background-size    : contain;
        background-position: center right;
        background-repeat  : no-repeat;
        transition         : transform .2s ease-in;
    }

    &:link,
    &:visited,
    &:hover,
    &:active {
        color: $core_blue_oklch;
    }

    &:hover {
        &:after {
            transform: translateY(-1px);
        }
    }
}

.tel-link,
.email-link {
    color: $link-color;
}

.privacy-policy-link {
    @include fontSize(12px);

    &:hover {
        font-weight: 700;
    }
}
