.services-masthead {
    overflow      : visible;
    padding-bottom: 80px;
    margin-bottom : 100px;
    min-height    : 430px;

    .headline {
        &.hdr-xxl {
            @include display-font-med(58px);
            line-height: clamp(60px, 6vw, 110px);
            font-size  : clamp(50px, 6vw, 100px);
        }
    }

    @include media-breakpoint-up(xl) {
        min-height    : 550px;
        padding-bottom: 100px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 650px;
    }

    .copy-block {
        width         : 100%;
        padding-top   : 90px;
        padding-bottom: 40px;
        max-width     : 100%;
        text-align    : center;

        @include media-breakpoint-up(md) {
            text-align    : left;
            padding-top   : 0;
            padding-bottom: 0;
            max-width     : 660px;
        }
    }

    .col.expand-visible {
        position: relative;
        width   : 100%;
        left    : auto;
        overflow: visible;

        @include media-breakpoint-up(md) {
            position: absolute;
            width   : 50%;
            left    : 50%;
            overflow: visible;
        }
    }

    &.split-masthead .photo-wrapper {
        position: relative;
        display : flex;
        right   : -2rem;
        width   : calc(100% + 2rem);

        &:before {
            @include media-breakpoint-down(md) {
                max-width      : calc(100% - .25rem);
                background-size: auto;
                right          : 2rem;
                height         : calc(100% - 1rem);
            }
        }

        img {
            max-width       : 100%;
            background-color: $raisin;

            @include media-breakpoint-up(md) {
                max-width: 44vw;
            }
        }

        @include media-breakpoint-up(md) {
            position  : absolute;
            width     : 40vw;
            min-height: 720px;
            height    : 100vh;
            right     : 0;
        }

        @include media-breakpoint-up(xxl) {
            width: 35vw;
        }
    }


}
