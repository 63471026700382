.pagination-station {
    width: 100%;
    display: block;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    .left-btn {
        transform: scaleX(-1);
    }

    .right-btn,
    .left-btn {
        display: block;
        margin: 8px;
        background-position: center;
        background-size: 5px 8px;
        background-repeat: no-repeat;
        width: 8px;
        height: 16px;
        // background-image: url('/static/img/icon/icon-angle-right.svg');
        position: relative;
        line-height: 0;
        font-size: 0;
        color: transparent;

        &:hover {
            &:after {
                position: absolute;
                content: '';
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                height: 1px;
                background-color: $turbo;
            }
        }
    }

    ul {
        list-style: none;

        li {
            float: left;
            padding: 10px;

            &:before {
                display: none;
                content: none;
            }

            a {
                @include fontSize(12px);
                font-weight: 100;
                color: $turbo;
                text-decoration: none;

                &:hover {
                    font-weight: 600;
                    text-decoration: underline;
                }

                &.current {
                    // font-family: $body-font-bold;
                    position: relative;

                    &:after {
                        content: '';
                        height: 2px;
                        background-color: $black;
                        width: 100%;
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                    }
                }
            }

        }
    }


}
