.home-three-icon-panel {
    color: white;

    .card-grid {
        .card {
            background-color: $raisin;
    
            em {
                color: $core_blue;
                font-weight: 600;
                font-style: normal;
            }
        }
    }
    
}