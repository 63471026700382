.overflow-visible {
    overflow: visible !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

#non-smooth-scroll {
    overflow: hidden !important;
}
