.purpose-panel-block {
    position      : relative;
    height        : auto;
    padding-bottom: 0;
    overflow      : hidden;

    &:after {
        grid-column     : 1;
        grid-row        : 1;
        content         : '';
        width           : 100%;
        background-color: $whitesand;
        height          : 200px;
        grid-column     : 1;
        position        : absolute;
        width           : 100vw;
        left            : 0rem;
        bottom          : -78px;
        z-index         : 0;

        @include media-breakpoint-up(md) {
            bottom: -70px;
            height: 170px;
            @include bigRadiusCorner(top-right);
        }

        @media (min-width: 820px) {
            bottom: -46px;
        }

        @include media-breakpoint-up(lg) {
            left   : 0;
            display: block;
            height : 20vw;
            bottom : -6vw;
            @include bigRadiusCorner(top-right);
        }

        @include media-breakpoint-up(xl) {
            bottom: -72px;
            height: 250px;
        }
    }

    .row.purpose-text {
        @include media-breakpoint-down(md) {
            padding-top   : 60px;
            padding-bottom: 0;
        }

        padding-top   : 120px;
        padding-bottom: 80px;

        .headline {
            margin-bottom: 15px;
        }
    }

    .expand-visible {
        position: relative;

        @include media-breakpoint-up(md) {
            height: 470px;
        }

        @include media-breakpoint-up(xl) {}

        .photo-wrapper {
            grid-row   : 1;
            grid-column: 1;
        }

    }

    .split-panel {
        padding-bottom       : 50px;
        grid-row             : 1;
        grid-column          : 1;
        position             : relative;
        z-index              : 1;
        display              : grid;
        grid-template-columns: 50% 50%;

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
            display       : flex;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: clamp(50px, 5vw, 90px);
        }

        .copy-side {
            grid-column: 2;
            grid-row   : 1;
        }
    }

    .photo-row {
        grid-row   : 1;
        grid-column: 1;
    }

    .photo-wrapper {
        top       : 0;
        margin-top: 36px;
        left      : -7.5vw;
        position  : relative;
        overflow  : hidden;
        z-index   : 1;
        max-width : 100%;
        height    : 100%;

        @include media-breakpoint-up(md) {
            left      : -5vw;
            margin-top: 0;
            width     : calc(50vw + 5vw);

        }

        @include media-breakpoint-up(xl) {
            width: 50vw;
            left : 0;
        }

        @include media-breakpoint-up(xxl) {
            margin-top: 0;
        }

        img {
            width           : 100%;
            height          : 100%;
            object-fit      : cover;
            transform-origin: right top;
            @include bigRadiusCorner(top-right);
        }
    }

    #box {
        will-change: transform;
    }

    .copy-box {
        width        : 100%;
        padding-left : 1.75rem;
        padding-right: 2rem;

        @include media-breakpoint-up(sm) {
            padding-left: 2.75rem;
        }

        @include media-breakpoint-up(md) {
            padding-left : 1rem;
            max-width    : 45vw;
            padding-right: 0;
        }

        @include media-breakpoint-up(xl) {
            margin-left : 4rem;
            padding-left: 0;
            max-width   : 480px;
        }
    }
}

.test-section {
    height  : 1000px;
    position: relative;

    .test-panel {
        display              : grid;
        grid-template-columns: 1fr 1fr;
        grid-gap             : 110px;

        .image {
            grid-column: 1 / span 1;
        }

        .copy {
            grid-column: 2 / span 1;
        }
    }
}
