// Basic colors
$white: #FFFFFF;
$black: #000000;

// Brand colors (change to customize)
$turbo     : #FBEF00;
$raisin    : #1E2126;
$woodsmoke : #12151A;
$whitesand : #F6F5F5;
$grey_d6   : #D6D6D6;
$pink      : #FF00FF;


// Core plus colors
$core_grey   :#5e5f61;
$core_green  :#00b159;
$core_blue   :#00bbe3;
$core_yellow :#f2e900;


// Text shades
$cat_grey                  : #7E7F81;
$filter_btn_border_color   : #DEDEDF;
$field_border_color        : #5E5F61;
$contact_email_field_border: #707070;

// Shadow color
$drop_shadow: #5E5F61;

// OKLCH
$turbo_oklch     : oklch(93.16% 0.19862286931998074 105.96262687419528);
$core_blue_oklch : oklch(73.3% 0.13393799731566086 220.50241706947318);
$core_green_oklch: oklch(66.57% 0.176 151.69);
$white_oklch     :oklch(100% 0 0);

// custom gradient colors
$new_hope_oklch_alpha_0  : oklch(92.86% 0.057 121.49/0%);
$new_hope_oklch_alpha_25 : oklch(92.86% 0.057 121.49/38%);
$duck_egg_oklch          : oklch(93.52% 0.047 196.37);

// Background gradient
$vanilla-sky-bg-gradient: linear-gradient(135deg, $new_hope_oklch_alpha_0 0%, $new_hope_oklch_alpha_25 60%, $duck_egg_oklch 100%);

// Background gradient
$vanilla-sky-bg-gradient-reversed: linear-gradient(35deg, $duck_egg_oklch 0%, $new_hope_oklch_alpha_25 60%, $new_hope_oklch_alpha_0 100%);


// Shadows
$linearblackgradient  : linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
$linearblackgradient2 : linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
$linearblackgradient3 : linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
$linearwhitegradient  : linear-gradient(to bottom, rgba(255, 255, 255, 1) 80%, rgba(0, 0, 0, 0) 100%);
$linearwhitegradient2 : linear-gradient(to bottom, $white_oklch 50%, rgba(0, 0, 0, 0) 100%);
$card_shadow          : 6px 6px 30px rgba($drop_shadow, .10);

// Custom
$burger_blue : #091421;
$input_border: #C6C7C8;
$input_placeholder: #8D8F91;
