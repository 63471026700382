.work-masthead {
    height : auto;
    padding: 4rem 2rem 4rem;

    @include media-breakpoint-up(lg) {
        padding: 6rem 6rem;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 497px;
    }

    .headline {

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
        }
    }
}

.work {
    .work-scroller-container {
        height: 240px;

        @include media-breakpoint-up(md) {
            height: 350px;
        }

    }
}
