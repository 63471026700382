.modal {

    .btn-close {
        background-color   : transparent;
        border             : 0;
        height             : 20px;
        width              : 20px;
        background-size    : contain;
        background-position: center;
        background-repeat  : no-repeat;
        opacity            : 1;
        @include transition(.23s all ease);

        span {
            visibility: hidden;
        }
    }

    .modal-dialog {
        max-width : none;
        width     : calc(100vw - 1rem);
        margin-top: 0;

        @include media-breakpoint-up(lg) {
            width     : 900px;
            margin-top: 2.5rem;
        }

        @include media-breakpoint-up(xl) {
            width: 1000px;
        }

        @include media-breakpoint-up(xxl) {
            width: 1200px;
        }

        @include media-breakpoint-up(xxxl) {
            width: 1280px;
        }
    }

    .modal-content {
        border-radius   : 0;
        background-color: transparent;
        color           : $white;
        border          : 0;
    }

    .modal-header {
        border: 0;
    }
}

.modal-backdrop.show {
    opacity: 0.84;
}
