.landing-detail {

    .one-column-row.fullbleed .one-column.copy-column,
    .gallery-wrap {
        margin-left : auto;
        margin-right: auto;
        max-width   : 1200px;
        grid-gap    : 8px;

        @include media-breakpoint-up(md) {
            grid-gap: 1rem;
        }

        @include media-breakpoint-up(xl) {
            grid-gap: 30px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 1382px;
        }
    }

    .gallery-container {

        .row {
            margin-bottom: 8px;

            @include media-breakpoint-up(md) {
                margin-bottom: 1rem;
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 30px;
            }
        }

        &:last-child {
            .row {
                margin-bottom: 0;
            }
        }

        .js-turbo-line-wrap {

            .turbo-line-stretch {
                transform: scaleX(0);
                height   : 2px;
                width    : 100%;
                @include magicBackgroundGradient();
            }
        }

        .cc-quote-block {
            text-align: center;

            .container.inner {
                @include media-breakpoint-down(sm) {
                    max-width: calc(100% - 3rem);
                }
            }

            .blockquote-container {
                padding: 1rem 0rem;

                @include media-breakpoint-up(sm) {
                    padding: 30px 30px;
                }

                @include media-breakpoint-up(md) {
                    padding: 60px 30px 56px;
                }
            }

            cite {
                @include cite();
            }
        }

        .video-embed-container {
            display        : flex;
            justify-content: center;
            align-items    : center;
            height         : 100%;
            width          : 100%;

            &.dark-blue-bg {
                background-color: $burger_blue;
            }
        }

        .video-embed-wrapper {
            width   : 100%;
            height  : 75%;
            position: absolute;
        }

        .one-column-row.fullbleed {
            .col {
                padding-left : 0;
                padding-right: 0;
            }
        }

        .block-padding-bottom-sm {
            @include media-breakpoint-up(md) {
                padding-bottom: 90px;
            }
        }
    }
}
