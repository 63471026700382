.insights-detail {
    .fixed-masthead {
        height: fit-content;

        .top-layer {
            top: 0;
        }

    }


    #main {
        z-index : 1;
        position: relative;

        .container-fluid.gallery-container.block-type-one_column_full_bleed {
            padding-left : 0;
            padding-right: 0;
        }
    }
}


.client-showcase-masthead {
    .btn.btn-primary.pill.black-pill {
        margin-bottom: 1.5rem;
    }
}

.success-stories-detail .masthead {
    &.masthead-parallax {

        .container.inner {
            height: auto;

            .copy-block {
                p {
                    @include fontSize(20px);
                    line-height: 26px;
                }

                @include media-breakpoint-down(md) {
                    p {
                        @include fontSize(18px);
                        line-height: 24px;
                    }

                    padding-top   : 0;
                    padding-bottom: 25px;
                }
            }
        }

        .cta.link {
            margin-top: 20px;

            &.activated:hover {
                --text: #fff;
            }
        }

        &+.masthead-background-image {
            top   : 0;
            height: 100vh;
            bottom: 0;
        }
    }



    .article-type-row {
        padding-top   : 2rem;
        padding-bottom: 1rem;
    }

    .headline {

        @include media-breakpoint-up(md) {
            margin-bottom: 24px;
        }
    }


}

// Custom client showcase styles

// Mobile container margin mgmt

@include media-breakpoint-down(sm) {

    .work-detail-gallery-panel {

        .gallery-container {
            padding-left : 0;
            padding-right: 0;
        }
    }
}




// Business list
.insights-detail .business-column {

    .business-list,
    ul {
        list-style-type: disc;
    }
}

// Blokus

.custom-split-copy-block {
    padding-left : 0;
    padding-right: 0;

    .row {
        margin-bottom: 0;
    }
}

// Long scroll with pinned column on one side
.long-scroll-content {
    overflow     : visible;
    position     : relative;
    margin-left  : 0;
    padding-left : 0;
    padding-right: 0;

    .super-grid {

        @include media-breakpoint-up(md) {
            display: grid;
        }

    }

    .container.inner {
        &.media-column-container {
            max-width: 1380px;
        }

        grid-row   : 1;
        grid-column: 1 / span 1;

        .dummy {
            height  : 0;
            padding : 0;
            overflow: hidden;
            margin  : 0;
        }
    }

    .copy-column {
        padding-top   : $block-spacer-40;
        padding-bottom: 0;
        padding-right : $block-spacer-40;
        height        : fit-content;

        @include media-breakpoint-up(lg) {
            padding-top   : $block-spacer-105;
            padding-bottom: $block-spacer-105;
            margin-bottom : $block-spacer-105;
        }
    }

    .grid-container {
        display : grid;
        grid-gap: .5rem;

        .copy-column {
            order: 1;
        }

        .media-column {
            order: 2;
        }

        @include media-breakpoint-up(md) {

            grid-template-columns: 50% 50%;

            .media-item {
                max-width: 676px;
            }
        }

        @include media-breakpoint-up(lg) {
            grid-gap: 1rem;
        }

        // Copy on right side, media col on left
        &.grid-column-reverse {

            @include media-breakpoint-up(lg) {
                .copy-column {
                    order: 2;
                }

                .media-column {
                    order: 1;
                }

                .copy-column {
                    padding-right: 1.5rem;
                    padding-left : 80px;
                }
            }
        }
    }

}

.media-item {
    margin-bottom: 38px;
}

.media-caption>p {
    padding-top: 12px;
    font-size  : clamp(12px, 6vw, 14px);
    line-height: clamp(16px, 6vw, 19px);
}
