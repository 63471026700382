.news-card {
    border-radius: 40px;

    .news-card-hdr {
        position: relative;

        .article-date,
        .article-title {
            display: block;
        }

        .article-date {
            margin-top: 22px;
            font-size : clamp(10px, 2vw, 13px);

            @include media-breakpoint-up(md) {
                margin-top: 40px;
            }
        }

        .article-type {
            @include link-font(8px);
            font-weight: bold;
            @include tracking(200);
            color        : $core_blue_oklch;
            margin-top   : .75rem;
            margin-bottom: .5rem;
            line-height  : 14px;

            @include media-breakpoint-up(md) {
                @include link-font(9px);
                margin-top   : 1.25rem;
                margin-bottom: 1rem;
                line-height  : 15px;
            }

            @include media-breakpoint-up(lg) {

                margin-top   : 1.75rem;
                margin-bottom: 1rem;

            }
        }

        .article-title {
            margin-top   : 0;
            margin-bottom: .25rem;
        }

        .article-img-parent {
            border-radius      : clamp(20px, 3vw, 30px);
            overflow           : hidden;
            height             : auto;
            backface-visibility: hidden;

            @include media-breakpoint-up(lg) {
                height: clamp(175px, 18vw, 200px);
            }

            @include media-breakpoint-up(xl) {
                height: clamp(210px, 22vw, 260px);
            }
        }

        // Apply height clamp for success stories index
        .success-stories-index & .article-img-parent {
            height: auto;
        }

        .article-img {
            object-fit         : cover;
            border-radius      : clamp(20px, 3vw, 30px);
            overflow           : hidden;
            height             : auto;
            backface-visibility: hidden;

            @include media-breakpoint-up(md) {
                height: auto;
            }
        }

    }

    .news-card-body {
        margin-bottom: 2px;

        p {
            text-wrap: pretty;
        }
    }

    .news-card-footer {
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }
    }
}
