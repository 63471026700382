.work-detail {
    #main {
        z-index : 1;
        position: relative;
    }

    @include media-breakpoint-down(md) {
        .masthead-background-image .img-parent {
            height: 90vh;
        }
    }

    p {
        text-wrap: balance;
    }
}

.masthead-parallax {
    overflow       : hidden;
    position       : relative;
    height         : auto;
    padding        : 3.5rem 2rem 3.5rem;
    flex-direction : column;
    justify-content: center;
    z-index        : 1;

    @include media-breakpoint-up(lg) {
        min-height: 480px;
        padding   : 6rem 6rem 6rem;
    }
}

.masthead-parallax-image {
    position   : relative;
    z-index    : 0;
    padding-top: 48%;

    @include media-breakpoint-up(md) {
        padding-top: 34%;
    }

    // match the header height for balance
    @include media-breakpoint-up(lg) {
        padding-top: 480px;
    }

    .img-parent {
        display        : flex;
        height         : 110%;
        position       : absolute;
        top            : 0;
        left           : 0;
        width          : 100%;
        align-items    : flex-end;
        justify-content: center;

        img {
            object-fit: cover;
            position  : absolute;
            width     : 100%;
            height    : 100%;
        }
    }
}

.fixed-masthead {
    overflow      : hidden;
    position      : relative;
    height        : clamp(600px, 50vh, 650px);
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        height: 100vh;
    }


    .top-layer {
        position     : relative;
        display      : block;
        margin-bottom: 50vh;
        z-index      : 1;

        @include media-breakpoint-up(sm) {
            margin-bottom: 32vh;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: clamp(300px, 25vh, 250px);
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 50vh;
        }
    }

    .container.inner {
        height        : auto;
        z-index       : 1;
        position      : relative;
        padding-top   : 3rem;
        padding-bottom: 3rem;

        @include media-breakpoint-up(sm) {
            // padding-top   : 0;
            // padding-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            height: clamp(300px, 25vh, 250px);
        }

        @include media-breakpoint-up(lg) {
            height: 50vh;
        }


    }

    .copy-block {
        @include media-breakpoint-down(md) {
            padding-top: 30px;
        }
    }

    &+.masthead-background-image {
        position        : fixed;
        top             : 0;
        left            : 0;
        z-index         : 0;
        width           : 100%;
        height          : clamp(600px, 50vh, 650px);
        max-height      : 100%;
        background-color: $woodsmoke;


        @include media-breakpoint-up(md) {
            top: 0;
        }

        @include media-breakpoint-up(lg) {
            height: 100vh;
        }

        .img-parent {
            position   : absolute;
            top        : 0;
            left       : 0;
            z-index    : 0;
            display    : flex;
            height     : 100vh;
            width      : 100vw;
            opacity    : 0;
            will-change: opacity;
            transition : .6s opacity ease-in;



            .smoov &,
            .mobile & {
                opacity: 1;
            }

            img {
                object-fit     : cover;
                object-position: bottom center;
                position       : absolute;
                top            : 0;
                left           : 0;
                width          : 100%;
                height         : 100%;

                @include media-breakpoint-up(lg) {
                    top: 0;
                }
            }
        }

    }

}

.desktop .fixed-masthead {
    @include media-breakpoint-down(md) {
        height: clamp(520px, 50vh, 650px);
    }

    &+.masthead-background-image {

        @include media-breakpoint-down(md) {
            height: clamp(520px, 50vh, 650px);
        }

        .img-parent {
            top: 0;
        }

    }

}
