// From https://codepen.io/dev_loop/pen/OJWNabR
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.nav-list-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	overflow: hidden;
	padding-top: 15vh;

	.nav-list-wrapper {
		grid-row: 1/span 1;
	}

	.lower-link-nav {

		grid-row: 2/span 1;
	}

	@include media-breakpoint-up(sm) {
		display: grid;
	}

	@include media-breakpoint-up(md) {
		justify-content: center;
		padding-top: 0;
	}

}


.nav-list-wrapper {
	width: 100vw;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
	position: relative;

	@include media-breakpoint-up(sm) {
		padding: 5rem 4rem 0;
	}

	@include media-breakpoint-up(xxl) {
		padding: 10vh 4rem 0;
	}
}

.fancy-menu {
	position: relative;
	z-index: -1;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.menu__item {
	@include navItem();
	text-align: center;
	width: fit-content;
	align-self: center;
	position: relative;
	display: grid;
	align-items: center;
	@include display-font-semi-bold(32px);
	line-height: 1;


	@include media-breakpoint-up(sm) {
		font-size: 37px;
	}

	@include media-breakpoint-up(md) {
		line-height: clamp(43px, 6vw, 84px);
		font-size: clamp(37px, 5vw, 74px);
	}

	& * {
		position: relative;
	}

	span {
		grid-column: 1 /span 1;
		grid-row: 1;
		color: $white;
		padding: 10px 0;
		mix-blend-mode: normal;
		overflow: hidden;
		display: inline-block;
		text-transform: uppercase;
		background-image: linear-gradient(45deg, $white, $white);
		transition: background-image .25s ease-in;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		animation: gradient 10s linear 1s infinite;
		z-index: 1;
		position: relative;

		@include media-breakpoint-up(xl) {
			padding: 2vh 0;
		}
	}

	&:after {
		padding: 2vh 0;
		grid-column: 1 /span 1;
		grid-row: 1;
		content: attr(title);
		text-transform: uppercase;
		color: transparent;
		overflow: hidden;
		visibility: hidden;
		z-index: 0;
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		opacity: 0;
		background-image: linear-gradient(45deg, $turbo, $turbo, $core_green, $core_blue, $core_blue, $core_green, $turbo, $turbo);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-size: 600% 100%;
		animation: gradient 9s linear infinite;
		animation-direction: alternate;
		position: relative;
		transition: opacity .25s ease-in-out;
	}

	&:hover {
		text-decoration: none;

		&:after {
			visibility: visible;
			z-index: 1;
			opacity: 1;
		}
	}
}

.hover-menu {
	text-decoration: none;
	display: flex;
	flex-direction: column;

	.dropdown-menu {
		padding: 0;
		position: relative;
		display: flex;
		justify-content: center;
		height: 100%;
		max-height: 0;
		transition: max-height .5s ease-out, margin .3s ease-in-out;
		background-color: transparent;
		border: 0;
		border-radius: 0;
		margin: 0 0;
		transform-origin: center bottom;

		&:after,
		&:before {
			height: 1px;
			width: 100%;
			position: absolute;
			content: '';
			background-color: transparent;
			border: 0;
			@include magicHoverLine();
			left: 0;
			top: 0;
			bottom: auto;
			background-size: 200% 100%;
			z-index: -1;
		}

		&:after {
			bottom: 0;
			top: auto;
		}

		li {
			height: 2.5rem;
			width: 100%;

			@include media-breakpoint-up(md) {
				height: 51px;
				width: clamp(150px, 20vw, 200px);
			}
		}

		a.link {
			text-align: center;
			color: $white;
			transition: color .2s ease-in-out;
			background-color: transparent;
			background-image: none;
			padding: 5px;
			height: 100%;
			position: relative;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;

			&:after {
				content: '';
				display: block;
				height: 100%;
				width: 100%;
				transform: scaleY(0);
				background-color: $turbo;
				transition: transform .2s ease-in;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				z-index: -1;
				transform-origin: center;
				@include magicHoverLine();
			}

			&:before {
				display: none;
			}

			&:hover {
				color: $black;

				&:link,
				&:visited,
				&:focus,
				&:active {
					color: $black;
				}

				&:after {
					z-index: -1;
					transform: scaleY(1);
				}
			}
		}
	}

	&.hover {
		text-decoration: none;

		.dropdown-menu {
			height: auto;
			max-height: 200px;
			margin: 0 0 0.75rem;

			&:after,
			&:before {
				transform: scale(1);
			}
		}
	}
}



.social-footer {
	position: relative;
	padding: 10px;
	display: flex;
	justify-content: center;

	.social-link {
		margin: 0 2px;
		padding: .25rem;
		color: $white;
		backface-visibility: hidden;
		transition: all 150ms ease;
		transform-origin: bottom center;

		&:hover {
			transform: scale(1.1);
		}
	}
}


.lower-link-nav {
	width: 100%;
	padding: 0 3rem;
	visibility: hidden;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@include media-breakpoint-up(sm) {
		padding: 0 0 3rem;
	}

	@include media-breakpoint-up(xl) {
		padding: .4rem 1.5rem 1.5rem 1.5rem;
		text-align: right;
		justify-content: space-between;
	}

	.link-wrapper {

		@include media-breakpoint-up(xl) {
			display: flex;
			justify-content: flex-end;
			padding: 1rem 3rem;
		}

	}

	.link {
		color: $white;

		&:before {
			background-color: $white;
		}

		&:hover {
			color: $white;
		}

		&.privacy-policy {

			&:before,
			&:after {
				transform-origin: left center;
				width: 100%;
				content: "";
				height: 1px;
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: $white;
				content: '';
			}

			&:before {}

			&:after {
				opacity: 0;
				display: block;
				@include magicHoverLine();
			}

			&:hover {
				background-image: none;

				&:after {
					display: block;
					z-index: 1;
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}


	body.nav-expanded & {
		z-index: 1002;
		visibility: visible;
	}
}
