.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
    }
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4 {
    font-family: $display-font-med;
}

.hdr-xxl {
    @include display-font-med(58px);
    line-height: clamp(43px, 7vw, 110px);
    font-size: clamp(37px, 6vw, 100px);
}

.hdr-xl {
    @include display-font-med(40px);
    line-height: clamp(43px, 6.5vw, 84px);
    font-size: clamp(37px, 6vw, 74px);
}

// Large fonts need scaling back on small portrait screens to prevent long words from breaking width
.hdr-xxl,
.hdr-xl {
    @media screen and (max-width: 340px) {
        line-height: clamp(39px, 6vw, 84px);
        font-size: clamp(32px, 5vw, 74px);
    }
}

.hdr-lg-plus {
    @include display-font-med(32px);
    line-height: clamp(38px, 5vw, 78px);
    font-size: clamp(34px, 4.5vw, 68px);

    @include media-breakpoint-up(md) {
        line-height: clamp(39px, 6vw, 78px);
        font-size: clamp(34px, 5.5vw, 68px);
    }
}

.hdr-lg-plus-semi-bold {
    @include display-font-semi-bold(26px);
    line-height: clamp(38px, 5vw, 78px);
    font-size: clamp(34px, 4.5vw, 68px);

    @include media-breakpoint-up(md) {
        line-height: clamp(39px, 6vw, 78px);
        font-size: clamp(34px, 5.5vw, 68px);
    }
}

.hdr-lg-custom {
    @include display-font-med(32px);
    line-height: clamp(38px, 5vw, 50px);
    font-size: clamp(34px, 4.5vw, 42px);
}

.hdr-lg {
    @include display-font-med(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        line-height: clamp(32px, 6vw, 64px);
        font-size: clamp(26px, 5vw, 52px);
    }



}

.hdr-sm {
    @include display-font-med(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        line-height: clamp(32px, 5vw, 50px);
        font-size: clamp(26px, 4vw, 42px);
    }
}

// Custom intro block sizes
.intro-block-hdr-small {
    @include display-font-semi-bold(16px);
    line-height: 24px;

    @include media-breakpoint-up(md) {

        line-height: clamp(26px, 5vw, 30px);
        font-size: clamp(21px, 4vw, 22px);
    }
}

.intro-block-hdr-medium {
    @include display-font-semi-bold(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {

        line-height: clamp(32px, 5vw, 40px);
        font-size: clamp(26px, 4vw, 30px);
    }
}

.intro-block-hdr-large {

    @include display-font-semi-bold(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {

        line-height: clamp(32px, 5vw, 50px);
        font-size: clamp(26px, 4vw, 42px);
    }

}

// Work page intro (2nd) headline, has mobile size same as .semi-bold-42
.hdr-sm-semi-bold {
    @include display-font-semi-bold(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        line-height: clamp(32px, 6vw, 50px);
        font-size: clamp(26px, 5vw, 42px);
    }


}

.careers-page .hdr-sm-semi-bold {
    @include display-font-semi-bold(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        line-height: 40px;
        font-size: 30px;
    }
}

.hdr-sm-eyebrow {
    @include display-font-med(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        line-height: clamp(32px, 4.75vw, 40px);
        font-size: clamp(26px, 3.75vw, 30px);
    }

    margin-bottom: 9px;
}

.hdr-22-semi-bold {
    @include display-font-semi-bold(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        font-size: 22px;
        line-height: 28px;
    }

}

.hdr-22-med {
    @include display-font-med(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        line-height: clamp(26px, 6vw, 30px);
        font-size: clamp(21px, 5vw, 22px);
    }
}

.hdr-30-med {
    @include display-font-med(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        font-size: clamp(26px, 5vw, 30px);
        line-height: clamp(26px, 6vw, 36px);
    }
}

.thumbnail-text {
    @include display-font-med(21px);

    line-height: clamp(22px, 2.75vw, 40px);
    font-size: clamp(16px, 2.75vw, 30px);
}

.h5,
h5 {
    @include display-font-reg(15px);
    line-height: 1.2;

    @include media-breakpoint-up(md) {
        @include display-font-reg(30px);
        line-height: 34px;
    }
}

.h6,
h6 {
    @include display-font-bold(18px);
    line-height: 1.2;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        @include display-font-bold(22px);
        line-height: 34px;
    }
}

.semi-bold-22 {
    @include display-font-semi-bold(18px);

    line-height: clamp(25px, 4vw, 30px);
    font-size: clamp(18px, 3vw, 22px);
}

.semi-bold-30 {
    @include display-font-semi-bold(18px);

    line-height: clamp(24px, 4vw, 40px);
    font-size: clamp(18px, 3vw, 30px);
}

.semi-bold-42 {
    @include display-font-semi-bold(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        font-size: clamp(26px, 5vw, 42px);
        line-height: clamp(32px, 6vw, 50px);
    }
}

.semi-bold-52 {
    @include display-font-semi-bold(26px);
    line-height: 32px;

    @include media-breakpoint-up(md) {
        line-height: clamp(38px, 5vw, 64px);
        font-size: clamp(34px, 4vw, 52px);
    }
}

.laviossa-med-52 {
    @include display-font-med(27px);

    @include media-breakpoint-up(md) {
        line-height: clamp(2.375rem, 1.9402rem + 2.1739vw, 3.625rem);
        font-size: clamp(2rem, 1.5652rem + 2.1739vw, 3.25rem);
    }
}

.medium-27 {
    @include display-font-med(27px);
    line-height: clamp(28px, 4vw, 34px);
    font-size: clamp(22px, 4vw, 27px);
}

.category-tags {
    @include fontSize(8px);
    line-height: 1;
    font-weight: bold;
    color: $cat_grey;
    text-transform: uppercase;
    @include tracking(50);

    @include media-breakpoint-up(md) {
        @include fontSize(13px);
    }
}

.display-font {
    font-family: $display-font-reg;
}

.body-font {
    font-family: $body-font;
}

.eyebrow-hdr {
    font-weight: bold;
    display: block;
}

.small-font-type {
    @include link-font(8px);
    @include tracking(200);
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        @include link-font(9px);
    }
}

.block-title {
    color: $turbo;
    margin-bottom: 19px;
}

// Override all sup that's watsup
sup {
    font-size: 25%;
    vertical-align: text-top;
    top: 0.4rem;
    left: auto;
    right: 1px;
}

.text-balance {
    text-wrap: balance;
}
