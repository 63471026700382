.latest-news-panel {
    background-color: $woodsmoke;
    padding-top     : 90px;
    padding-bottom  : 90px;

    @include media-breakpoint-up(lg) {
        padding-top   : 120px;
        padding-bottom: 120px;
    }
}

.latest-news-title {
    @include display-font-med(75px);
    line-height: clamp(62px, 20vw, 150px);
    font-size  : clamp(55px, 20vw, 150px);
    color      : $raisin;
    text-align : center;

    @include media-breakpoint-up(sm) {
        line-height: clamp(62px, 17vw, 160px);
        font-size  : clamp(55px, 17vw, 150px);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 6px;
        white-space  : nowrap;
        font-size    : clamp(8vw, 12vw, 150px);
        line-height  : clamp(64px, 13vw, 150px);
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 12px;
    }

    @include media-breakpoint-up(xxl) {
        @include display-font-med(150px);
        line-height  : 150px;
        margin-bottom: 12px;
    }
}

.read-all-news {
    margin-top: -20px;
    text-align: center;

    .link {
        color : $white;
        --text: #fff;

        &:hover {
            --text: #fff;
        }
    }
}

.latest-news-crawl-container {
    position   : absolute;
    top        : -6px;
    width      : 100vw;
    overflow   : hidden;
    height     : 100%;
    display    : flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
        top: -8px;
    }

    a {
        text-decoration: none;
    }


    .article-link-text {
        color          : $turbo;
        text-decoration: none;
        @include magicBackgroundGradient();
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &:hover {
            @include magicRadialGradient();
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    article.marquee-item {
        flex        : none;
        margin-right: 1rem;
        flex-shrink : 0;
        padding     : 0 4px;
        font-smooth : always;

    }

    .crawl-wrapper {
        -webkit-font-smoothing: antialiased;
        width                 : fit-content;
        display               : flex;
        flex                  : auto;
        flex-direction        : row;
    }


}
