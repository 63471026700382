.balance-panel-block {
    padding: 40px 0;


    @include media-breakpoint-up(md) {
        padding: 120px 0;
    }

    .balance-split-panel {
        @include media-breakpoint-up(md) {
            align-items: flex-start;
        }

        @include media-breakpoint-up(xl) {
            grid-gap: 20px;
        }
    }

    .headline-copy {
        padding-top: 20px;

        .headline {

            @include media-breakpoint-up(lg) {
                align-items: flex-end;
            }
        }
    }

    .circle-photo {
        flex         : 1 0 45px;
        margin-left  : 10px;
        margin-right : 10px;
        margin-top   : -15px;
        height       : 45px;
        max-width    : 45px;
        border-radius: 50%;
        overflow     : hidden;

        @include media-breakpoint-up(sm) {
            flex        : 0 1 10vw;
            max-width   : 10vw;
            height      : 10vw;
            margin-left : 2vw;
            margin-right: 2vw;
        }

        @include media-breakpoint-up(lg) {
            flex        : 0 1 90px;
            max-width   : 90px;
            height      : 90px;
            margin-left : 20px;
            margin-right: 20px;
        }
    }

    .balance-line {
        @include display-font-reg(32px);

        line-height: clamp(40px, 7vw, 50px);
        font-size  : clamp(32px, 7vw, 40px);

        @include media-breakpoint-up(md) {
            @include display-font-reg(74px);
            line-height: clamp(43px, 7vw, 64px);
            font-size  : clamp(35px, 7vw, 54px);
        }

        @include media-breakpoint-up(lg) {
            @include display-font-reg(74px);
            line-height: clamp(42px, 7vw, 74px);
            font-size  : clamp(37px, 7vw, 54px);
        }

        @include media-breakpoint-up(xl) {
            @include display-font-reg(74px);
            line-height: clamp(42px, 7vw, 84px);
            font-size  : clamp(37px, 7vw, 74px);
        }
    }

    .copy {
        .btn {
            margin-top: 24px;

            @include media-breakpoint-up(md) {
                margin-top: 40px;
            }
        }
    }
}
