.success-stories-index {

    .work-scroller-container {
        height: 350px;
    }

    .filter-tray-container {
        padding-top   : 3rem;
        padding-bottom: 45px;

        @include media-breakpoint-up(lg) {
            padding-top   : 90px;
            padding-bottom: 45px;
        }

    }
}

.filter-buttons {
    flex-direction: column;
    flex          : 1 0 50%;


    @include media-breakpoint-up(sm) {
        flex-direction: row;
        padding-right : 1.75rem;
        grid-gap      : 20px;
    }

    @include media-breakpoint-up(md) {
        grid-gap     : 20px;
        padding-right: 2.75rem;
    }

    .button-grid {
        flex-wrap: nowrap;
        grid-gap : 10px;

        @include media-breakpoint-up(md) {
            grid-gap             : 20px;
            display              : grid;
            grid-template-columns: 50% 50%;
        }
    }
}

.btn.btn-primary.filter-btn-close {
    text-transform: none;
    font-family   : $body-font;
    @include fontSize(12px);
    @include tracking(0);
    border             : 1px solid $filter_btn_border_color;
    background-color   : $white;
    border-radius      : 20px;
    min-height         : 32px;
    padding            : 10px 2.5rem 10px 15px;
    background-image   : url('/static/img/icons/ui/filter-close-x.svg');
    background-repeat  : no-repeat;
    background-position: calc(100% - 15px) center;
    background-size    : 10px 10px;
    color              : $black;
    transform-origin   : center right;
    transition         : none;

    &:hover {
        background-color: $filter_btn_border_color;
        background-size : 11px 11px;
    }
}


.js-filter-tray-btn.btn.btn-primary {
    border-radius   : 10px;
    min-height      : 40px;
    background-color: $woodsmoke;
    color           : $white;
    padding-right   : 2rem;
    padding-left    : 10px;
    flex            : auto;

    &.active {
        color           : $black;
        background-color: $turbo_oklch;

        .arrow-img {
            transform: rotateZ(-180deg);

            .arrow-path {
                stroke: $black;
            }
        }
    }


}

.cat-pill-container-grid {
    display              : grid;
    grid-template-columns: 1fr;
    grid-template-rows   : 1fr;
    height               : 0;
    width                : 100%;
    align-items          : flex-end;
    overflow             : hidden;

    &.active-grid {
        padding-top: 7rem;

        @include media-breakpoint-up(md) {
            padding-top: 0
        }
    }
}

.cat-pill-drawer {
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-wrap      : wrap;
    height         : fit-content;
    transition     : all .3s ease-out;
    overflow       : hidden;
    visibility     : hidden;
    grid-gap       : .25rem;
    grid-column    : 1 / span 1;
    grid-row       : 1 / span 1;
    width          : 100%;
    margin         : 0 auto;
    position       : relative;
    top            : -5px;

    @include media-breakpoint-up(md) {
        max-width: calc(100% - 6rem);
        grid-gap : 1rem;
    }

    &.active {
        visibility: visible;

        .btn.pill.btn-primary {
            transform: scale(1);
            opacity  : 1;
        }
    }

}



.arrow-img {
    right   : -1rem;
    width   : 12px;
    height  : 7px;
    position: relative;

    .arrow-path {
        stroke: $white;
    }
}

#js-more-work {
    position : absolute;
    bottom   : 40px;
    margin   : 0 auto;
    left     : 50%;
    transform: translateX(-50%);
}
