// Scaling management

.icon-wrapper {
    display   : flex;
    // height : 250px;
    // width  : 250px;
    width     : 100px;
    height    : 100px;

    // Custom element
    lord-icon {}
}
