.block.video-block,
.video-block,
.video-overlay-trigger,
.masonry-overlay-trigger[data-href] {

    .play-btn {
        position           : absolute;
        width              : 100%;
        height             : 100%;
        left               : 0;
        top                : 0;
        content            : '';
        background-image   : url(/static/img/svg/play-circle-white.svg);
        background-size    : 54px;
        background-repeat  : no-repeat;
        background-position: center;
        z-index            : 1;
        transform-origin   : center;
        backface-visibility: hidden;
        transform-origin   : center;
        transition         : .2s transform linear;

        @include media-breakpoint-up(lg) {
            background-size: 97px;
        }

        &:hover {
            transform: scale3d(1.03, 1.03, 1.03);
        }
    }


}

.play-vid.poster {
    overflow     : hidden;
    border-radius: clamp(1rem, 2vw, 20px);
    aspect-ratio : 16/9;
    position     : relative;

    .photo-parent {
        overflow: hidden;
        height  : 100%;
        width   : 100%;
        display : flex;

        img {
            object-fit: cover;
        }
    }
}

.video-overlay-trigger,
.masonry-overlay-trigger {
    transition: .2s all linear;
    width     : 100%;

    @include media-breakpoint-up(lg) {
        max-width: 992px;
    }

    .photo-parent {
        backface-visibility: hidden;
    }

    img {
        opacity            : 1;
        transition         : .2s opacity linear;
        backface-visibility: hidden;
    }

    &:hover {
        background-color: $black;

        img {
            opacity: .7;
        }
    }
}

.block.copy-with-video-block {

    .play-btn {
        background-size: 54px;
    }

    .copy-area {
        &.padding-right {

            @include media-breakpoint-up(md) {
                padding-right: 40px;
            }

            @include media-breakpoint-up(xl) {
                padding-right: 75px;
            }
        }

        &.padding-left {

            @include media-breakpoint-up(md) {
                padding-left: 40px;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 75px;
            }
        }
    }


}
