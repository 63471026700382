.hbspt-form {
    @include fontSize(17px);

    @include media-breakpoint-down(md) {
        @include fontSize(12px);
        line-height: 19px;
    }

    .hs-form-field {
        @extend .mb-3;
    }

    .hs-form-field ul {
        left: 0;
    }

    .hs-form-field.passwordinput {
        width: 100%;
        max-width: 300px;
        align-self: center;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        label {
            margin-right: .5rem;
        }

        input {
            flex: 0 1 193px;
        }

        .errorlist {
            flex: 1 0 100%;
        }
    }

    .hiddeninput {
        margin-bottom: 0 !important;
    }

    label {
        display: block;
    }

    .help-text {
        margin-top: 5px;
        color: $core_grey;
    }

    input {
        background-color: white;
        border: 1px solid $input_border;
        padding: 12px;
        outline: 0;
        width: 100%;
        color: $black;

        &[type='text'],
        &[type='email'] {
            height: 40px;

            @include media-breakpoint-up(lg) {
                height: 55px;
            }

        }
    }

    textarea {
        width: 100%;
        height: 230px;
        padding: 12px;
        border: 1px solid $input_border;
    }

    input,
    textarea,
    select {
        border-radius: 5px;
        cursor: auto;
        transition: all .2s ease-in;

        &::-webkit-input-placeholder {
            color: $input_border;
        }

        &:-moz-placeholder {
            color: $input_border;
        }

        &::-moz-placeholder {
            color: $input_border;
        }


        &:hover {
            box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.02);
        }

        &:focus {
            color: $black;
            border: 1px solid $turbo;
            box-shadow: inset 1px 2px 4px rgba($turbo, 0.01), 0px 0px 8px rgba($turbo, 0.2);
            outline: none !important;
        }

    }

    select {
        width: 100%;
        padding: 5px;
        border: 1px solid $input_border;
        height: 40px;

        @include media-breakpoint-up(lg) {
            height: 55px;
        }


    }

    // 'minimal' select from https: //codepen.io/vkjgr/pen/VYMeXp
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: $white;
        background-image: linear-gradient(45deg, transparent 50%, $core_grey 50%),
            linear-gradient(135deg, $core_grey 50%, transparent 50%),
            linear-gradient(to right, $input_border, $input_border);
        background-position:
            calc(100% - 20px) 50%,
            calc(100% - 15px) 50%,
            calc(100% - 2.5em) 50%;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;


    }

    @-moz-document url-prefix() {
        select {
            -moz-appearance: none;
            background-image: linear-gradient(45deg, transparent 50%, $core_grey 50%),
                linear-gradient(132deg, $core_grey 50%, transparent 50%),
                linear-gradient(to right, $input_border, $input_border);
        }

        select:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
        }
    }


    .selectmultiple {
        select {
            height: auto;
        }
    }

    // Custom checkbox with tick
    input[type=checkbox] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: #000000;
        width: 18px;
        height: 18px;
        border: 2px solid #004677;
        border-radius: 2px;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        padding: 9px;
        margin-top: 0;
        margin-right: 4px;

        &:after {
            // background-image    : url('/static/img/icons/ui/play-hover.svg');
            background-image: url('/static/img/icons/ui/cute-lil-blue-checkmark.svg');
            background-size: 9px 7px;
            background-repeat: no-repeat;
            background-position: center;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            color: #004677;
            align-self: stretch;
            height: 100%;
            width: 100%;
            transition: all .15s linear;
            transform-origin: center;
            color: $black;
            transform: scale(0);

            @include media-breakpoint-up(md) {
                // left: 2px;
                // top : -5px;
            }


        }

        &:checked:after {
            transform: scale(1);
        }
    }

    // Single
    .hs-form-field.hs-fieldtype-booleancheckbox ul {
        list-style-type: none;

        .hs-form-booleancheckbox {
            display: flex;
            justify-content: flex-end;
            flex-direction: row-reverse;
            align-items: center;

            .hs-form-booleancheckbox-display {
                display: flex;
                align-items: center;

                span {
                    display: inline-block;
                }
            }


            &>label {
                margin-left: 4px;
            }

            &>input {
                margin-top: 4px;
            }
        }
    }

    // Multiple
    .CheckboxSelectMultiple {
        li>label {
            position: relative;
            display: flex;
            align-items: center;
        }
    }

    .recaptchav3 {
        visibility: hidden;
        display: none;
    }

    input[type="radio"] {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: $black;
        width: 18px;
        height: 18px;
        border: 2px solid $black !important;
        border-radius: 100%;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        padding: 9px;

        &:before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 100%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $black;
        }

        &:checked::before {
            transform: scale(1);
        }
    }

    .RadioSelect,
    .CheckboxSelectMultiple {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 3px;

                label {
                    &:after {
                        display: none;
                    }
                }
            }

            label {
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;

                input {
                    margin-right: .5rem;
                    margin-top: 4px;
                }
            }
        }
    }

    .errorlist {
        padding: 0;
        margin: 5px 0 0 0;

        li {
            display: block;
            color: $orange;
        }
    }

    .required {
        label {
            &:after {
                content: "*";
            }
        }
    }

    .hs-button {
        text-decoration: none;
        font-family    : $body-font;
        overflow       : hidden;
        width: auto;

        &:focus,
        &:active {
            outline   : none !important;
            box-shadow: none;
        }

        background-color: $turbo;
            color           : $black;
            text-transform  : uppercase;
            border-color    : transparent;
            min-height      : 40px;
            padding-left    : 1.5rem;
            padding-right   : 1.5rem;
            border-radius   : 40px;
            font-weight     : bold;
            min-width       : auto;
            border          : 0;
            display         : inline-flex;
            justify-content : center;
            align-items     : center;
            @include link-font(8px);
            @include tracking(200);

            @include media-breakpoint-up(md) {
                @include link-font(9px);
                min-height: 50px;
            }

            // Hover devices
            @media(hover: hover) {
                &:hover {
                    text-decoration : none;
                    background-color: $black;
                    color           : $white;
                }
            }
    }

    .submitted-message {
        a {
            text-decoration: none;
            font-family    : $body-font;
            overflow       : hidden;
            width: auto;

            &:focus,
            &:active {
                outline   : none !important;
                box-shadow: none;
            }

            background-color: $turbo;
                color           : $black;
                text-transform  : uppercase;
                border-color    : transparent;
                min-height      : 40px;
                padding-left    : 1.5rem;
                padding-right   : 1.5rem;
                border-radius   : 40px;
                font-weight     : bold;
                min-width       : auto;
                border          : 0;
                display         : inline-flex;
                justify-content : center;
                align-items     : center;
                @include link-font(8px);
                @include tracking(200);

                @include media-breakpoint-up(md) {
                    @include link-font(9px);
                    min-height: 50px;
                }

                // Hover devices
                @media(hover: hover) {
                    &:hover {
                        text-decoration : none;
                        background-color: $black;
                        color           : $white;
                    }
                }
            }
       
    }
}

.form-standard .DateInput {
    .picker {
        .picker__header {
            padding: 0 1rem;
            display: flex;
        }
    }
}

.main-content .form-standard {

    ul,
    ul[id^='id_options'] {
        left: 0;
    }
}

.main-content .hs-form-field {

    ul,
    ul:not([class]) {
        padding-left: 0;
    }
}

