.video-landing {
    header.masthead.b2b {
        // padding-bottom: -20px;
        padding-bottom: clamp(-120px, -120px, -180px);
        padding-top: clamp(110px, 14vw, 150px);
        
        .container {
            z-index: 12;

            .full-video-container {
                margin-bottom: -60px;
            }
        }
    }

    main {
        margin-top: 80px;
        min-height: 400px;


        .card-grid {
            position             : relative;
            z-index              : 1;
            display              : flex;
            flex-wrap            : wrap;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap             : 3rem;
            padding-top          : 0;
            justify-content      : center;

            &.download-grid-two {
                @include media-breakpoint-up(md) {
                    display: flex;
                }
            }
        
            @include media-breakpoint-up(md) {
                display      : grid;
                grid-gap     : 2rem;
                margin-bottom: 40px;
            }
        
            .card {
                background-color: $whitesand;
                padding         : 4.5rem 4vw 2rem;
                overflow        : visible;
                display         : flex;
                align-items     : center;
                text-align      : center;
                flex-direction  : column;
                border-radius   : clamp(1rem, 2vw, 20px);
                max-width       : 450px;
                flex            : 1 1 450px;
                margin-bottom   : 0;
        
                &:last-child {
                    margin-bottom: 0;
                }
        
                @include media-breakpoint-up(lg) {
                    padding: 4.5rem 3rem 2.5rem;
                }
        
        
                &:nth-child(2) {
                    @include media-breakpoint-up(md) {
                        transform: none;
                    }
                }
            }
        
            .card .content p {
                text-wrap: pretty;
        
                b {
                    color: $core_blue;
                }
        
            }
        
            .icon-wrapper {
                border-radius   : clamp(1rem, 2vw, 20px);
                background-color: $white;
                box-shadow      : rgba($drop_shadow, 0.2) 6px 6px 30px 0px;
                position        : absolute;
                top             : 0;
                transform       : translateY(-50%);
                padding         : .85rem;
            }
        }
    }
}

