.work-scroller-container {
    width          : 100%;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    height         : 350px;

    @include media-breakpoint-up(sm) {
        height: 450px;
    }

    @include media-breakpoint-up(lg) {
        height: 600px;
    }

    @include media-breakpoint-up(xl) {
        height: 800px;
    }

    @include media-breakpoint-up(xxl) {
        height: 900px;
    }
}

.work-scroller-wrapper {
    width           : 135vw;
    margin          : 0 auto;
    height          : 100%;
    overflow        : hidden;
    display         : flex;
    grid-gap        : 30px;
    background      : $black;
    position        : absolute;
    transform-origin: center;
    left            : 50%;
    transform       : translateX(-50%);

    @include media-breakpoint-up(sm) {
        width: 120vw;
    }

    @include media-breakpoint-up(lg) {
        width: 135vw;
    }
}

.scroll-column {
    flex: 1 33%;

    .scroller {
        display       : flex;
        flex-direction: column;
        grid-gap      : 30px;
    }
}


// Mobile
@media (max-width: 600px) {

    .work-scroller-wrapper {
        grid-gap: 13px;
        width   : 140vw;
    }

    .scroll-column {
        .scroller {
            display       : flex;
            flex-direction: column;
            grid-gap      : 13px;
        }
    }
}
