.results-panel-block {

    &.two-core-teams-panel {
        padding-bottom  : 60px;
        background-color: $whitesand;
        background-image: none;



        @include media-breakpoint-up(sm) {
            .team-card {
                padding-bottom: 30px;
            }
        }

        @include media-breakpoint-up(md) {
            .content-row {
                padding-top: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top   : 0;
            padding-bottom: 90px;

            .team-card {
                padding-bottom: 30px;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 120px;
        }

        .cta-link {
            margin-top: 1rem;
            color     : $black;
            --text    : #000;
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    .intro-row {
        @include media-breakpoint-up(md) {
            padding-bottom: 50px;
        }
    }

    .pin-spacer {
        margin-left : 0 !important;
        margin-right: 0 !important;
    }

    .btn-row {
        @include media-breakpoint-up(md) {
            padding-top: 50px;
        }
    }

    @include media-breakpoint-up(md) {
        margin-top    : 0;
        padding-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }

    @include media-breakpoint-up(xl) {
        margin-top    : 0;
        padding-bottom: 108px;
    }

    @include media-breakpoint-up(xxl) {
        margin-top    : 0;
        padding-bottom: 108px;
    }

}

.offerings-panel {

    .service-hdr {
        text-align     : center;
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;

        .icon-wrapper {
            margin-bottom: 18px;
        }
    }

    .arrow-wrapper {
        width   : 23px;
        position: relative;
        height  : 50px;
        overflow: hidden;

        #tall_arrow {
            position        : absolute;
            bottom          : 0;
            transform-origin: top;

            #arrow_line {
                transform-origin: top;
                stroke          : $black;
                stroke-width    : 2px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .arrow-column {
            display: none;
        }
    }

}
