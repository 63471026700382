.masthead {
    &.h-750 {
        height    : 100%;
        min-height: 750px;
        max-height: calc(100vh - 96px);
    }

    .container.inner {
        height: 100%;
    }

    &.standard-480 {
        height    : 100%;
        min-height: 300px;
        max-height: calc(100vh - 96px);

        @include media-breakpoint-up(lg) {
            min-height: 480px;
        }
    }

    &.height-640 {
        height    : 100%;
        min-height: 600px;

        @include media-breakpoint-up(lg) {
            min-height: 640px;
        }
    }

    a,
    p>a {

        &:not(.btn):link,
        &:not(.btn):visited,
        &:not(.btn):focus,
        &:not(.btn):hover,
        &:not(.btn):active {
            color      : $white;
            font-weight: 500;
        }

        &:hover {
            font-weight: 700;
        }

    }

}
