.text-black {
    color: $black !important;
}

.text-white {
    color: $white !important;
}

.text-turbo {
    color: $turbo !important;
}