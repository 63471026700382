// Image MGMT
.richtext-image {
    position: relative;
    width   : auto;
    height  : auto !important;
    display : inline-block;

    @include smallRadiusCorner();
    overflow: hidden;



    .caption {
        display       : inline-block;
        width         : 100%;
        padding-top   : 12px;
        padding-bottom: 12px;
    }

    img {
        width    : 100%;
        max-width: 100%;
        float    : left;
    }

    &.right,
    &.left {
        max-width : 100%;
        height    : auto;
        max-height: calc(100vw * 3/4);

        @media screen and (min-width: 480px) {
            max-width: 50%;
        }
    }

    &.right {
        margin: 0 0 1rem 2rem;
        float : right;

        @include media-breakpoint-up(xl) {
            margin   : 0 0 1.5rem 2rem;
            max-width: 50%;
        }

        .caption {
            float     : right;
            text-align: right;
        }

    }

    &.left {
        margin: 1rem 2rem 1rem 0;

        @include media-breakpoint-up(xl) {
            margin   : 1.5rem 2rem 1.5rem 0;
            max-width: 50%;
        }

        .caption {
            float       : left;
            text-align  : left;
            padding-left: 30px;
        }
    }

    &.full-width {
        display  : inline-block;
        clear    : both;
        margin   : 2rem 0;
        width    : 100%;
        max-width: none;

        .caption {
            float : left;
            clear : both;
            margin: 0 0 3rem 30px;
            width : 100%;
        }
    }
}


// Format richtext lists

[data-block-key]+ul,
[data-block-key]+ol {
    margin-top   : .5rem;
    margin-bottom: 0;

    &+[data-block-key] {
        margin-top: 1rem;
    }
}
