@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

// large breakpoints
$grid-breakpoints: (xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xlg: 1350px,
    xxl: 1440px,
    xxxl: 1800px);

// For overrides in helpers/border-radius.scss
$border-radius         : 5px;
$border-radius-input   : 4px;
$border-radius-checkbox: 2px;

$link-color: $black;

// Block padding/margin
$block-spacer-10 : 10px;
$block-spacer-20 : 20px;
$block-spacer-30 : 30px;
$block-spacer-40 : 40px;
$block-spacer-60 : 60px;
$block-spacer-80 : 80px;
$block-spacer-90 : 90px;
$block-spacer-105: 105px;
$block-spacer-110: 110px;
$block-spacer-120: 120px;
