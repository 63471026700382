.background {
    &-white {
        background-color: $white;
    }

    &-black {
        background-color: $black !important;
    }

    &-light-grey {
        background-color: $whitesand !important;
    }

    &-woodsmoke {
        background-color: $woodsmoke;

        &>* {
            color: $white;
        }
    }

    &-raisin {
        background-color: $raisin;

        &>* {
            color: $white;
        }
    }

    &-whitesand {
        background-color: $whitesand;
    }

    &-gradient {
        background: $vanilla-sky-bg-gradient-reversed;
    }

    &-light-grey,
    &-grey,
    &-none {
        * {
            color: $black;
        }
    }

    &-purple {
        background-color: $purple;
    }
}
