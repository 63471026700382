.news-detail .main-content {
    overflow  : visible;
    z-index   : 1;
    position  : relative;
    margin-top: -50px;

    @include media-breakpoint-down(sm) {
        section.block {
            padding-left : 1.5rem;
            padding-right: 1.5rem;

            &.news-quoteblock {
                padding-left : .75rem;
                padding-right: 0.75rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-top: -80px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: -100px;
    }


    section:not(.cc-contact-panel-block) {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
        }

        &.block .container.inner {


            @include media-breakpoint-up(md) {
                max-width: 700px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 760px;
            }

        }

        // Contact form in-page
        &.block .container.inner.custom-form-container {

            .form-headline {
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }

            }

            padding: 3rem;

            @include media-breakpoint-up(md) {
                padding  : 50px 60px;
                max-width: calc(760px + 120px);
            }
        }

        // Contact form in-page
        &.block.video-block .container.inner {

            @include media-breakpoint-up(md) {
                max-width: calc(760px + 120px);
            }
        }


    }

    .category-list {
        grid-gap: 1rem;
    }

    .category-tag-btn {
        flex            : 0 1 fit-content;
        background-color: $whitesand;
        color           : $black;
        border-radius   : 10px;
        padding         : 8px 12px;
        min-height      : 38px;

        &:hover {
            color: $white;
        }

    }
}


.news-detail .article-hero {
    .article-hero-parent {

        @include media-breakpoint-up(md) {
            margin-bottom: 10px;
        }

        .article-hero-img {
            height: auto;
        }
    }
}

.news-detail {
    .masthead.no-hero+.main-content {
        padding-top: 80px;
        margin-top : 0;
    }

    .back-to-index {
        margin-left: 1.65rem;
        position   : relative;

        &:before {
            content            : '';
            position           : absolute;
            transition         : transform .4s ease-in-out;
            top                : 8px;
            left               : 0;
            transform          : scaleX(-1);
            height             : 14px;
            width              : 26px;
            background-position: right center;
            background-size    : contain;
            background-repeat  : no-repeat;
            background-image   : url(/static/img/icons/arrows/small-right-arrow.svg);
            background-size    : contain;
            background-color   : transparent;
        }

        &:hover {
            &:before {
                transform: translateX(-5px) scaleX(-1);
            }
        }
    }

    .content-wrapper-760 {
        max-width: calc(100% - 3rem);

        @include media-breakpoint-up(lg) {
            max-width: 784px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 760px;
        }
    }

    .content-wrapper-840 {
        width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: 840px;
        }
    }

    .two-up-grid {
        flex-direction: column-reverse;

        .col {
            margin-bottom: 2rem;

            &:first-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;

            .col {
                margin-bottom: 0;
            }
        }

    }

    .related-content-block {
        .headline {
            margin-bottom: 2rem;

            @include media-breakpoint-up(xl) {
                margin-bottom: 60px;
            }
        }




    }

    .custom-form-container {
        form.contact-form {
            display        : flex;
            flex-direction : column;
            align-items    : center;
            justify-content: stretch;
        }

        .form-standard.contact-form {


            .form-field {
                position : relative;
                width    : 100%;
                max-width: 350px;

                &.required {
                    label {
                        &:before {
                            content : '*';
                            color   : $white;
                            position: absolute;
                            right   : -8px;
                            top     : -2px;
                        }
                    }
                }

                label {
                    position        : absolute;
                    top             : 0.68rem;
                    left            : 50%;
                    transition      : all 0.2s ease-out;
                    transform       : translate(-50%, 0) scale(1);
                    transform-origin: center;

                    @include media-breakpoint-between(md, lg) {
                        font-size: 14px;
                        top      : .5rem;

                        &:after {
                            height: 40%;
                        }
                    }

                    &:after {
                        content         : '';
                        height          : 61%;
                        position        : absolute;
                        width           : calc(100% + .5rem);
                        left            : -.25rem;
                        top             : 0;
                        z-index         : -1;
                        opacity         : 0;
                        background-color: $woodsmoke;
                    }
                }

                &.active label {
                    text-shadow: 2px 2px 4px rgba($black, 1);
                    left       : 50%;

                    &:after {
                        opacity: 1;
                    }

                    @include media-breakpoint-between(md, lg) {
                        transform: translate(-50%, -1.5rem) scale(.8);
                    }

                    left     : 50%;
                    transform: translate(-50%, -2rem) scale(.8);
                }

                input {
                    text-align: center;
                }

                input,
                textarea {
                    color           : $white;
                    background-color: $raisin;
                    border          : 1px solid $field_border_color;

                }

                a {
                    color: $core_blue_oklch;
                }

                // Custom privacy checkbox
                &.privacy-check.news-detail {

                    label {
                        @include fontSize(12px);
                        transform: none;
                        top      : auto;
                        left     : 3px;
                        position : relative;

                    }

                    &>input[type=checkbox] {
                        border: 1px solid $field_border_color;
                    }

                }

            }

            .submit-row {
                display       : flex;
                flex-direction: column;

                .js-btn-split.activated {


                    &:hover {
                        --text          : #000;
                        background-color: $white;
                    }
                }
            }
        }

        a:link {
            color: $core_blue_oklch;
        }


    }




    .news-img-copy-block {
        .flex-row-reverse .copy-box {
            @include media-breakpoint-up(xl) {
                padding-left: 2rem;
            }
        }
    }



    .js-btn-split.activated,
    .link.activated,
    .format-link.activated:hover {

        --text: #fff;

    }

}

.news-detail .modal {

    .modal-header {
        position      : relative;
        padding-top   : 1.5rem;
        padding-bottom: .25rem;
        margin-top    : .5rem;
    }

    .close-x {
        background: transparent;
        border    : 0;
        position  : absolute;
        right     : 0;
        top       : 0;
        z-index   : 11;
        padding   : 0;
    }

    .btn-close {
        background-color   : transparent;
        background-image   : none;
        border             : 0;
        height             : 20px;
        width              : 20px;
        background-size    : contain;
        background-position: center;
        background-repeat  : no-repeat;
        opacity            : 1;
        @include transition(.23s all ease);

        span {
            visibility: hidden;
        }
    }
}

.news-detail .article-footer {
    position: relative;

    &:before {
        height  : 1px;
        width   : 100%;
        position: absolute;
        top     : 0;
        left    : 0;
        content : '';
        z-index : 0;
        @include magicBackgroundGradient();
    }

    padding-top   : 2rem;
    padding-bottom: 2rem;

    .container.inner {
        max-width: 100%;
    }

    @include media-breakpoint-up(sm) {
        .container.inner {
            max-width: calc(100% - 3rem);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top   : 60px;
        padding-bottom: 60px;

        .container.inner {
            max-width: none;
        }
    }

    .author-info {
        display : flex;
        flex    : 1 80%;
        grid-gap: 40px;
    }

    .col.social {
        backface-visibility: hidden;
        flex               : 0 1 50px;
        position           : relative;
    }

    .author-name-title {
        text-align: center;
        flex      : 1;

        @include media-breakpoint-up(sm) {
            text-align: left;
        }
    }

    .avatar-wrapper {
        border-radius              : 50%;
        overflow                   : hidden;
        display                    : flex;
        position                   : relative;
        height                     : clamp(60px, 14vw, 160px);
        width                      : clamp(60px, 14vw, 160px);
        -webkit-backface-visibility: hidden;
        -webkit-transform          : translate3d(0, 0, 0);
        backface-visibility        : hidden;

        img {
            position                   : relative;
            width                      : 100%;
            border-radius              : 50%;
            overflow                   : hidden;
            backface-visibility        : hidden;
            -webkit-backface-visibility: hidden;
            backface-visibility        : hidden;
            -webkit-transform          : translate3d(0, 0, 0);
        }
    }

    .name {
        margin-bottom: 0;
        font-weight  : bold;
        font-family  : inherit;
        font-size    : inherit;
        line-height  : inherit;

        @include media-breakpoint-down(md) {
            @include fontSize(12px);
            line-height: 19px;
        }
    }

    .title {
        @include fontSize(9px);
        line-height   : 14px;
        font-weight   : bold;
        text-transform: uppercase;
        color         : $cat_grey;

        @include media-breakpoint-up(md) {
            @include fontSize(11px);
            line-height: 16px;
        }
    }

    .social-share-links {
        z-index         : 12;
        position        : absolute;
        padding         : .75rem;
        display         : flex;
        grid-gap        : .75rem;
        background-color: $raisin;
        flex-direction  : column;
        border-radius   : 40px;
        overflow        : hidden;
        height          : 200px;
        width           : 50px;
        max-height      : 50px;
        transition      : .3s all ease-in-out;

        .social-icon {
            opacity   : 0;
            transition: .3s all ease-in-out;
        }

        .expand-icon {
            position  : absolute;
            top       : 15px;
            left      : 50%;
            transform : translateX(-50%) scale(1);
            width     : 20px;
            height    : 20px;
            transition: .3s all ease-out;
        }

        &.activated {
            max-height: 179px;

            .expand-icon {
                transform: translateX(-50%) scale(0);
            }

            .social-icon {
                opacity: 1;
            }
        }
    }

    .js-share-link {
        text-decoration: none;

        &:hover {
            img {
                transform: translateY(-1px);
            }
        }
    }
}

.news-detail {
    .main-content+footer {
        z-index: 2;
    }
}

.category-pill {
    display         : flex;
    align-items     : center;
    position        : absolute;
    background-color: $white;
    @include link-font(8px);
    @include tracking(200);
    top           : 14px;
    left          : auto;
    right         : 14px;
    transform     : translateX(0);
    padding       : 1px 10px 0 10px;
    border-radius : 14px;
    height        : 27px;
    line-height   : 1.1;
    text-transform: uppercase;
    z-index       : 2;
    box-shadow    : 0 0 10px rgba($black, .2);

    @include media-breakpoint-up(lg) {
        height: 30px;
    }
}
