.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.max-one {
        -webkit-line-clamp: 1;
    }

    &.max-two {
        -webkit-line-clamp: 2;
    }

    &.max-three {
        -webkit-line-clamp: 3;
    }

    &.max-four {
        -webkit-line-clamp: 4;
    }

    &.max-five {
        -webkit-line-clamp: 5;
    }

    &.max-six {
        -webkit-line-clamp: 6;
    }

    &.max-seven {
        -webkit-line-clamp: 7;
    }

    &.max-eight {
        -webkit-line-clamp: 8;
    }
}
