.block.quoteblock {
    position: relative;

    &.with-background {
        padding-top   : 150px;
        padding-bottom: 150px;

        @include media-breakpoint-up(sm) {}

        @include media-breakpoint-up(xl) {
            padding-top   : 150px;
            padding-bottom: 150px;
        }

        @include media-breakpoint-up(xlg) {
            padding-top   : 200px;
            padding-bottom: 200px;
        }

        @include media-breakpoint-up(xxl) {
            padding-top   : 237px;
            padding-bottom: 200px;
        }


        * {
            color: $white;
        }
    }

    .js-turbo-line-wrap {

        .turbo-line-stretch {
            transform: scaleX(0);
            height   : 2px;
            width    : 100%;
            @include magicBackgroundGradient();
        }
    }


    .cc-quote-block {
        text-align: center;

        .blockquote-container {
            padding: 60px 30px 56px;
        }

        cite {
            @include cite();
        }

        @include media-breakpoint-down(sm) {

            .container.inner {
                padding-left : .75rem;
                padding-right: .75rem;
            }
        }
    }

    .quote-author {
        @include fontSize(14px);
        @include tracking(130);
        text-transform: uppercase;
        font-weight   : 600;
        margin-top    : 25px;
    }

    q {
        &:before {
            margin-bottom: 33px;
        }
    }


}


.cc-quote-block {
    cite {
        /* the js-text-fade is cutting off the last letter when italics - need padding to display */
        padding-right: 2px;
    }
}