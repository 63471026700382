.how-we-do-it-panel {
    padding-top   : 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
        .container.inner {
            max-width: 100%;
        }
    }
}

.how-we-do-it-grid {
    position: relative;
    height  : auto;

    @include media-breakpoint-up(md) {

        .container.inner {
            max-width: 100%;
        }
    }

    // Kick in the grid
    @include media-breakpoint-up(md) {
        .left-side {
            padding    : 0;
            grid-column: 1 / span 1;
            grid-row   : 1 / span 1;
        }

        .right-side {
            grid-column: 1 / span 1;
            grid-row   : 1 / span 1;
        }
    }


    .left-side {
        position   : relative;
        overflow   : visible;
        margin-left: -1.5rem;

        .hwdi-image-container {
            position: relative;
            left    : -0.75rem;
            width   : calc(100vw - 2rem);
            height  : 100%;
            bottom  : 0;

            @include media-breakpoint-up(sm) {
                left : -1.75rem;
                width: calc(100vw - 3rem);
            }

            @include media-breakpoint-up(md) {
                position: relative;
                left    : auto;
                right   : 0;
                width   : 50vw;
            }

            @include media-breakpoint-up(lg) {
                width: 57vw;
            }

            @include media-breakpoint-up(xl) {
                left : auto;
                right: 0;
                width: 56.5vw;
            }
        }

        .photo-wrapper {
            position: relative;
            padding : 24px 24px 0 0;
            z-index : 1;

            @include media-breakpoint-up(md) {
                padding: 3rem 3rem 0 0;
            }

            @include media-breakpoint-up(xl) {
                padding: 60px 60px 0 0;
            }

            picture,
            img {
                z-index: 1;
                width  : 100%;
                height : auto;

                @include bigRadiusCorner('top-right');
            }
        }

        .pattern {
            width   : calc(100% - 24px);
            height  : calc(100% - 24px);
            right   : 0;
            position: absolute;
            top     : 0;
            z-index : -1;

            @include fingerPrint($light-print:false);

            @include media-breakpoint-up(sm) {
                width : calc(100% - 3rem);
                height: calc(100% - 3rem);
            }

            @include media-breakpoint-up(md) {
                width : calc(100% - 4.25rem);
                height: calc(100% - 3rem);
                left  : 4.25rem;
                right : auto;
            }

            @include media-breakpoint-up(lg) {
                width : calc(100% - 4.25rem);
                height: calc(100% - 3rem);
                left  : 4.25rem;
            }

            @include media-breakpoint-up(xl) {
                width : calc(100% - 90px);
                height: calc(100% - 60px);
                left  : auto;
                right : 0;
            }
        }
    }

    .right-side {
        position      : relative;
        padding-bottom: 40px;
        padding-left  : .75rem;

        @include media-breakpoint-up(md) {
            padding-left  : 0;
            padding-bottom: 0;
            left          : 50vw;
            width         : clamp(320px, 45vw, 560px);
        }

        @include media-breakpoint-up(lg) {
            left: 55vw;
        }

        @include media-breakpoint-up(xl) {
            left: 56.5vw;
        }

        .copy-box {
            width    : 100%;
            max-width: 500px;
            padding  : 0;
            position : relative;

            @include media-breakpoint-up(md) {
                padding: 0 .5rem 0 1.5rem;
            }

            @include media-breakpoint-up(lg) {
                padding: 0 2.5rem 0 3rem;
            }

            @include media-breakpoint-up(xl) {
                padding: 0 0 0 3rem;
            }
        }
    }
}
