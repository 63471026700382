.block-type-one_column_full_bleed {
    padding-left: 0;
    padding-right: 0;
}

.one-column-row.fullbleed {
    margin-left : 0;
    margin-right: 0;

    .one-column.copy-column {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
}