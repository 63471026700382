.cc-contact-panel-block {
    @include media-breakpoint-up(xl) {
        min-height: 450px;
    }

    .container.inner {
        padding-left : .25rem;
        padding-right: .25rem;

        @include media-breakpoint-up(sm) {
            padding-left : 3rem;
            padding-right: 3rem;
        }

        @include media-breakpoint-up(xxl) {
            padding-left : 0;
            padding-right: 0;
        }
    }
}
