.featured-work-panel {

    .container.inner {
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }

        .work & {
            @include media-breakpoint-down(lg) {
                max-width: 100%;
            }
        }
    }

    .negative-margin-text {
        padding: 0 40px;

        @include media-breakpoint-up(lg) {
            margin-bottom: -2rem;
        }

    }

    .js-turbo-line-wrap {
        padding: 0 40px;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        .headline {
            padding      : 1rem 1.5rem 0;
            margin-bottom: 23px;

            @include media-breakpoint-up(md) {
                margin-bottom: 27px;
            }
        }

        .turbo-line {
            height          : 4px;
            width           : 100%;
            background-color: $turbo;
        }
    }

    .cc-featured-items {

        @include media-breakpoint-up(sm) {
            max-width: 50%;
        }

        .cc-featured-item {
            padding         : 2.5rem;
            background-color: var(--bg-color);

            @include media-breakpoint-up(sm) {
                background-color: transparent;
                padding         : 0;
                height          : 100vh;
                display         : flex;
                justify-content : space-between;
                align-items     : center;
            }
        }

        // Work page tablet rules
        .work & {

            @include media-breakpoint-between(sm, lg) {
                max-width: 100%;

                .cc-featured-item-grid {
                    display       : flex;
                    align-items   : center;
                    flex-direction: row-reverse;
                    grid-gap      : 3rem;
                    padding-top   : 90px;
                    padding-bottom: 90px;
                }

                .cc-featured-item,
                .cc-featured-item-grid-col {
                    height: auto;
                    flex  : 1 50%;

                    &.-left {
                        height: auto;
                    }
                }

                .cc-featured-item {
                    padding-left    : 3rem;
                    padding-right   : 3rem;
                    background-color: var(--bg-color);
                }

                .cc-featured-photos-item-link {
                    margin-bottom: 0;
                }
            }

            .cc-featured-item-grid-col {}
        }

        .cc-featured-item-grid-col {
            &.-left {
                margin        : 0;
                max-width     : none;
                text-align    : left;
                display       : flex;
                flex-direction: column;
                height        : auto;

                @include media-breakpoint-up(sm) {
                    height: 100vh;
                }

                .js-copy-group {
                    flex           : 1;
                    display        : flex;
                    flex-direction : column;
                    justify-content: center;
                    align-items    : flex-start;
                    max-width      : 500px;
                    height         : 100%;

                    @include media-breakpoint-up(md) {
                        padding-right: 2rem;
                    }

                    @include media-breakpoint-up(xl) {
                        padding-right: 0;
                    }

                    .work-title {
                        margin-bottom: 14px;

                        @include media-breakpoint-up(md) {
                            margin-bottom: 18px;
                        }
                    }

                }
            }

            &.-right {

                .cc-featured-photos-item-link {
                    margin-bottom: 24px;

                    @include media-breakpoint-up(sm) {
                        margin-bottom: 0;
                    }

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 40px;
                    }

                    .img-parent {
                        width: 100%;
                    }
                }

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }


    }

    .cc-featured-photos {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        position: absolute;
        right   : 0;
        width   : 50%;
        top     : 0;
        overflow: hidden;
        height  : 100vh;

        &-container {
            height: 100vh;
        }

        .photo-mask {
            top      : 50%;
            transform: translateY(-50%);

            @include media-breakpoint-up(sm) {
                left    : auto;
                right   : 0;
                position: absolute;
                height  : 250px;
                width   : 250px;

            }

            @include media-breakpoint-up(md) {
                height: 390px;
                width : 330px;

            }

            @include media-breakpoint-up(lg) {
                height: 480px;
                width : 450px;

            }

            @include media-breakpoint-up(xl) {
                width     : clamp(520px, 58vh, 560px);
                height    : 80vh;
                max-width : 560px;
                max-height: 660px;
            }
        }

        .cc-featured-photos-item {
            top     : 0;
            left    : 0;
            right   : 0;
            position: absolute;
            width   : 100%;
            height  : 100%;
        }

        .img-parent {
            display : flex;
            position: absolute;
            left    : 0;
            height  : 100%;
            width   : 100%;

            img {
                object-fit: cover;
                position  : absolute;
                height    : 100%;
                width     : 100%;
            }
        }
    }
}
