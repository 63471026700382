.block {
    position: relative;
    overflow: hidden;

    // Format wagtail editor h2,h3,h4
    h2:not([class]) {
        font-family: $display-font-semi-bold;
        @include fontSize(26px);
        line-height: 32px;

        @include media-breakpoint-up(md) {
            @include fontSize(32px);
            line-height: 38px;
        }
    }

    h3:not([class]) {
        font-family: $display-font-semi-bold;
        @include fontSize(22px);
        line-height: 1.3;

        @include media-breakpoint-up(md) {
            @include fontSize(28px);
            line-height: 36px;
        }
    }

    h4:not([class]) {
        font-family: $display-font-semi-bold;
        @include fontSize(18px);
        @include tracking(130);
        line-height   : 24px;
        font-weight   : 600;
        text-transform: uppercase;
        margin-bottom : .25rem;
        display       : block;
        clear         : both;
    }

}
