.design-services {

    .featured-work-panel {
        background-color: var(--bg-color);
        padding-top     : 60px;
        padding-bottom  : 60px;

        @include media-breakpoint-up(md) {
            padding-top   : 90px;
            padding-bottom: 90px;
        }

        &:nth-child(2n) {
            background-color: $whitesand;
        }
    }

    .cc-featured-items.design-services {
        max-width: 100%;

        .cc-featured-item {
            padding         : 0 2.5rem;
            background-color: transparent;
            z-index         : 1;
            position        : relative;

            @include media-breakpoint-up(sm) {
                padding         : 0 1rem;
                background-color: transparent;
                height          : auto;
                display         : flex;
                justify-content : space-between;
                align-items     : center;
            }

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }


    // No cool scrolling for design services
    .cc-featured-item-grid.design-services {
        display       : flex;
        flex-direction: column-reverse;
        align-items   : center;
        width         : 100%;
        align-items   : stretch;

        .js-copy-group {
            padding-left : 1rem;
            padding-right: 1rem;
        }

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }

        @include media-breakpoint-up(sm) {
            align-items: center;

            .cc-featured-item-grid-col {
                flex: 1 50%;
            }
        }

        .cc-featured-item-grid-col.-left {
            height: auto;
        }

        .cc-featured-item-grid-col.-left .js-copy-group {
            padding-left : 0;
            margin-bottom: 0;

            @include media-breakpoint-up(xl) {
                max-width   : 500px;
                padding-left: 1rem;
            }
        }

        .cc-featured-item-grid-col.-right {

            img {
                height: auto;
            }

            @include media-breakpoint-up(sm) {
                display: block;

                .cc-featured-photos-item-link {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-up(xl) {
                img {
                    height: 660px;
                }
            }
        }
    }
}
