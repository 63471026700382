.h-960 {
    height: 960px;
}

.h-760 {
    @include media-breakpoint-up(md) {
        height: 760px !important;
    }
}

.h-360 {
    height: 360px !important;
}
