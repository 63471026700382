.responsive-object {
    position   : relative;
    overflow   : hidden;
    width      : 100%;
    padding-top: 56.25%;
    /* 16      :9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-object-tall {
    padding-top: 116.625%;
}

.portrait-embed {
    min-height: 788px;

    iframe,
    object,
    embed,
    video {
        padding-top   : 80px;
        padding-bottom: 80px;
    }
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed,
.responsive-object video {
    position: absolute;
    top     : 0;
    left    : 0;
    bottom  : 0;
    right   : 0;
    width   : 100%;
    height  : 100%;
}
