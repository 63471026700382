// overflow  : visible;
// Allow background fingerprint to show below masthead when pinned
.home-page .main-content {
    @include media-breakpoint-up(lg) {

        &:after {
            content: '';
            height: 200vh;
            width: 100%;
            position: absolute;
            left: 0;
            background-color: $white;
            @include fingerPrint();
            z-index: 0;
            top: 0;
        }
    }
}

.masthead.intro-panel {
    height: 100vh;
    min-height: 670px;
    background-color: $woodsmoke;
    z-index: 1;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        min-height: 700px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 900px;
        height: 100vh;
    }

    .split-panel {
        height: 100%;
        align-items: center;
    }

    .content-panel {
        position: relative;
        z-index: 1;
        width: clamp(40%, 45vw, 552px);
        left: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .video-preview-panel {
        height: 100%;
        background-color: transparent;
        position: absolute;
        width: 50vw;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &.expand .turbo-curtain {
            width: 100vw;
        }

        &.loaded {
            .video-wrapper {
                opacity: 1;
            }
        }

        .video-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            overflow: visible;
            top: 50%;
            opacity: 0;
            transition: opacity .2s ease-in;
            @include fingerPrint($light-print: false);

            z-index: 1;
            transform: translateY(-50%);
            padding: 40px;
            width: 45vw;
            height: 45vw;
            min-height: 320px;
            min-width: 320px;
            max-width: 320px;
            max-height: 320px;
            left: 0;
            transform-origin: right center;
            margin: 0 auto;
            right: auto;


            @include media-breakpoint-up(sm) {
                max-width: 420px;
                max-height: 420px;
                min-height: 400px;
            }

            @include media-breakpoint-up(md) {
                max-width: 470px;
                max-height: 470px;
                right: auto;
                left: 2rem;
                width: 45vw;
                height: 45vw;
                min-height: 320px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 570px;
                max-height: 570px;
                min-height: 540px;
            }

            @include media-breakpoint-up(xxl) {
                max-width: 670px;
                max-height: 670px;
                min-height: 560px;
            }

            .turbo-curtain {
                width: 100vw;
                background-color: #FBEF00;
                height: 600vh;
                position: absolute;
                left: 50%;
                right: 0;
                top: -150%;
                z-index: 0;
                transform-origin: right;
            }

            @keyframes gradient {
                0% {
                    background-position: 0% 50%;
                }

                50% {
                    background-position: 100% 50%;
                }

                100% {
                    background-position: 0% 50%;
                }
            }

            .dynamic-gradient-curtain {
                width: 50vw;
                height: 600vh;
                position: absolute;
                left: 50%;
                right: 0;
                top: -150%;
                z-index: 0;
                transform-origin: right;
                @include magicBackgroundGradient();
                background-position: 300px 35%;
                transition: all .2s linear;

                &:before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    background-color: $white;
                    width: 100%;
                    height: 100%;
                    right: 0;
                    top: 0;
                    transition: opacity .2s linear;
                    opacity: 0;
                }
            }
        }

        &.expand .video-wrapper .dynamic-gradient-curtain {
            background-color: white;
            background-image: none;
        }

        &.expand .video-wrapper .dynamic-gradient-curtain:before {
            opacity: 1;
        }

        &.expand .video-wrapper .fingerprint-bg {
            opacity: 1;
            @include fingerPrint();
            content: '';
            height: 100%;
            z-index: 1;
            background-position: -2px 90%;
            width: 200vw;
            left: -52vw;
            top: 50%;
            position: fixed;
        }

        &.expand .video-wrapper {
            max-width: none;
            max-height: none;
            height: calc(100vh - 50px);
            width: 100%;
        }

        .video-container {
            transform-origin: right;
            position: relative;
        }

        .dynamic-stroke {
            stroke: $black;
        }

        .close-video-btn {
            background-color: transparent;
            border: 0;
            outline: 0;
            padding: .25rem;
            position: absolute;
            top: .25rem;
            right: 2.35rem;
            height: 24px;
            width: 24px;
            visibility: hidden;
            z-index: -1;
            display: flex;

            img {
                width: 100%;
            }
        }

        &.loaded {
            &:before {
                opacity: 1;
            }

            &.video-loaded .close-video-btn {
                visibility: visible;
                z-index: 2;
            }

            .video-container {
                opacity: 1 !important;
            }
        }
    }

    .video-container {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        opacity: 0;

        .plyr--playing {
            &:hover {

                cursor: url('/static/img/icons/ui/pause-hover.svg'), auto;

            }
        }

        .plyr--paused {
            &.plyr--full-ui.plyr--video .plyr__control--overlaid {
                background-image: url('/static/img/icons/ui/play-hover.svg');
                background-size: contain;
                background-repeat: no-repeat;

                svg {
                    visibility: hidden;
                }
            }

            &:hover {
                &.plyr--full-ui.plyr--video .plyr__control--overlaid {
                    display: none;
                }

                cursor: url('/static/img/icons/ui/play-hover.svg'),
                auto;
            }
        }


        .preview-video-container {
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            top: 0;
            z-index: 0;
        }

        #hdr-player-full {

            &:hover {
                pointer-events: none;
            }
        }

        .full-video-container {
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            top: 0;
            visibility: hidden;
            z-index: -1;
            opacity: 0;
            will-change: transform;
            transition: .2s all linear;
            background-color: $raisin;

            @include media-breakpoint-up(xl) {
                .responsive-object {
                    height: 100%;
                    padding: 0;
                }
            }

        }

        .video-content.activated {

            .full-video-container {
                visibility: visible;
                z-index: 1;
                opacity: 1;
            }

            .preview-video-container {
                opacity: 0 !important;
                z-index: -1;
            }
        }
    }

    // plyr controls
    .video-content {
        .plyr--video {
            height: 100%;
            transition: 1s all ease-in;
        }

        .plyr video {
            width: 100%;
            height: 100%;
            transform: translateX(-50%);
            left: 50%;
            position: relative;
        }

        .preview-video-container {

            video {
                pointer-events: none;
                width: 183%;
                left: 50%;
                top: 50%;
                position: relative;
                transform: translate(-50%, -50%);

                &::-webkit-media-controls {
                    display: none !important;
                }
            }
        }
    }
}


body.video-open {
    .main-nav {
        visibility: hidden;
        z-index: -1;
    }
}


// Mobile
.mobile-intro {

    &.video-open {
        .main-nav {
            visibility: visible;
            z-index: 2;
        }
    }



    &.home-page .over-masthead {
        #nav-icon span {
            background: $turbo;
        }
    }

    .masthead.intro-panel {
        height: auto;
        min-height: min-content;

        .container.inner {
            padding: 0;
            max-width: 100%;
        }

        .split-panel {
            height: auto;
            display: block;
            position: relative;
        }

        .content-panel {
            padding: 80px .25rem 50px;
            padding-top: clamp(80px, 13vw, 120px);
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;

            .copy-block {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 540px;
            }
        }

        .close-video-btn {
            display: none;
        }

        .dynamic-stroke {
            stroke: $white;
        }

        .video-preview-panel {
            position: relative;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 0 50px;
            width: 100%;

            .video-wrapper {
                position: relative;
                background-size: contain;
                background-position: center top;
                background-repeat: no-repeat;
                transform: none;
                z-index: 1;
                transform: none;
                padding: 23px;
                left: auto;
                width: 80vw;
                height: 80vh;
                right: auto;
                background-image: url('/static/img/home/video-panel-pattern-ring-mobile@2x.png');
                background-size: contain;
                background-position: top center;
                background-repeat: no-repeat;

            }

            &.video-loaded {
                .video-wrapper {
                    background-image: none;
                }
            }

            .full-video-container {
                position: relative;
            }
        }

        .turbo-curtain-mobile {
            @include magicBackgroundGradient();
            width: 101vw;
            height: calc(50% + 23px);
            position: absolute;
            left: 50%;
            right: 0;
            top: auto;
            bottom: 0;
            z-index: 0;
            transition: all .3s ease-in;
            transform: translateX(-50%);
            padding: 0 0 50px;
            display: block !important;
        }

        .turbo-curtain,
        .dynamic-gradient-curtain {
            display: none !important;
        }

    }
}
